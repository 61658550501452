import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const FinancialEducation = (props) => {
    const { t } = useTranslation();
    
    return (
        <div >
        <div className='nk-wrap'>
            <div className="nk-pages tc-light">
                <section style={{marginBottom: '-8rem'}} className="section" id="mvp">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-10">
                                <div className="section-head section-head-s2">
                                    <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("FİNANSAL")}>{t("Finansal Eğitim")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container container-xxl">
                        <div className="nk-block">
                            <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                <div className="col-xxl-6 col-xl-6 col-lg-8">
                                <div className="nk-block-img nk-block-plx animated" data-animate="fadeInUp" data-delay="0.3">
                                    <img className="shadow rounded" src="images/skybitpro/financial-education.jpg" alt="" />
                                    {/* <img className="nk-block-img-plx plx-screen shadow rounded" src="images/app-screens/sc-small-d.jpg" alt="" /> */}
                                    <img className="nk-block-img-plx plx-circle plx-circle-s1" src="images/gfx/circle-a.png" alt="" />
                                    <img className="nk-block-img-plx plx-polygon plx-polygon-s1" src="images/gfx/polygon-a.png" alt="" />
                                    <img className="nk-block-img-plx plx-triangle plx-triangle-s1" src="images/gfx/triangle-a.png" alt="" />
                                </div>
                            </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-8">
                                    <div className="nk-block-text">
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-text">
                                                <p>{t("Kaldıraçlı kripto para alım ve satım işlemler yapmaya yeni başladıysanız ya da daha fazla tecrübe edinmek istiyorsanız finansal eğitimlerimize katılabilirsiniz. Uzman eğitmenler tarafından, online olarak gerçekleştirilecek eğitimlere katılarak kripto para ve yatırım dünyası hakkında daha fazla bilgi sahibi olabilirsiniz.")}</p>
                                            </div>
                                        </div>
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-text">
                                                <p>{t("Online finansal eğitimlerimiz hakkında daha detaylı bilgi sahibi olmak için bizimle hemen iletişime geçebilirsiniz.")}</p>
                                            </div>
                                        </div>
                                        <div className="text-center text-sm-left animated" data-animate="fadeInUp" data-delay="0.9">
                                            <a href="/" className="btn btn-round btn-primary mt-5">{t("Skybitpro'yu KEŞFEDİN")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AltComponent />
            </div>
        </div>
    </div>
    );
};
export default FinancialEducation;