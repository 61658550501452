import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const Privacy = (props) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section  style={{marginBottom: '-8rem'}} className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-6">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("GİZLİLİK")}>{t("Gizlilik Politikası")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-12">
                                        <div className="nk-block-text">
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                                <div className="feature-text">
                                                    <h4 className='mb-3'>{t("Gizlilik Politikası")}</h4>
                                                    <p>{t("Skybitpro Yazılım (“Şirketimiz” veya “Skybitpro”) olarak, web servis ve dijital kaynaklarımızın herhangi birini kullanan müşterilerimizin veya üçüncü kişilerin (“Kullanıcılar”) kişisel verilerinin korunması, bu hususlarda şeffaflık ve karşılıklı güven oluşturmak önceliklerimizdendir. Web adresimiz www.skybitpro.com veya uzantıları ve bunlarla birlikte ama bunlarla sınırlı olmamak üzere sosyal medya sayfalarımızı ve/veya dijital kanallarımızı ziyaret etmeniz, hizmet ve ürünlerimizi kullandığınız veya destek hizmetlerimizden yararlandığınız durumlar gibi Skybitpro’yu online ve dijital kanallarımız üzerinden ziyaret ettiğiniz anlarda otomatik olan veya olmayan yöntemlerle kaydettiğimiz ve elektronik olarak bizimle paylaştığınız kişisel verileriniz, öncelikle taleplerinizin yerine getirilmesini temin edebilmek, daha sonra ise size daha iyi hizmet sunmak amacıyla kullanılacaktır.")}</p>
                                                    <p>{t("Kişiler verilerinizin Skybitpro tarafından işlenmesi ile ilgili daha detaylı bilgiye Kişisel Verilerinin Korunması’ndan ve Çerez Politikası’ndan ulaşabilirsiniz.")}</p>
                                                    <p>{t('Onaylı veri tabanımızda yer almanız halinde ise, Şirketimiz tarafından sunulan ürün ve hizmetlerle ilgili olarak tanıtım, pazarlama ve promosyon faaliyetleri yapılması için ileti gönderilebilecektir. Şirketimiz tarafından gönderilen iletileri almak istemiyorsanız, size sunulan reddetme hakkını kullanarak örneğin; “Skybitpro’dan yeni ürün ve hizmet tanıtımına ilişkin herhangi bir e-posta almak istemiyorsanız lütfen tıklayınız.” bağlantısına tıklayarak, ilgili kanala ait listemizden çıkabilirsiniz.')}</p>
                                                    <h5>{t('Sorumluluğun Sınırlandırılması')}</h5>
                                                    <p>{t('Bu internet sitesinde verilen bilgiler, “olduğu gibi”, “mevcut olduğu şekilde” sağlanmaktadırlar. Skybitpro, bu bilgilerin doğruluklarını, yeterliliklerini ve eksiksizliklerini garanti etmemekte ve bu bilgilerdeki hatalar ya da eksiklikler nedeniyle sorumluluğu, açık bir şekilde reddetmektedir. Bu bilgilerle üçüncü şahısların haklarının ihlal edilmemesi; mülkiyet, belirli bir amaç için uygunluk ve/veya bilgisayar virüsü bulunmamasına ilişkin garantiler dahil ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti vermemektedir.')}</p>
                                                    <p>{t('Bu internet sitesinin kullanımı sırasında herhangi bir performans arızası, hata, eksiklik, kesinti kusur, işletme ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı zarar, ziyan ve masraflar da dahil ancak bunlarla sınırlı olmamak üzere hiçbir zarar ve ziyandan, Skybitpro ve/veya çalışanları, bu tür bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar.')}</p>
                                                    <p>{t('Gerek bu internet sitesinden bağlantı verilen sitelerdeki, gerekse de diğer internet sitelerine bağlantıların riski, kullanıcıya aittir. Bu sitelerde verilen bilgilerin doğruluğu ile bu sitelerden verilen diğer bağlantıların nitelikleri Skybitpro tarafından araştırılmamış ve doğrulanmamıştır.')}</p>
                                                    <p>{t('Skybitpro, bu internet sitesinde yer alan bütün ürün ve hizmetleri, sayfaları, bilgileri, görsel unsurları önceden bildirimde bulunmadan değiştirme hakkını saklı tutar.')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default Privacy;