import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const AML = (props) => {
    const { t } = useTranslation();

    return (
        <div >
            <div  className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section style={{marginBottom: '-8rem'}} className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-6">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="AML">{t("AML Politikası")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-12">
                                        <div className="nk-block-text">
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                                <div className="feature-text">
                                                    <h4 className='mb-5'>{t("SKYBITPRO BİLİŞİM KARA PARA AKLAMAYI ÖNLEME (AML) POLİTİKASI")}</h4>
                                                    <p>{t("Para aklama, yasa dışı kaynakların nakit veya görünürde meşru olan yatırımlara dönüştürülmesiyle örtbas edilmesidir.")}</p>
                                                    <p>{t("Skybitpro olarak müşterilerimize ve hesap güvenliğine büyük önem vermekle birlikte en iyi müşteri hizmetlerini sunma konusunu son derece ciddiye alırız. Her ne surette olursa olsun kara para aklanmasının önlenmesi amacıyla Kara Para Aklama Politikası’nı harfiyen uygularız. Bu kapsamda, Kara Para Aklama Politikası (bundan sonra “AML Politikası” olarak anılacaktır) Skybitpro tarafından para aklamayı önlemek amacıyla belirlenen prosedürleri ve mekanizmaları özetler.")}</p>
                                                    <p>{t("Kara Para Aklama Önleme Politikası’nın amacı, risk temelli bir yaklaşımla Skybitpro Teknoloji Anonim Şirketi bünyesindeki müşterileri, işlemleri ve hizmetleri değerlendirerek riski azaltmak, suç gelirlerinin aklanmasının ve terörizmin finansmanının önlenmesi hakkında çalışanların bilinçlendirilmesi ve bilgi edinilmesi ve bu kapsamda Şirket’in faaliyetlerini, yürürlükteki mevzuat uyarınca gerçekleştirdiğinin müşterilere bildirilmesidir.")}</p>
                                                    <p>{t("AML Politikası kapsamında, Şirket faaliyetlerine halel gelmemesi amacıyla haklarında AML politikası işletilen müşteriler, www.skybitpro.com adresinden erişilen platforma (“Platform”) üye olan ve Platform’da sunulan hizmetlerden yararlanan ve karşılığında işbu AML Politikası’ndaki hususları kabul eden gerçek kişiyi ifade eder.")}</p>
                                                    <p>{t("Skybitpro, AML Politikası’nı uluslararası hukuk norm ve standartlarına, yürürlükteki mevzuata uygun olarak düzenlemiş olup uygulanması zımnında, tüm müşterilerin kimliklerini makul düzeye kadar doğrulama, müşteriye ait işlemlerin izlenmesi için riske dayalı bir yaklaşım uygulama, müşteriler tarafından gerçekleştirilen herhangi bir şüpheli işlemi yürürlükteki mevzuat çerçevesinde ilgili kurum ve kuruluşlara bildirme ve işlemleri kaydetme, Şirket nezdinde AML Politikası’nın icra edilmesi ve uygulanabilirliğini koordine etmek için gerekli organizasyonu sağlama gibi birçok tedbir almıştır.")}</p>
                                                    <p>{t("Buna göre Skybitpro aşağıdaki politikaları izler:")}</p>
                                                    <ul className="tc-list">
                                                        <li>{t("Suçlular ve/veya teröristlerle iş ilişkilerine girmemek,")}</li>
                                                        <li>{t("Suç ve/veya terörist faaliyetlerden kaynaklanan işlemleri işleme koymamak,")}</li>
                                                        <li>{t("Suç ve/veya terörist faaliyetlerle ilgili herhangi bir işlemi kolaylaştırmamak.")}</li>
                                                    </ul>
                                                    <h5>{t("Risk Değerlendirmesi")}</h5>
                                                    <p>{t("Skybitpro gerek ulusal gerekse uluslararası gerekliliklere uygun olarak kara para aklama ve terörizmin finansmanı için riske dayalı bir yaklaşım benimser. Dolayısıyla kara para aklamayı önleme ve terörizmin finanse edilmesine yönelik tedbirler, belirlenen risklerle orantılıdır ve kaynakların etkin bir şekilde adanmasına izin verir. Kaynaklar, öncelik temeline göre kullanılır ve en büyük risklere en büyük önem verilir.")}</p>
                                                    <p>{t("Skybitpro, müşterilerinin finansal faaliyetlerinin izlenmesinde riske dayalı bir yaklaşım benimsemesi sebebi ile kara para aklama ve terörizmin finansmanını önlemek amacıyla aşağıdaki yöntemlerle risk analizi yapıp risk analizi sonucu elde ettiği bilgiler çerçevesinde ilgili müşterileri izlemeye alabilir.")}</p>
                                                    <p>{t("Yüksek risk grubunda yer alan müşteri ve işlemler aşağıdaki gibidir:")}</p>
                                                    <ul className="tc-list">
                                                        <li>{t("Tek bir kripto para alım-satım işleminin veya birden çok bağlantılı işlemlerin toplam tutarı 500.000 TL’ye eşit veya fazla ise,")}</li>
                                                        <li>{t("Tek bir kripto para takas işleminin veya birden çok bağlantılı işlemin toplam tutarı 500.000TL’ye eşit veya fazla ise,")}</li>
                                                        <li>{t("Yürürlükteki mevzuat çerçevesinde şüpheli işlem bildirimini gerektiren durumlarda,")}</li>
                                                        <li>{t("Önceden edinilmiş kimlik bilgilerinin doğruluğu ve yeterliliği konusunda şüphe olduğu takdirde,")}</li>
                                                        <li>{t("Üçüncü kişi yararlanıcıları gizleme potansiyeline sahip karmaşık işlemleri gerçekleştirmesi halinde,")}</li>
                                                        <li>{t("Para kaynaklarının kolayca doğrulanamayacağı durumlarda,")}</li>
                                                        <li>{t("Ekonomik veya görünür herhangi bir meşru amaca sahip olağandışı işlemlerde.")}</li>
                                                    </ul>
                                                    <h5>{t("İşlem İzleme")}</h5>
                                                    <p>{t("Müşterilerin işlemlerinin izlenmesi ve elde edilen verilerin analizi de risk değerlendirmesi ve şüpheli işlemlerin tespiti için önemli bir araçtır. Para aklama şüphesinin olması halinde, Skybitpro tüm işlemleri izleme (yüksek risk grubundaki müşterilerin işlemleri, kompleks ve olağandışı işlemler, yüksek riskli ülkelerle yapılan işlemler, müşterilere ait bilgileri ve belgeler, kripto para alım satım ve transferine ilişkin tutulması gereken yazılı ve zorunlu bilgiler, müşteri tarafından gerçekleştirilen bir işlemin, söz konusu işlemle ilgili bilgilere uygun olup olmadığı vb.) ve aşağıdakileri yapma hakkına sahiptir:")}</p>
                                                    <ul className="tc-list">
                                                        <li>{t("Şüpheli işlemlerin ilgili kolluk birimlerine bildirilmesi,")}</li>
                                                        <li>{t("Müşteriden ek bilgi ve belgeler sunmasını talep edilmesi,")}</li>
                                                        <li>{t("Müşteri hesabının askıya alınması veya kapatılması.")}</li>
                                                    </ul>
                                                    <p>{t("Yukarıdaki liste tam kapsamlı bir liste olmayıp AML Politika Uyum Sorumlusu, müşterilerin işlemlerini günlük olarak izleyerek müşterileri raporlar ve şüpheli olarak değerlendirip değerlendirmeyeceğini belirler.")}</p>
                                                    <h5>{t("Müşterini Tanı Politikası")}</h5>
                                                    <p>{t("Müşterini Tanı (Know-Your-Customer) Politikası’na www.skybitpro.com üzerinden erişim sağlanabilir.")}</p>
                                                    <h5>{t("Doğrulama Prosedürü")}</h5>
                                                    <p>{t("Skybitpro, para aklamayla mücadele standartlarını ve Müşterini Tanı Politikası’na (KYC) uyumu belirlemek için kendi prosedürlerini oluşturacaktır.")}</p>
                                                    <p>{t("Skybitpro müşterileri, bir doğrulama prosedürünü tamamlar. Skybitpro, AML politikasının amaçları için müşterilerinin kimlik bilgilerini toplama hakkını saklı tutar. Bu bilgiler, Skybitpro Gizlilik Politikası uyarınca işlenir ve güvenli bir şekilde saklanır.")}</p>
                                                    <p>{t("Skybitpro ikinci bir müşteri kimlik belgesi talep edebilir. (Müşterinin tam adını ve gerçek adresini içeren elektrik/su faturası, 3 ay içinde yapılmış bir işleme ait banka dekontu gibi)")}</p>
                                                    <p>{t("Skybitpro, müşterilerin ibraz ettiği belgelerin ve bilgilerin doğruluğunu teyit ettikten sonra tehlikeli veya şüpheli olarak tanımlanan müşteriler hakkında ek bilgi isteme hakkını saklı tutar.")}</p>
                                                    <p>{t("Müşterinin kimlik bilgileri değiştirilmişse veya faaliyetleri şüpheli bulunmuşsa, Skybitpro geçmişte kimliği doğrulanmış olsa dahi müşteriden güncellenmiş belgeler talep etme hakkına sahiptir.")}</p>
                                                    <h5>{t("Raporlama")}</h5>
                                                    <p>{t("Skybitpro’nun sağlamış olduğu hizmetler çerçevesinde, kara para aklama ve terörizmin finansmanına dair şüphe uyandıran hallerde, gerekli incelemeler sonucunda şüpheli işlem olarak belirlenen işlemler tutarına bakılmaksızın yürürlükteki mevzuat uyarınca yetkili makamlara bildirilir. Şüpheli işlemi gerçekleştiren gerçek kişiler ve bunların yasal temsilcileri, şüpheli işlemi bildirme yükümlülüğüne uymayan yönetici ve personeller her türlü hukuki, idari ve cezai yaptırımdan sorumlu olacaktır.")}</p>
                                                    <h5>{t("Kara Parayı Aklamayı Önleme Sorumlusu")}</h5>
                                                    <p>{t("Kara Para Aklamayı Önleme Sorumlusu, AML Politikası’na uymayı sağlamakla görevli bir Skybitpro çalışanıdır.")}</p>
                                                    <p>{t("Kara Para Aklamayı Önleme Sorumlusu;")}</p>
                                                    <ul className="tc-list">
                                                        <li>{t("Müşterilerin kimlik bilgilerinin toplanması,")}</li>
                                                        <li>{t("Mevcut yasa ve yönetmeliklere uygun olarak gerekli tüm raporları oluşturmak, gözden geçirmek, arz etmek ve saklamaya yönelik iç politika ve prosedürleri oluşturmak ve güncellemek,")}</li>
                                                        <li>{t("Müşterilerin sıra dışı faaliyetlerinden kaynaklanan önemli sapmaların izlenmek ve analiz etmek,")}</li>
                                                        <li>{t("Belge, dosya, form ve günlük oturum giriş çıkışlarını kaydetme ve alma için bir kayıt yönetim sistemini uygulamak,")}</li>
                                                        <li>{t("Risk değerlendirmelerini düzenli olarak güncellemek konularında görevlendirilmiştir.")}</li>
                                                    </ul>
                                                    <h5>{t("Eğitim, Güncelleme ve İç Denetim")}</h5>
                                                    <p>{t("Skybitpro, yürürlükteki mevzuata uygun surette çıkardığı personel politikası ve prosedürler ile eğitim kapsamındaki tüm yükümlülüklerini yerine getirir. Bu kapsamda Kara Para Aklamanın Önlenmesi Prosedürü başta olmak üzere personeline birçok eğitim verir ve işbu bilgilerin güncel tutulmasını sağlar.")}</p>
                                                    <p>{t("Skybitpro “Kara Para Aklama Ve Terörizm Finansmanına Karşı Kanun”, yönetmelik ve tebliğlerle ilgili faaliyetlerinin yürürlükteki mevzuat, Şirket politikaları ve usullerine uygun olup olmadığı konusunda periyodik olarak denetim yapar.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default AML;