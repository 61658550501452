import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from "./helpers/notification/closeSnackbar"
import { Provider } from 'react-redux';
import store from './store'
import './i18n/i18n';

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider autoHideDuration={3000} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} preventDuplicate>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
