import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const Commissions = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-7">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("ALIŞ-SATIŞ")}>{t("Komisyonlar")}</h2>
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("Skybitpro'da yapacağınız işlemlerde aşağıda belirtilen komisyon oranları uygulanır.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-12">
                                        <div className="token-info bg-theme animated" data-animate="fadeInUp" data-delay=".3">
                                            <table className="table table-token">
                                                <tbody>
                                                    <tr>
                                                        <td className="table-head">TRY {t("HACİM / 30 GÜN")}</td>
                                                        <td className="table-head">{t("Piyasa Yapıcı")}</td>
                                                        <td className="table-head">{t("Piyasa Alıcı")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">0 - 1.000.000</td>
                                                        <td className="table-des">%0.20</td>
                                                        <td className="table-des">%0.20</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">1.000.000 - 5.000.000</td>
                                                        <td className="table-des">%0.17</td>
                                                        <td className="table-des">%0.17</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">5.000.000 - 10.000.000</td>
                                                        <td className="table-des">%0.12</td>
                                                        <td className="table-des">%0.12</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">10.000.000 - 50.000.000</td>
                                                        <td className="table-des">%0.10</td>
                                                        <td className="table-des">%0.10</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">50.000.000 +</td>
                                                        <td className="table-des">%0.05</td>
                                                        <td className="table-des">%0.05</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section style={{marginTop: '-8rem', marginBottom : '-6rem'}} className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-7">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("YATIRMA-ÇEKME")}>{t("Ücretler")}</h2>
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("Skybitpro'da yapacağınız işlemlerde aşağıda belirtilen ücretler uygulanır.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-xs-12 col-lg-7">
                                        <div className="token-info bg-theme animated" data-animate="fadeInUp" data-delay=".3">
                                            <table className="table table-token">
                                                <tbody>
                                                    <tr>
                                                        <td className="table-head">{t("YÖNTEM")}</td>
                                                        <td className="table-head">{t("YATIRMA")}</td>
                                                        <td className="table-head">{t("ÇEKME")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">AKBANK</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">VAKIFBANK</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">ZİRAAT BANKASI</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">FİBABANK</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-des">{t("KRİPTO PARA")}</td>
                                                        <td className="table-des">{t("Ücretsiz")}</td>
                                                        <td className="table-des">{t("Okuyunuz")} <span className='tc-primary'>(*)</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-lg-5">
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2"><span className='tc-primary'>(*)</span> {t("BTC, XRP, XLM kripto para birimlerinde hiçbir komisyon alınmamaktadır. ETH çekim işlemlerinden 0.005 ETH, AVAX çekim işlemlerinden 0,01 AVAX, LTC çekim işlemlerinden 0,001 LTC, ALGO çekim işlemlerinden 0,01 ALGO, DOT için 0,1 DOT, USDT için 20 USDT, LINK için 0,6 LINK, FTM için 15 FTM, HOT için 3000 HOT, SHIB için 750.000 SHIB, SAND için 8 SAND, MANA için 3 MANA blok zinciri ücreti (fee) alınmaktadır. Alınan fee, yatırımcının gönderdiği tutardan düşmektedir.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default Commissions;