import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Promotions() {

    const { t } = useTranslation()
    let language = localStorage.getItem("skybit-language")

    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-10">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("PROMOSYONLAR")}>{t("Promosyonlar")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container justify-content-center h-50'>
                            <div className="row text-center justify-content-center">
                                <div>
                                    <h4 className='text-white'>{t("Skybit Pro’nun sunduğu üst düzey hizmet anlayışı sayesinde, Forex piyasasının dinamiklerini ayrıcalıklı işlem gerçekleştirme avantajlarıyla deneyimleyeceksiniz!")}</h4>
                                </div>
                                <div className='col-md-4 mt-4'>
                                    <div className="card bg-dark text-white">
                                        <Link to='/iphone-14-campaign'>
                                            {language === 'tr' ? <img src="images/skybitpro/14-iphone.png" className="card-img" alt="Iphone 14 Pro Max" /> : 
                                            <img src="images/skybitpro/14-iphone-eng.png" className="card-img" alt="Iphone 14 Pro Max" />}
                                            <div className="card-img-overlay d-flex flex-column">
                                                <h5 className="card-title">{t("iPhone 14 Pro Max Kampanyası")}</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-md-4 mt-4'>
                                    <div className="card bg-dark text-white">
                                        <Link to='/skycard'>
                                            {language === 'tr' ? <img style={{height: '560px'}} src="images/skybitpro/skycardtr.png" className="card-img" alt="Sky-Card" /> : 
                                            <img style={{height: '560px'}} src="images/skybitpro/skycardeng.png" className="card-img" alt="Sky-Card" />}
                                            <div className="card-img-overlay d-flex flex-column">
                                                <h5 className="card-title my-4">{t("Sky Kart Kampanyası")}</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </div >
    )
}

export default Promotions