import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const MobileApp = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section  style={{marginBottom: '-8rem'}} className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-12">
                                    <div className="section-head section-head-s2">
                                    <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("MOBİL UYGULAMA")}>{t("Cebinde, Her Anında Seninle!")}</h2>
                                        {/* <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="ÇOK YAKINDA">Çok Yakında Mobil Uygulamamız Sizlerle</h2> */}
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("Kullanıcı dostu, pratik ve son teknoloji tasarımla hem profesyoneller hem de yeni başlayanlar için.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-xxl-6 col-xl-6 col-lg-8">
                                        <div className="nk-block-img nk-block-plx animated" data-animate="fadeInUp" data-delay="0.3">
                                            <img style={{scale: '1.2', marginTop: '-4rem'}} className="shadow rounded" src="images/skybitpro/app-mobiles.png" alt="" />
                                            {/* <img className="nk-block-img-plx plx-screen shadow rounded" src="images/app-screens/sc-small-d.jpg" alt="" /> */}
                                            {/* <img className="nk-block-img-plx plx-circle plx-circle-s1" src="images/gfx/circle-a.png" alt="" />
                                            <img className="nk-block-img-plx plx-polygon plx-polygon-s1" src="images/gfx/polygon-a.png" alt="" />
                                            <img className="nk-block-img-plx plx-triangle plx-triangle-s1" src="images/gfx/triangle-a.png" alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-8">
                                        <div className="nk-block-text">
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">

                                                <div className="feature-text">
                                                <p>{t("Şimdi Skybitpro mobil uygulaması ile dilediğin an kripto dünyasına tek tıkla bağlan. Bitcoin ve diğer kripto paraları kolayca al, sat.")}</p>
                                                    {/* <p>Çok Yakında Skybitpro mobil uygulaması ile dilediğin an kripto dünyasına tek tıkla bağlanabileceksiniz.</p> */}
                                                </div>
                                            </div>
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                                <div className="feature-text">
                                                    <p>{t("Kullanıcı dostu platformumuz ve arayüzlerimiz, üyelerimizin tüm işlemlerini kolay ve hızlı bir şekilde yapabilmesi için tasarlanacak. Ayrıca gelişmiş mobil uygulamamız sayesinde işlemlerinizi dilediğiniz yerden takip edebilirsiniz.")}</p>
                                                </div>
                                            </div>
                                            <div className="text-center text-sm-left animated mt-5" data-animate="fadeInUp" data-delay="0.9">
                                                <a href="https://apps.apple.com/tr/app/skybitpro/id6443659452?l=tr" target="_blank" className='mr-3'><img width="150px" src="images/apps-store.png" alt="" /></a>
                                                <a href="https://play.google.com/store/apps/details?id=com.webtrader.skybitpro1" target="_blank"><img width="150px" src="images/google-play.png" alt="" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default MobileApp;