import React from 'react';
import { useTranslation } from 'react-i18next';

const Banner = (props) => {
    const register = 'https://my.skybitpro.com/register?lang=tr';
    const { t } = useTranslation();

    return (
        <div className="banner banner-fs tc-light">
            <div className="nk-block nk-block-sm nk-block-header my-auto">
                <div className="container container-xxl pt-5">
                    <div class="banner-rounded-bg bg-theme-alt">
                        <span class="banner-shade-1"><span class="banner-shade-2"><span class="banner-shade-3"></span></span></span>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px">
                        <div className="col-lg-6 order-lg-last">
                            <div className="banner-gfx banner-gfx-auto animated" data-animate="fadeInUp" data-delay=".7">
                                <img src="images/skybitpro/banner-coins.png" alt="header" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-sm-10 text-center text-lg-left" style={{ zIndex: "999" }}>
                            <div className="banner-caption">
                                <h1 className="title title-xl ttu animated col-lg-11" data-animate="fadeInUp" data-delay=".8">{t("SKYBITPRO İLE KRİPTO PARA DÜNYASINA KATILIN!")}
                                </h1>
                                <p className="lead animated col-lg-12" data-animate="fadeInUp" data-delay=".9">
                                    {t("Skybitpro, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. Skybitpro ile dijital para borsalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.")}
                                </p>
                                <div className="cpn-action animated ml-3" data-animate="fadeInUp" data-delay={1}>
                                    <a className="btn btn-primary btn-round mt-5" href={register}>{t("HEMEN BAŞLAYIN!")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Banner;