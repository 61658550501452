import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'tr',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
        translation: {
          "": "",
        }
      },
      en: {
        translation: {
          "Mobil Uygulama": "Mobile App",
          "Piyasa": "Market",
          "Yatırma": "Deposit",
          "Çekme": "Withdrawal",
          "Bize Ulaşın": "Contact Us",
          "Promosyonlar": "Promotions",

          "Skybitpro, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. Skybitpro ile dijital para borsalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "",

          "Skybitpro'yu KEŞFEDİN": "DISCOVER Skybitpro",
          "Kaldıraçlı kripto para alım-satım işlemleriniz için Skybitpro yanınızda. Güvenilir bir şekilde yatırım yapmak, kazanç sağlamak için güçlü altyapımızı kullanmak, 7/24 hizmet anlayışımız sayesinde web sitemiz üzerinden dilediğiniz zaman kripto para alıp satmak için hemen üye olabilirsiniz.": "Skybitpro has got you on your leveraged cryptocurrency trades. Sign up now to buy and sell cryptocurrency securely with Skybitpro’s secure and powerful and 24/7 online system. Sign up for Skybitpro now and start buying or selling cryptocurrency any time you wish with the help of our 24/7 online platform.",
          "ÜYE OLUN": "SIGN UP",
          "GİRİŞ YAPIN": "SIGN IN",
          "ÜYE OL": "SIGN UP",
          "GİRİŞ YAP": "SIGN IN",
          "KAYIT OL": "SIGN UP",

          "Hakkımızda": "About Us",
          "KURUMSAL": "CORPORATE",
          "Türkiye’nin en güvenli, en hızlı kripto para alım-satım platformu ile tanışın.": "Meet the safest and fastest crypto exchange platform in Turkey. Sign up and start buying or selling your cryptocurrency today.",
          "2019’dan bu yana faaliyet gösteren Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda yatırım yapmak isteyenlerin işini kolaylaştıran çözümler sunar. Skybitpro platformunun; hızlı altyapısı, kullanıcı dostu arayüzü ve 7/24 ulaşılabilir olması sayesinde kullanıcılar, alım-satım işlerini en hızlı şekilde ve kolayca gerçekleştirir.": "Skybitpro has actively been producing effective solutions on leverage and margin deals for traders since 2019. We use the fastest and safest technology to increase user experience and provide a trusted trading experience with 24/7 online customer support. ",
          "Müşteri odaklı bir yatırım platformu olan Skybitpro, kullanıcılarının beklentilerini en iyi şekilde karşılamayı hedefler. Uzman kadrosunun da desteği ile müşteri memnuniyetini üst düzeyde tutmak için çalışır. Faaliyet gösterdiği ilk günden bu yana güvenilirliğini hiç kaybetmez, güvenlik konusunda kullanıcılarından da tam not alır.": "Skybitpro is a customer driven cryptocurrency exchange platform and it aims to provide a seamless trading experience for all. Thriving to deliver a top-notch customer experience with its professional team, Skybitpro is among the most trusted cryptocurrency platforms with its primary focus on user’s security since the day of its launch.",
          "Başarısını güçlü sermayesi ile destekleyen Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda sektörün lider markaları arasında yer almaktadır. Kullanıcılarının yatırım işlemlerinde daha çok kazanç sağlaması için teknik altyapı ve imkanları gün geçtikçe artırmayı hedefleyen markamız sayesinde siz de geleceğinize yatırım yapmaya hemen başlayabilirsiniz. Güvenli, hızlı, kesintisiz bir şekilde yatırım yapmak için hemen Skybitpro’ya üye olabilirsiniz.": "Also supported by generous financial investment, Skybitpro is among the most trusted and well known names when it comes to leverage cryptocurrency trades. You can also start investing in your future with our brand whose sole aim is continuous growth and improvement. Sign up for Skybitpro now for a safe, simple and uninterrupted cryptocurrency investment experience.",

          "Neden Skybitpro?": "Why Skybitpro?",

          "Kaldıraçlı kripto para alım ve satım işlemler yapmaya yeni başladıysanız ya da daha fazla tecrübe edinmek istiyorsanız finansal eğitimlerimize katılabilirsiniz. Uzman eğitmenler tarafından, online olarak gerçekleştirilecek eğitimlere katılarak kripto para ve yatırım dünyası hakkında daha fazla bilgi sahibi olabilirsiniz.": "",
          "Online finansal eğitimlerimiz hakkında daha detaylı bilgi sahibi olmak için bizimle hemen iletişime geçebilirsiniz.": "",

          "Gizlilik Politikası": "Privacy Policy",
          "GİZLİLİK": "PRIVACY",
          "Skybitpro Yazılım (“Şirketimiz” veya “Skybitpro”) olarak, web servis ve dijital kaynaklarımızın herhangi birini kullanan müşterilerimizin veya üçüncü kişilerin (“Kullanıcılar”) kişisel verilerinin korunması, bu hususlarda şeffaflık ve karşılıklı güven oluşturmak önceliklerimizdendir. Web adresimiz www.skybitpro.com veya uzantıları ve bunlarla birlikte ama bunlarla sınırlı olmamak üzere sosyal medya sayfalarımızı ve/veya dijital kanallarımızı ziyaret etmeniz, hizmet ve ürünlerimizi kullandığınız veya destek hizmetlerimizden yararlandığınız durumlar gibi Skybitpro’yu online ve dijital kanallarımız üzerinden ziyaret ettiğiniz anlarda otomatik olan veya olmayan yöntemlerle kaydettiğimiz ve elektronik olarak bizimle paylaştığınız kişisel verileriniz, öncelikle taleplerinizin yerine getirilmesini temin edebilmek, daha sonra ise size daha iyi hizmet sunmak amacıyla kullanılacaktır.": "As Skybitpro Software (“Skybitpro” or “Our Company”, protecting personal data and details of our customers and third parties and creating a mutual relationship based on trust is among our highest priorities. Skybitpro collects and uses your personal information that you share with us electronically through your visits to our website www.skybitpro.com or digital and social media platforms and/or web pages automatically or automatically in order to provide and continually improve our products and services for users.",
          "Kişiler verilerinizin Skybitpro tarafından işlenmesi ile ilgili daha detaylı bilgiye Kişisel Verilerinin Korunması’ndan ve Çerez Politikası’ndan ulaşabilirsiniz.": "You can access more details on processing your personal data by Skybitpro via Protection of Personal Data and Cookie Policy.",
          "Onaylı veri tabanımızda yer almanız halinde ise, Şirketimiz tarafından sunulan ürün ve hizmetlerle ilgili olarak tanıtım, pazarlama ve promosyon faaliyetleri yapılması için ileti gönderilebilecektir. Şirketimiz tarafından gönderilen iletileri almak istemiyorsanız, size sunulan reddetme hakkını kullanarak örneğin; “Skybitpro’dan yeni ürün ve hizmet tanıtımına ilişkin herhangi bir e-posta almak istemiyorsanız lütfen tıklayınız.” bağlantısına tıklayarak, ilgili kanala ait listemizden çıkabilirsiniz.": "Once your details are in our verified database, we use your personal information to take and handle sales, marketing and promotion purposes and communicate with you about these services. If you do wish not to receive the promotions and offers by our company, you can click on the “Opt out of new product offers, promotions and electronically sent communications by Skybitpro” to stop getting emails.",

          "Sorumluluğun Sınırlandırılması": "Limitation of Liability",
          "Bu internet sitesinde verilen bilgiler, “olduğu gibi”, “mevcut olduğu şekilde” sağlanmaktadırlar. Skybitpro, bu bilgilerin doğruluklarını, yeterliliklerini ve eksiksizliklerini garanti etmemekte ve bu bilgilerdeki hatalar ya da eksiklikler nedeniyle sorumluluğu, açık bir şekilde reddetmektedir. Bu bilgilerle üçüncü şahısların haklarının ihlal edilmemesi; mülkiyet, belirli bir amaç için uygunluk ve/veya bilgisayar virüsü bulunmamasına ilişkin garantiler dahil ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti vermemektedir.": "All content on this website is provided to you on an “as is” “as available” basis. Skybitpro does not guarantee any implied warranties of fitness for a particular purpose, accuracy and non-infringement. Skybitpro does not assume any responsibility or liability for the accuracy, completeness, reliability or usefulness of the information disclosed or accessed through this website. It is your responsibility to verify any information contained in this website before relying upon it.",
          "Bu internet sitesinin kullanımı sırasında herhangi bir performans arızası, hata, eksiklik, kesinti kusur, işletme ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı zarar, ziyan ve masraflar da dahil ancak bunlarla sınırlı olmamak üzere hiçbir zarar ve ziyandan, Skybitpro ve/veya çalışanları, bu tür bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar.": "Skybitpro and its staff specifically disclaims any liability and rejects any warranty even though they have been advised of the possibility of such damages, including but not limited to reliance by any party on any content obtained through the use of the website, or that arises in connection with mistakes or omissions in, or delays in transmission of, information to or from the user, interruptions in telecommunications connections to the website or viruses, whether caused in whole or in part by negligence, telecommunications failure.",
          "Gerek bu internet sitesinden bağlantı verilen sitelerdeki, gerekse de diğer internet sitelerine bağlantıların riski, kullanıcıya aittir. Bu sitelerde verilen bilgilerin doğruluğu ile bu sitelerden verilen diğer bağlantıların nitelikleri Skybitpro tarafından araştırılmamış ve doğrulanmamıştır.": "You accept that you are fully responsible for verifying the content, accuracy, completeness, timeliness, current value or any aspect of the information on any other website or pages linked to or linking to the site. Skybitpro does not verify the content of any such websites, pages or links and will not be liable or responsible for any damages that may be incurred by the users due to following links to any other website or pages.",
          "Skybitpro, bu internet sitesinde yer alan bütün ürün ve hizmetleri, sayfaları, bilgileri, görsel unsurları önceden bildirimde bulunmadan değiştirme hakkını saklı tutar.": "Skybitpro, retains the right to amend and/or entirely or partially remove all kinds of information, images, pages, services, products and features included in this site without notice.",

          "ŞARTLAR VE KOŞULLAR": "TERMS AND CONDITIONS",
          "ŞARTLAR": "TERMS",
          "Taraflar": "The Parties",
          "İşbu sözleşme ve sözleşmenin ayrılmaz birer parçası olan eklerden oluşan Skybitpro Kullanım Sözleşmesi (bundan böyle kısaca “Kullanım Sözleşmesi” olarak anılacaktır), “Skybitpro Yazılım Anonim Şirketi” ile Site’ye üye olan “Kullanıcı” arasında, Kullanıcı’nın Site’ye elektronik ortamda üye olması anında, Site’de sunulan hizmetleri kullanırken karşılıklı hak ve yükümlülükleri belirtmek amacı ile düzenlenmiştir.": 'These Skybitpro Terms of Use is provided by Skybitpro (hereinafter briefly referred to as “User Agreement”) which is an integral part of this agreement and its annexes, between “Skybitpro Software INC” and the User who becomes a member of the Site, the terms and conditions of the services provided by the Company and the rules regarding the membership of the User to the Site are arranged at the time when the User in the electronic environment where the Site is located.',
          "Tanımlar": "Definitions",
          "Skybitpro Yazılım Anonim Şirketi.": "Skybitpro Software Incorporated Company",
          "Kripto Para": "Cryptocurrency",
          "Kriptografik/şifreli olarak güvenli işlem yapmaya ve ek sanal para arzına olanak sağlayan dijital değerlere, kripto-para denir. Kripto-paralar, alternatif para birimidirler, dijitaldirler ve aynı zamanda sanal paradırlar.": "Cryptocurrency is an alternative type of encrypted currency that’s digital and decentralized and can be used to buy and sell things, or as a long-term store of value.",
          "Kripto-paralar, merkezi elektronik paraların ve bankacılık sistemlerindekinin aksine, merkezi olmayan yapıdadırlar. Merkezi olmayan bu yapının kontrolü Blok-Zincir (BlockChain) işlem veri tabanları tarafından gerçekleştirilir (*).": "Cryptocurrencies are digital currencies that refer to encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form. (*)",
          "Bitcoin Cüzdanı": "Bitcoin Wallet",
          "Bitcoin bir kripto para’dır. Bitcoin adreslerinizi ve onların şifrelerini tutan bir bilgisayar dosyasıdır. Bitcoin adresler arasında transfer edilir. Bitcoin adresleri rastgele oluşturulan kriptografik açık anahtar eşleridir. Açık adresler yaklaşık 33 harf ve sayı kullanılarak oluşturulan “1” ya da “3” ile başlayan dizilerdir. Bitcoin adresi genelde bu örnekteki şekildedir: 1KUsvypQkcs91Ffdkjkk8ddW7RDWKF75Yc.": "Bitcoin is a type of cryptocurrency.It is a desktop file that keeps your bitcoin addresses and their passwords. Bitcoin is transferred between addresses. However, instead of storing physical currency, the wallet stores the cryptographic information used to access Bitcoin addresses and send transactions. Bitcoin addresses are public cryptographic key pairs. These public addresses are approximately 33 characters long consisting of letters and numbers and start with “1” or “3”;such as; 1KUsvypQkcs91Ffdkjkk8ddW7RDWKF75Yc.",
          "Kullanıcılar": "Users",
          "Site’ye üye olan ve Site’de sunulan Hizmet’lerden yararlanan gerçek kişi.": "User refer to all individuals that acces, registers for the Services provided by the Website",
          "Site": "Website",
          "www.skybitpro.com alan adından ve bu alan adına bağlı alt alan adlarından oluşan internet sitesi.": "The Website consisting of the domain name www.skybitpro.com and its subdomains affiliated to this domain.",
          "Hak ve Yükümlülükler": "Rights and Obligations",
          "Skybitpro hizmetine bağlı tüm servislerin, alan adlarının, yazılım kodlarının, ara yüzlerinin, içeriklerinin, ürün incelemelerinin, videolarının, algoritmalarının, çizimlerinin, modellerinin, tasarımlarının ve diğer tüm fikri sınai hakların sahibi, (üçüncü partilerden sağlanan içerik ve uygulamalar hariç olmak üzere) Skybitpro Yazılım Anonim Şirketi’dir. Sunulan servislerin yazılım, tasarım ve telif hakları tamamen Skybitpro’ya aittir. Servislerin ve hizmetlerin, bununla bağlantılı sayfaların kopyalanmasına, çoğaltılmasına ve yayılmasına, ayrıca tersine mühendislik işlemlerine tabi tutulmasına hiçbir şekilde izin verilmemektedir.": "Skybitpro Software INC, is the owner of the Website and it prohibits any commercial or personal usage of the visual and written content presented on the Website and all elements including the all services affiliated with this domain, domain names, scripts, programs, User interfaces, contents, product reviews, videos, algorithms, designs, models and related to them. Each User hereby accepts that they shall not reproduce, copy pages and similar pages related within the Website or carry out any engineering tests which are completely prohibited by the copyright owner Skybitpro.",
          "Kullanıcı; hesabını ve haklarını üçüncü şahıslara devredemez, satamaz, kendi üyeliğini her ne nam ve ad altında olursa olsun başkasına kullandıramaz. Bu durumda Skybitpro kullanıcının hesabını iptal etme, durdurma, askıya alma hakkına sahiptir. Kullanıcının kripto para varlıkları bu durumdan etkilenmez.": "Users may not assign or transfer any right to use Skybitpro Services or any of their rights or obligations to third party Users under these Terms. In the event that the Users act against the provision of this article,  Skybitpro may temporarily/permanently suspend, freeze or cancel the use of accounts Cryptocurrency funds will not be affected under these circumstances.",
          "Site’ye üye olurken verilen bilgilerin doğruluğundan ve gizliliğinden, ayrıca şifre ve kullanıcı adının korunmasından, başka kimselerle paylaşılmamasından kullanıcının kendisi sorumludur. Bu bilgilerin yetkisiz kişiler tarafından ele geçirilmesi ve Skybitpro servislerinin kullanılması halinde Skybitpro hiçbir sorumluluk kabul etmemektedir.": "All Users register for Skybitpro, must provide their real name, email address and passwords, and are responsible for the security and privacy of their Usernames and passwords. Skybitpro cannot be held responsible for any unauthorized access or use of their Username and password by the third parties.",
          "Site’nin, kullanıcının hatasından doğan veya doğacak, her türlü zarar ve/veya kayıplardan dolayı Skybitpro yönetim kurulu üyeleri, yöneticileri, çalışanları ve bu sitede yer alan bilgileri hazırlayan kişilerin hiçbir hukuki ve cezai sorumluluğu bulunmamaktadır.": "Skybitpro assumes no liability for any loss and/or damage caused by your use of any Skybitpro services or your unawareness of the risks associated with the use of Digital Assets or with your use of Skybitpro Services. In no event will Skybitpro, its affiliates, their respective shareholders, members, directors, officers, employees or any individuals designed any content format presented on the Website be liable for any incidental, indirect, special, punitive, consequential or similar damages or liabilities.",
          "Kullanıcılar yalnızca hukuka uygun amaçlarla Site üzerinde işlem yapabilirler. Kullanıcıların hukuka aykırı işlem yapmalarının fark edilmesi halinde, Skybitpro hiçbir şekilde sorumlu olmadığı gibi, Skybitpro, her türlü hukuki yola başvurma ve gerekli güvenlik tedbirlerini alma, hakkına sahiptir.": "Users agree not to use the services in an illegal way. If Users breach our enforcement of these Terms, or violate these Terms,, regulation, or rights of any third party during your use of the Services, Skybitpro has the right to take any legal action, and security measures.",
          "Kullanıcıların Site üzerinde aldığı hizmetleri kullanarak yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Skybitpro’nun hiçbir hukuki ya da idari sorumluluğu bulunmamaktadır.": "All Users of the Website hereby accept that they are responsible for their action while using the Services Skybitpro provides including every transaction and trading activities they carry out. Skybitpro does not hold any legal or administrative liability.",
          "Kullanıcılar sitede hesap sahibi olmak için bir üye formu doldururlar. İşlem yapmak isteyen kullanıcıların kimlik ve adres bilgilerini belgelemek zorundadırlar. Aksi takdirde, Skybitpro’ün işlem yapmamak ya da hesabı durdurma hakkı tek taraflı olarak saklıdır.": "All Users complete a form to have an account on Skybitpro. In order to use the services, Users shall need to show a proof of address and identity. Otherwise, Skybitpro reserves the right to suspend/ or not to take any actions on the User’s account.",
          "Skybitpro, işlemlerin şüpheli görülmesi halinde, kullanıcıların yaptıkları para transferlerini iade etme hakkına sahiptir.": "Skybitpro has the right to reimburse if the transactions are deemed suspicious.",
          "Para yatırma işlemlerinin, Kullanıcı ile aynı ismin altına kayıtlı olan banka hesabından yapılması zorunludur.": "All transactions should be carried out via a bank account registered under the User’s name.",
          "ATM’ler üzerinden kartsız bankacılık yöntemleri ile transfer yapılmamalıdır. Bu tip işlemlerin saptanması durumunda, Skybitpro’nun söz konusu transferleri iade etme hakkına sahiptir.": "Account holders should not perform any cardless transaction via ATMs. Skybitpro holds the right to reimburse the transferred amount under these circumstances.",
          "Para yatırma ve çekme işlemleri sürelerinde beyan edilen sürelerin dışında işlem yapılması durumunda Skybitpro doğacak veya doğabilecek zararlar veya kayıplardan sorumlu değildir.": "Skybitpro cannot be held accountable for any risk of economic losses when purchasing, selling, holding and/or trading outside the declared periods.",
          "Skybitpro reddettiği ve gerçekleştirmediği transferlerin iadesi için kimlik ibrazı ve transfer gerçekleştiren kişinin banka hesabı bilgisini isteme hakkında sahiptir.": "Skybitpro has the right to demand proof of identification and bank account information of the account from which the transfer initiated for refund purposes for the amount that was initially rejected or failed.",
          "Skybitpro para yatırma ve çekme kurallarında değişiklik yapma hakkına tek taraflı olarak sahiptir. Para yatırma ve çekme kuralları, site üzerindeki yardım sayfalarında ve diğer sayfalarda bulunan uyarılar ve kuralların bütünüdür.": "Skybitpro unilaterally  reserves the right to modify regulations and rules regarding depositing and withdrawal. Rules regarding depositing and withdrawals include all the regulations on the Website, help pages and similar related pages and warnings.",
          "Skybitpro destek hizmetlerini yalnızca destek@skybitpro.com e-posta adresi üzerinden sağlamaktadır. Skybitpro bu e-posta adresi dışında bir yöntem ile kullanıcılarına destek hizmeti sağlamaz, destek yazışmalarında şifre sormaz, kullanıcılarından kripto para göndermeleri için bir kripto para adresi bildirmez. Bu husus bir tebliğ niteliğinde olup, Skybitpro bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş bir e-posta sebebiyle kullanıcılarının uğradığı ya da uğraması muhtemel zarar ve kayıplardan sorumlu tutulamaz.": "",
          "Kullanıcılar kendi hesaplarının güvenliklerini sağlamak zorundadır. Tavsiye edilen güvenlik önlemleri:": "Users are responsible for the security and privacy of their accounts. Recommended safety measures are below.",
          "Güçlü bir şifre kullanılması": "Using a strong password",
          "Kullanıcı adı ve şifresinin kimseyle paylaşılmaması": "Not sharing the Username and password with any other individuals or third parties",
          "Site’de kullanılan şifrenin yalnızca Site’de kullanılması": "Use of the password should be limited to the Website only.",
          "Site’ye her zaman “https” yoluyla bağlantı kurulması ve siteye girişlerde “https://www.Skybitpro.com” adreslerinin kontrol edilmesidir. Tarafımızca bildirilen güvenlik önlemleri, tavsiye niteliğinde olup, söz konusu güvenlik önlemleri alınmış olmasına rağmen, kullanıcının doğmuş ya da doğacak olan zararlarından Skybitpro hiçbir şekilde sorumlu tutulamaz.": 'Always connecting the Website via "https" only and controlling the  "https://www.Skybitpro.com" url upon visiting.',
          "Skybitpro bir kripto para alış satış platformudur. Site üzerinden kullanıcılar birbirlerine açık arttırma veya eksiltme yöntemi ile kripto para birimlerini alır veya satarlar. Skybitpro bir aracı sitedir. Sitede oluşan fiyatları Skybitpro belirlemez. Fiyatlarda olan değişikliklerden dolayı kullanıcıların zarar veya kayıplara uğramasından ve doğan ya da doğacak zararlardan Skybitpro sorumlu tutulamaz.": "Skybitpro is a cryptocurrency trading platform. Users sell or/and buy cryptocurrencies via auctions. Skybitpro is only the facilitator. Skybitpro does not define the prices on the Website. Skybitpro cannot be held responsible for existing or future damages and/or losses incurred by Users due to changes in prices.",
          "Skybitpro benzer alış satış platformlarından bağımsız bir şirkettir ve herhangi bir kuruluşun temsilcisi değildir. Kullanıcı tarafından Skybitpro haricinde bir alış satış platformunda yapılan alış satış işlemlerinden ve bu işlemlerden doğan ya da doğacak zararlardan Skybitpro hiçbir şekilde sorumlu tutulamaz.": "Skybitpro independently operates and does not represent any institution or organisation. In no event will Skybitpro be accountable for any damage and/or losses as a result of the buying and/or selling activities of the Users outside Skybitpro.",
          "Kripto para birimlerine yatırım yapmanın getirdiği belli başlı riskler vardır. Kullanıcılar işbu sözleşmenin kabul edilip onaylanması ile kripto para birimlerine yatırımının risklerini anladıklarını ve yatırımın sebep olduğu ya da olabileceği zarar ve ziyanlardan Skybitpro’yu sorumlu tutmayacaklarını beyan ve taahhüt ederler. Fiyat değişimlerinden oluşan kâr ve zararlardan kullanıcılar sorumludur. Kullanıcıların, bir kayıp ya da zarar yaşaması durumunda Skybitpro’den hiçbir hak talep edemezler.": "All Users of Skybitpro must understand the risks involved in Digital Assets trading, and are recommended to exercise prudence and trade responsibly within their own capabilities. You are aware of the risks associated with transactions of digital currencies and their derivatives; you shall assume all risks related to the use of Skybitpro services and transactions of digital currencies and their derivatives; and Skybitpro shall not be liable for any such risks or adverse outcomes.",
          "Skybitpro üzerinden satın alınan kripto paraların nasıl kullanıldığının, nereye ve ne amaçla transfer edildiğinin, bir suçta kullanılıp kullanılmadığının, teknik olarak takibi mümkün değildir. Kripto paraların kötüye kullanılmasından doğan ya da doğacak, kullanıcı ya da üçüncü kişilerin zarar ve kayıplarından, menfi veya müspet zararlarından Skybitpro sorumlu tutulamaz.": "It is not possible to track cryptocurrencies bought through Skybitpro, along with the purpose and address of the transaction and whether they are used for criminal or any illegal purposes. Skybitpro  assumes no liability for any loss or damage arising from the use of the User account by the Users or any third party with or without User’s authorization.",
          "Kripto para kullanımı ile ilgili yasal sorumluluklar ve vergi mükellefiyetlerinden kullanıcılar bizzat sorumludur. Skybitpro, Kullanıcıların kazanç ve kayıplarından oluşan vergisel mükellefiyetlerinden sorumlu tutulamaz.": "Users accept that they own the responsibility on legal and taxation liabilities while using cryptocurrency services. Skybitpro cannot be held accountable for legal and taxation liabilities of Users during cryptocurrency usage.",
          "Skybitpro, Kullanıcılar adına muhafaza edilen hesaplarda bulunan kripto paraların güvenliği için basiretli bir tüccar olarak azamı özeni göstermekle yükümlüdür. Kullanıcılara ait kripto paralar herhangi bir siber saldırıya karşı internet bağlantısı olmayan ortamlarda (cold storage) saklanır. Ancak Skybitpro’nun tüm bu çabalarına rağmen yine de oluşabilecek hırsızlık/dolandırıcılık olaylarından dolayı Skybitpro sorumlu tutulamaz.": "Skybitpro has been committed to maintaining the maximum security measures to protect as a prudent merchant, and has implemented industry standard protection for the Services. Users' cryptocurrencies are stored in cold storage in case of any cyber attacks. However, despite all these efforts of Skybitpro, Skybitpro cannot be held responsible for theft/fraud events that may occur.",
          "Site, gerçek kişilere hizmet verir.": "The website provides service for real individuals.",
          "Kullanıcıların 18 yaşından büyük olması zorunludur. Kullanıcı işbu sözleşme kapsamında 18 yaşından büyük olduğunu beyan ve taahhüt etmektedir. Skybitpro, herhangi bir nedenle sahibinin 18 yaşından küçük olduğunu tespit ettiği veya 18 yaşından küçüklerce kullanıldığından şüphe ettiği Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir. 18 yaşından büyük olduğunu taahhüt eden Kullanıcı, bildirdiği tüm bilgilerin doğruluğundan res’en sorumludur. Skybitpro, Kullanıcı ile ilgili tüm işlemlerde bu beyana itimat eder ve buna göre davranır.": "Users declare that all Users should be at least 18 or are of legal age to form a binding contract under applicable laws. Skybitpro has the right to suspend/cancel the User accounts without further notice, if the account holder is determined to be under the age of 18 or suspected that the account is used by persons under the age of 18. Skybitpro relies upon the declaration of User and acts accordingly.",
          "Her Kullanıcı, sadece bir Kullanıcı hesabına sahip olabilir. Kullanıcı Site’yi sadece işbu Sözleşme’de tanımlanan hizmetlerden faydalanılması amacı ile kullanabilir. Skybitpro, aynı kişi tarafından kullanılan birden fazla Kullanıcı hesabı bulunması durumunda ilgili Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir.": "Each User may only have one User account. Users may use the Website to benefit from the services aforementioned. Skybitpro reserves the right to suspend/cancel the account that has been used by multiple individuals once determined without further notice.",
          "Skybitpro, işlemlerin teknik hatalardan dolayı, gerçekçi olmayan fiyatlardan gerçekleşmesi gibi durumlarda, sistemi düzeltmek ve doğru çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri alabilir. İptal ve geri alma sırasında bu Kullanıcı hesapları dondurulabilir, eğer herhangi bir ödeme yapıldı ise haksız yapılmış ödemenin iadesi istenebilir, iade edilmemesi halinde Skybitpro her türlü hukuki yola başvurma hakkına sahiptir. Bu tarz işlemlerden dolayı Skybitpro sorumlu tutulamaz ve hak talep edilemez.": "Skybitpro may cancel or withdraw the transactions to fix any issues or errors or trades performed at prices that are unordinary. Transacted amounts can be cancelled or reimbursed during cancellation or suspension, and should there be any wrongful payments transacted, the amount shall be reimbursed. If the amount is not to be refunded, Skybitpro reserves the right to take legal action. Under these circumstances Skybitpro cannot be held accountable for the actions aforementioned.",
          "Skybitpro, internet sitesinde oluşabilecek teknik arızalardan dolayı hiçbir şekilde sorumlu tutulamaz. Kısa süreli yada uzun süreli teknik arızalardan dolayı doğrudan veya dolaylı olarak doğan ve doğabilecek hiçbir zarardan Skybitpro sorumlu tutulamaz.": "Skybitpro cannot be held accountable for any technical issues that might occur on the Website. Skybitpro will not be responsible for any loss and/or damages that might occur as a result of these technical issues.",
          "Kullanıcıların Skybitpro ile sözlü ve yazılı iletişimde, saygısızlık ve hakaret içeren tavırları karşısında, Skybitpro`nun Kullanıcı hesabını dondurma, kapatma ve hukuki yollara başvurma hakkı saklıdır.": "Skybitpro reserves the right to suspend/deactivate or/and close the accounts of the Users that use offensive language or perform disrespectful manners during their verbal and written correspondence.",
          "Ücretlendirme": "Fees",
          "Skybitpro, Hizmet’lerle ilgili ücretleri Site’nin Yardım bölümünde ilan edecektir. Site’nin yardım bölümünden ulaşılabilmektedir. Ücretler, yardım bölümünde ilan edildiği andan itibaren geçerlilik kazanacaktır.": "Skybitpro will announce the respective prices on the Services section located on the Help section of the Site. Fees will be valid from the moment they are announced in the help section.",
          "Skybitpro, her alış ve satış işlemlerinde kullanıcılarından kendi belirlediği bir oran üzerinden komisyon (hizmet bedeli) alma hakkına sahiptir. Skybitpro, zaman zaman bu ücretler ve oranlar üzerinde değişiklik yapma hakkına sahiptir.": "Skybitpro has the right to charge commission (service fee) at a determined rate for every purchase and transaction it provides. Skybitpro reserves the right to change these fees and rates at any time.",
          "Skybitpro, her para çekme işleminde kullanıcılarından kendi belirlediği bir işlem ücreti alma hakkına sahiptir. Kullanıcı, iş bu sözleşmenin kabul ve onaylanması ile, bu işlem ücretinin alınmasını kabul etmiş sayılacaktır.": "Skybitpro is entitled to charge its users a transaction fee that it determines for each withdrawal. With the acceptance and approval of this contract, the user will be deemed to have accepted the receipt of this transaction fee.",
          "Kripto para transferleri iade edilemez, dolayısıyla Skybitpro tarafından tahsis edilen komisyon veya işlem ücretlerinin iadesi mümkün değildir ve kullanıcıların bu ücretlerin iadesini isteme hakkı bulunmamaktadır.": "Cryptocurrency transfers are non-refundable services. Consequently, commission or/and transaction fees charged by Skybitpro are also nonrefundable. Users hereby accept that they do not have the right to request refunds for these services.",
          "İş bu sözleşmenin kullanıcı tarafından kabul ve onaylanması halinde, Kişisel Verilerin Korunması hakkındaki kanundan doğan hak ve yükümlülükleri, Skybitpro ile Kullanıcı karşılıklı olarak kabul edilmiş sayılacak ve Kanun kapsamına uygun olarak davranacaklarını kabul ve tahahhüt ederler.": "Cryptocurrency transfers are non-refundable services. Consequently, commission or/and transaction fees charged by Skybitpro are also nonrefundable. Users hereby accept that they do not have the right to request refunds for these services.",
          "Skybitpro, Kullanıcıların şahsi bilgilerini (kimlik, fatura, adres, e-posta, telefon, faks, demografik bilgiler veya müşteri numarası vb.) yasal zorunluluklar veya kullanıcının açık rızası haricinde üçüncü şahıslara vermeyeceğini taahhüt eder.": "In the event of accepting to be bound by the rules on this agreement, Users acknowledge and declare the responsibility for any liability as a result of Protection of General Data Protection Regulatiın between Skybitpro and the User; they also acknowledge that they are bound by these terms.",
          "Kullanıcıların vermiş olduğu bilgiler Kullanım Sözleşmesi’nde belirtilen kurallar ve amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır.": "Skybitpro will keep User’s data protected and confidential and will not disclose personal details such as; identity, invoices, address, email address, phone number, fax, demographic information or customer ID willingly except for unless legally required and taken consent from the Users.",
          "Skybitpro, kullanıcıların IP adreslerini, Site’ye erişim sağladıkları cihazların modellerini, işletim sistemlerini ve tarayıcı bilgilerini tespit etmekte ve kayıt altına almaktadır. Skybitpro, bu kayıtları kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak, sistem ve kullanıcı hesap güvenliğini sağlamak, sahtecilik ile mücadele etmek ve kanuni yükümlülüklere uymak amaçları için kullanabilir.": "User’s personal details shall not be used for any purpose other than the rules and regulations that are aforementioned on the User Agreement, and shall not be shared with third parties.",
          "Skybitpro, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla birçok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde iş birliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. Skybitpro, iletişim faaliyetlerinde, kanunların öngördüğü şekilde izinli iletişim/pazarlama yapacağını, kullanıcının isteği dışında iletişim yapmamayı, kullanıcının sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder.": "Skybitpro may cooperate with many 3rd party institutions and organizations in various ways to use its services and services more effectively. This cooperation; advertising, sponsorship, permission-based marketing, data sharing and other legal and commercial means. Skybitpro hereby declares and undertakes that they will carry out authorised communication/marketing as stipulated by the laws in its correspondences, in order not to communicate against the user's consent, and to provide necessary tools that will enable the user to leave the platform free of charge and easily.",
          "Skybitpro, Site içerisinde başkaca sitelere link(bağlantı) sağlayabilir. Anlaşmalı olduğu 3. Partilerin reklamlarını ve/veya çeşitli hizmetlere ilişkin başvuru formlarını yayınlayabilir, Kullanıcıları bu formlar ve reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. Skybitpro, bu bağlantı yoluyla erişilen diğer sitelerin gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.": "Skybitpro may provide external links on the Website. They can publish third party ads or/and registration forms, Users can be prompted to these platforms via these links and ads. Skybitpro cannot take any responsibility on these privacy policies or content on the website once the user is prompted to these platforms.",
          "Skybitpro, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar:": "Skybitpro, may disclose your personal data outside the Privacy Policy to the following third parties:",
          "Kanun, Yönetmelik vb. yetkili hukuki makamlar tarafından çıkarılan ve yürürlükte bulunan yazılı hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,": "Relevant government regulatory agencies or law enforcement agencies to comply with laws or regulations formulated by government authorities;",
          "Skybitpro’un kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,": "Regarding certain circumstances where the terms and regulations on the agreement are followed and implemented,",
          "Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda kullanıcılarla ilgili bilgi talep edilmesi hallerinde,": "In cases where the further information about the Users is requested in line with a procedural investigation or inquest carried out by authorities and/or administrative or legal authorities,",
          "Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu hallerde.": "In cases where User’s rights or/and safety need to be protected by disclosing their details.",
          "Skybitpro, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanıcıyı veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.": "Skybitpro reassures its users to take and perform all necessary safety measures and actions to keep and maintain the security and privacy of these details to prevent any kind of confidential information from being disclosed to public domains or to unauthorised Users and/or third parties.",
          "Sorumsuzluk Beyanı": "Release of Liability",
          "Skybitpro.com Site’si üzerinde sunulan her tür içerik, 3. Partilerden ve kamuya açık kaynaklardan toplanan verilerden oluşmaktadır. Tüm veriler, analizler, raporlar, istatistikler herhangi bir düzenleme veya yönlendirme olmadan bilgileri otomatik işleme tabi tutmuş bir yazılım tarafından işlenmekte ve objektif olarak sunulmaktadır. Skybitpro tarafından sunulan her türlü haber ve raporlar; sadece bilgilendirmeye ve tavsiyeye yöneliktir ve doğrudan çözüm, sonuç, hukuki görüş, politik ve sosyolojik araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir. Bu tür durumlarda Skybitpro hiçbir sorumluluk üstlenmez.": "Content types presented on Skybitpro consist of the data collected from third parties and publicly available sources. All data, analyses, statistics on Skybitpro.com are presented objectively by a software that automatically processes and presents the information without any modification or instructions. All types of news and reports that are presented on Skybitpro is only displayed for informative and recommendation purposes and does not contain any direct solution, legal opinion, political or sociological resource facts, therefore the accuracy of these information can not be guaranteed. Data may contain controversial and inconsistent sentences. Skybitpro cannot be held accountable in such cases.",
          "Skybitpro, kendisi tarafından sunulan içeriklerin kullanıcı yoluyla herhangi bir kişi veya kuruluş tarafından elde edilecek sonuçlarla ilgili olarak, her türlü ticarete elverişlilik, performans, pazarlanabilirlik, belli bir amaca uygunluk veya belirli bir amaca uygunluk garantileri de dâhil olmak üzere ister açık ister örtülü olsun, hiçbir şekilde garanti vermez. Tüm içerikler kullanıcıya “olduğu gibi” sunulmaktadır. Skybitpro, içeriklerin kullanılmasından doğabilecek kâr kayıplarından ya da menfi zararlardan sorumlu tutulamaz. Skybitpro tarafından sunulan içeriklerde, herhangi bir Kişiye/Kuruma/Şirkete/Markaya yapılan atıf, bu Kişilerin/Kurumların/Şirketlerin/Markaların piyasa değerine, çeşitli kriterlerdeki sıralamalarına, marka değereine etki yapacak veya hisse senetlerinin satın alınmasına, satılmasına veya elde tutulmasına yönelik bir tavsiye değildir.": "Skybitpro does not provide any guarantee regarding the results to be obtained by any individual or organisation, including merchantability, performance, marketability, fitness for a particular purpose. All content provided to users is presented “as is” on the Website. For this reason, Skybitpro declines any liability for profit loss and/or negative damages that may arise from the use of the contents. Any reference attributed to People/Institution/Company/Brand presented within the content on Skybitpro, does not offer a recommendation that will have an impact on the market values, various rankings and brand values such as buying, selling or holding stocks of these People/Institutions/Companies/Brands.",
          "Uygulanacak Hukuk ve Yetki": "Applicable Law and Authority",
          "Site’nin kullanımından doğan ve/veya yasal uyarıda yer alan koşul ve hükümlere ilişkin ve/veya bu Site ile bağlantılı olarak çıkabilecek uyuşmazlıklarda işbu Kullanım Sözleşmesi geçerlidir.": "This Use of Agreement is valid; in disputes may arise regarding the usage of the Website; or/and in disputes in relation to the terms and conditions in addition to the disputes that may arise associated with the Website.",
          "Sözleşme Değişiklikleri": "Changes to This Agreement",
          "Skybitpro bu yasal uyarıda yer alan tüm koşulları ve hükümleri önceden bir ihbara gerek kalmaksızın değiştirme ve güncelleme hakkına tek taraflı olarak haizdir.": "Skybitpro unilaterally reserves the right to modify/update terms and rules aforementioned in this legal disclaimer without notice or obtaining User’s consent or approval.",
          "Yürürlük ve Kabul": "Acceptance and Effectiveness of the Terms",
          "Bu internet sitesinin herhangi bir sayfasına girerek, Kullanım Sözleşmesi’nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz. Kullanım Sözleşmesi Skybitpro tarafından Site üzerinde ilan edildiği tarihte yürürlük kazanır.": "By accessing any page of the Website, you hereby accept and declare  that you have read all of the terms, and you fully understand its contents and approve all its provisions. The Usage Agreement becomes effective immediately on the date it is announced by Skybitpro on the Website.",
          "Kullanım Sözleşmesi’nin şartlarını kabul etmeyen kullanıcıların Site’yi ve Site üzerinden sağlanan servisleri kullanmamaları gerekmektedir. Aksi halde doğmuş ya da doğacak olan zararlardan Skybitpro sorumlu tutulamaz.": "Users who do not accept the rules of the Terms of Use should not use the Website and other services provided by the Website. Otherwise, Skybitpro will not be held liable or responsible for any damages or/and losses.",

          "İŞLEM PLATFORMU": "TRADING PLATFORM",
          "Platformumuzu Keşfedin": "Explore more about Skybitpro",
          "İnternetin olduğu her yerde yatırım yapmanın tadını çıkarın.": "Invest anywhere and anytime with Skybitpro.",
          "Sirix Trader, kripto para piyasalarına erişim sağlayan en güvenilir platformlardan biridir. İnternet bağlantısının olduğu her yerde kolayca işlem yapmanıza olanak tanıyan Sirix Trader platformu sayesinde:": "Sirix is among the top most trusted cryptocurrency platforms. You can buy, sell and remain up to date with cryptocurrencies as long as you have an internet connection and you can also;",
          "Piyasayı canlı ve anlık olarak takip edebilir,": "Follow the market daily,",
          "Sunduğu veriler sayesinde işlemler yapmadan önce çeşitli değişkenler konusunda daha kolay öngörüde bulunabilir,": "Proactively take actions and foresee numerous variables thanks to the data the platform provides you",
          "İşlemlerinizi anlık olarak, zaman kaybetmeden gerçekleştirebilirsiniz.": "Save time and invest simpler and wiser.",
          "Skybitpro’yu Keşfedin": "Explore Skybitpro",
          "Web Sitemizi Kullanmaya Başlayın!": "Start Using Our Website Now!",
          "Skybitpro’ya üye olun ve kazanç sağlamaya başlayın.": "Sign up Skybitpro and earn more.",
          "Sirix Web Trader platformlu web sitemiz sayesinde kripto para piyasalarına dilediğiniz zaman ulaşabilir, kripto para işlemlerinizi kolayca gerçekleştirebilirsiniz. Herhangi bir indirme işlemi yapmadan, doğrudan üye olarak ya da hesap satın alarak size özel ayrıcalıkların tadını çıkarabilirsiniz.": "You can reach our services regardless of time and place via our mobile app developed with Sirix Mobile Trader platform. You can sign up or sign into your account without the need to download anything and start enjoying the benefits.",
          "Web sitemizi kullanarak:": "Our website allows you to;",
          "Kaldıraçlı kripto para alım-satım işlemlerinizi kolayca gerçekleştirebilir,": "Manage your leverage trading easily,",
          "Kesintisiz ve sorunsuz bir şekilde işlem yapabilir,": "Enjoy uninterrupted and smooth trading process",
          "Size özel hesap türlerini seçerek yatırımlarınızı daha ayrıcalıklı ve avantajlı şekilde gerçekleştirebilirsiniz.": "Pick an account type that is suitable for your interest and get more advantage and benefits while investing.",
          "Mobil Uygulamamızı İndirin!": "Download Our Mobile App!",
          "Sadece bir cep telefonu ile yatırımlarınızı her yerden takip edin.": "A mobile phone is all you need to follow your investment anywhere and anytime.",
          "Skybitpro’nun Sirix Mobil Trader platformlu mobil uygulaması sayesinde, tüm hizmetlerimize cep telefonunuzdan da ulaşabilirsiniz. Bunun için hem iOS hem de Android işletim sistemleriyle uyumlu mobil uygulamamızı indirmeniz yeterli.": "Skybitpro mobile app developed with Sirix Mobile Trader platform allows you to enjoy our various services. You can download the mobile application that is both available for iOS and Android operating systems.",
          "Kullanıcı dostu bir arayüze sahip mobil uygulamamızı indirerek:": "Downloading our user friendly mobile app allows users to:",
          "Yatırımlarınızı dilediğiniz yerde yapabilir,": "Invest regardless of time and place,",
          "Tüm piyasayı cep telefonunuz üzerinden takip edebilir,": "Daily access to market,",
          "Anlık işlemlerinizi kaçırmadan, hızlıca gerçekleştirebilirsiniz.": "Fast and immediate trading process.",
          "Platformlarımız": "Our Platforms",
          "WEB UYGULAMAMIZ": "OUR WEB APP",
          "MOBİL UYGULAMAMIZ": "OUR MOBILE APP",

          "FİNANSAL EĞİTİM": "FINANCIAL EDUCATION",
          "İŞLEM PLATFORMLARI": "TRADING PLATFORMS",
          "KOMİSYONLAR": "COMMISSION",
          "HAKKIMIZDA": "ABOUT US",
          "BİZE ULAŞIN": "CONTACT US",
          "Tüm Hakları Saklıdır.": "All Rights Reserved.",
          "AML POLİTİKASI": "AML POLICY",
          "GİZLİLİK POLİTİKASI": "PRIVACY POLICY",
          "Sayfalar": "Pages",
          "İletişim": "Contact",

          "Finansal Eğitim": "Financial Training Lessons",
          "Kaldıraçlı kripto para alım ve satım işlemler yapmaya yeni başladıysanız ya da daha fazla tecrübe edinmek istiyorsanız finansal eğitimlerimize katılabilirsiniz. Uzman eğitmenler tarafından, online olarak gerçekleştirilecek eğitimlere katılarak kripto para ve yatırım dünyası hakkında daha fazla bilgi sahibi olabilirsiniz.": "If you are new to the concept of leverage in cryptocurrency trading, you are always welcomed in our financial training lessons to learn and explore more. Our experts will guide you on investments and various topics on cryptocurrency through our training lessons.",
          "Online finansal eğitimlerimiz hakkında daha detaylı bilgi sahibi olmak için bizimle hemen iletişime geçebilirsiniz.": "Contact us now to get more details or to join our sessions and explore the power of the investment world.",

          "Tüm kripto para işlemlerinizi": "Do all your crypto money transactions",
          "hızınız kesilmeden, güvenle yapın!": "safely, without slowing down!",

          "7/24 BİZE ULAŞIN": "REACH US 24/7",
          "Sizlere sunduğumuz hizmet, ürün veya servisler hakkındaki her türlü soru, görüş, öneri veya şikayetlerinizi aşağıdaki iletişim kanallarını kullanarak bize iletebilirsiniz.": "Send us all your questions, opinions, suggestions or complaints about the products or services we offer, by using the communication channels below.",
          "ADINIZ": "NAME",
          "SOYADINIZ": "SURNAME",
          "EMAIL ADRESİNİZ": "E-MAIL ADDRESS",
          "TELEFON NUMARANIZ": "YOUR PHONE NUMBER",
          "MESAJINIZ": "YOUR MESSAGE",
          "Adınızı Giriniz": "Enter Your Name",
          "Soyadınızı Giriniz": "Enter Your Surname",
          "Email Adresinizi Giriniz": "Enter Your E-Mail Address",
          "Telefon Numaranızı Giriniz": "Enter Your Phone number",
          "Mesajınızı Giriniz": "Enter Text",
          "GÖNDER": "SEND",
          "Mail İsteğiniz Alındı!": "Your Mail Request Has Been Received!",
          "En kısa süre içerisinde geri dönüş sağlayacağız.": "We will get back to you as soon as possible.",
          "Bir Hata Oluştu!": "Something went wrong!",
          "Lütfen sayfayı yenileyip tekrar deneyin.": "Please refresh the page and try again.",

          "SKYBITPRO İLE KRİPTO PARA DÜNYASINA KATILIN!": "JOIN THE WORLD OF CRYPTO WITH SKYBITPRO!",
          "Skybitpro, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. Skybitpro ile dijital para borsalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "Skybitpro offers a reliable and transparent service approach, as well as low commission principles. Maximize your trading experience on digital currency exchanges with Skybitpro.",
          "HEMEN BAŞLAYIN!": "START NOW!",

          "": "Rates",
          "SEMBOL": "SYMBOL",
          "SON FİYAT": "LAST PRICE",
          "24S DEĞİŞİM": "CHANGE IN 24H",
          "1H DEĞİŞİM": "CHANGE IN 1 W",
          "24S HACİM": "VOLUME IN 24 H",
          "SATIN AL": "BUY",
          "PİYASA EKRANINI GÖRÜNTÜLE": "VIEW MARKET SCREEN",
          "GÜNCEL": "CURRENT",
          "Fiyat Listesi": "Rates",
          "KRİPTO PARA": "CRYPTO CURRENCY",
          "Borsa": "Exchange",

          "Kripto Para İşlemleri İçin Güvenilir Adres": "Reliable Source for Cryptocurrency Transactions",
          "Hızlı altyapı, 7/24 ulaşma imkanı ve alanında uzman kadromuzun desteği sayesinde kripto para işlemleri ile geleceğe yatırım yapmak hem çok kolay hem de çok güvenli.": "Investing in the future with cryptocurrency transactions is easy and safe with our fast infrastructure, 24/7 accessibility, and the support our expert staff offers.",
          "Hızlı Altyapı Avantajı": "Fast Infrastructure",
          "Hızlı altyapı avantajımız ile işlemlerinizi kesintisiz bir şekilde gerçekleştirin. Herhangi bir sorun ile karşılaşmadan, tüm kripto para alım ve satım işlemlerinizi kolayca gerçekleştirin.": "Perform your transactions without interruptions with our fast infrastructure. You can easily perform all your cryptocurrency buying and selling transactions without any problems.",
          "Uzman Kadro Desteği": "Expert Staff",
          "İşlem yaparken karşılaştığınız herhangi sorunu hızlıca çözmek, platformumuz ile ilgili tüm konularda destek almak ve aklınıza takılan sorulara kolayca yanıt bulmak için alanında uzman ekibimizden destek alın.": "Get support from our expert team to quickly solve any problem you might encounter trading, get support on all issues related to our platform, and easily find answers to your questions.",
          "7/24 Ulaşabilme İmkanı": "24/7 Access",
          "Kripto para almak ya da satmak için belirli saatleri beklemenize gerek yok. Çalıştığımız bankalar üzerinden 7/24 para transfer işlemleri yapın, dilediğiniz saatte kripto para alıp satın.": "Don’t wait for certain hours to buy or sell cryptocurrencies. Make money transactions 24/7 through the banks we work with, buy and sell crypto money at any time.",
          
          "3 Kolay Adımda Kripto Para Almaya Başlayın!":"Buy and Sell with 3 Easy Steps!",
          "1. Adım": "Step 1",
          "Kayıt Ol": "Sign Up",
          "2. Adım": "Step 2",
          "Hesabını Doğrula": "Verify Account",
          "3. Adım": "Step 3",
          "Para Yatır": "Invest",
          "HEMEN BAŞLA": "START NOW",

          "Neden Skybitpro ?": "Why Skybitpro?",
          "GÜVENLİ DEPOLAMA": "SAFE STORAGE",
          "Dijital varlıkları, soğuk cüzdanlar ve Çok Taraflı hesaplama (MPC) teknolojisinin bir kombinasyonunu kullanarak yönetiyoruz.": "We manage digital assets using a combination of cold wallets and Multilateral computing (MPC) technology.",
          "ÖNCE GÜVENLİK": "SAFETY FIRST",
          "Fonlarınızı güvende tutmak için en son teknolojileri kullanıyoruz ve güvenlik açıklarının ve istismar girişimlerinin önünde yer alıyoruz.": "We use the latest technologies to keep your funds safe and we’re always one step ahead of security vulnerabilities and exploit attempts.",
          "HIZLI PARA ÇEKME": "FAST WITHDRAWAL",
          "Çok taraflı bilgi işlem kullanarak, sıkı güvenlik standartlarımızı korurken günün 24 saati hızlı para çekme işlemleri sunabiliyoruz.": "Using multi-party computing, we are able to offer fast withdrawals 24 hours a day while maintaining our strict security standards.",
          "7/24 DESTEK": "24/7 SUPPORT",
          "Bir sorununuz mu var yoksa bize bir şey mi sormanız gerekiyor? Müşteri destek ekibimiz 7/24 yardıma hazırdır.": "Have a problem or need to ask us something? Our customer support team is here to help 24/7.",

          "SSS": "FAQ",
          "Sıkça Sorulan Sorular": "Frequently Asked Questions",
          "Skybitpro nedir?": "What is Skybitpro?",
          "2019’dan bu yana faaliyet gösteren Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda yatırım yapmak isteyenlerin işini kolaylaştıran çözümler sunar.": "Operating since 2019, Skybitpro offers solutions that make investing in leveraged cryptocurrency trading easy.",
          "Skybitpro güvenilir mi?": "Is Skybitpro safe?",
          "Müşteri odaklı bir yatırım platformu olan Skybitpro, kullanıcılarının beklentilerini en iyi şekilde karşılamayı hedefler. Uzman kadrosunun da desteği ile müşteri memnuniyetini üst düzeyde tutmak için çalışır. Faaliyet gösterdiği ilk günden bu yana güvenilirliğini hiç kaybetmez, güvenlik konusunda kullanıcılarından da tam not alır.": "Skybitpro is a customer-oriented investment platform and aims to meet the expectations of its users in the best way possible. It works to keep customer satisfaction at the highest level with the support of its expert staff. Skybitpro has maintained its credibility and got thumbs up from its users in terms of security since day 1.",
          "Komisyonlar ne kadar?": "How much are the commissions?",
          "Skybitpro'da işlemlerin tutarına ve şekline göre çeşitli komisyonlar uygulanır. Tüm işlemlere Katma Değer Vergisi (KDV) dahildir ve ekstra bir ücret talep edilmez. Ayrıca komisyonlar, emir verildiği tarihte uygulanır. Emir tarihinden sonra komisyon bedellerinin azalması ya da artması dikkate alınmaz.": "Various commissions are applied according to the amount and type of transactions at Skybitpro. Value Added Tax (VAT) is included in all transactions and there is no extra charge. In addition, commissions are applied on the date the order is placed. The decrease or increase in commission fees after the order date is not taken into account.",
          "Komisyonları görmek için tıklayın": "Click to see commissions",

          "Bitcoin fiyatları nasıl belirlenir?": "How are bitcoin prices determined?",
          "Sahibi ve merkezi olmayan Bitcoin’in fiyatları, piyasa tarafından belirlenir. TOMYA gibi alım satım platformlarında arz ve talebe göre fiyatlar belirlenir. Platformlar birbirine bağlı olmadığı için küçük fiyat farklılıkları gözlemlenebilir. Ancak fiyat uçurumu yaşanmaz. Çünkü farklı platformlardan alınıp platform değişikliği yapılan kripto paralar sayesinde fiyatlar birbiri ile benzer şekilde azalır ya da artar.": "Prices are determined by the market as far as Bitcoin that doesn’t have an owner and a center goes. In trading platforms such as TOMYA, prices are determined according to supply and demand. There might be minor price differences as the platforms are not interconnected. However, there are no huge price gaps. Thanks to the crypto money bought from different platforms and changed the platform, the prices decrease or increase similarly.",
          "Bitcoin’in sahibi var mı?": "Does Bitcoin have an owner?",
          "Bitcoin’in herhangi bir sahibi ya da merkezi yoktur. Bitcoin, mining yani madencilik yapan kişiler tarafından üretilir. Dağınık şekilde örgütlenmiş, birbiri ardına eklenen ve kayıt tutan blok zincir yöntemi ile Bitcoin akışı gerçekleşir. Burada da bir sahiplik yoktur ve her kullanıcı eşit söz hakkına sahiptir.": "Bitcoin has no owner or center. It’s produced by miners. Bitcoin flows with the disorganized blockchain method that is added one after the other and keeps records. There is no ownership either and every user has an equal say.",
          "Bitcoin nakit paraya çevrilebilir mi?": "Can Bitcoin be converted to cash?",
          "Bitcoin’i nakit olarak kullanabilmek için kripto para borsalarına dahil olunması gerekir. Borsaya dahil olduktan sonra Türk Lirası olarak EFT ya da havale yöntemi ile paranızı hesabınıza aktarabilirsiniz.": "In order to use Bitcoin as cash, you need to be included in crypto currency exchanges. After you enter the stock market, you can transfer your money in Turkish Lira to your account by EFT or money order.",

          "Blok zinciri (Blockchain) ne demek?": "What is Blockchain?",
          "Blok zinciri temel olarak bir ağdır. Ancak bugüne kadar sıkça kullanılan sorumluluğun dağıtıldığı ağlardan farklı olarak merkezî bir yapı söz konusu değildir. Bilgiler bloklar üzerinde tutulur ve her blok, kendinden önce gelen blok ile ilişkilidir. Blokların tamamı kriptografi yöntemi ile birbirine bağlanır ve güvenliği sağlanır. Böylece dışarıdan her türlü müdahalenin önüne geçilir.": "Blockchain is basically a network. However, unlike frequently used networks where responsibility is distributed, there is no centralized structure. Information is kept on blocks, and each block is associated with the previous block. All of the blocks are connected to each other by the cryptography method and their security is ensured. Thus, any outside interference is prevented.",
          "Bilgilerin yer aldığı blokların açık anahtar denen özel bir kodu bulunur. Bu kod ile ilgili bloğa erişmek mümkündür. Gizli anahtar ise kullanıcıya aittir ve özeldir. Gizli anahtar ile kullanıcı, bilgilerini gözlemleyebilir. Kısacası blok zinciri; dağıtılmış bir muhasebe defteri gibidir, merkezi yoktur ve halka açık olmasına rağmen bilginin değiştirilebilmesi için onay gerekir. Günümüzde blockchain yöntemi, kripto paralar dışında çok sayıda farklı alanda kullanılıyor. Ancak yaygınlaşıp adından söz ettirebilmesini sağlayan Bitcoin oldu.": "The blocks containing the information have a special code called the public key. It is possible to access the related block with this code. The hidden key belongs to the user and is private. The user can observe their information with the hidden key. In short, the blockchain is like a distributed ledger. It is decentralized and although publicly available, consent is required before the information can be changed. The blockchain method is used in many different areas apart from cryptocurrencies. However, it was Bitcoin that made it popular and put it on the map.",
          "Blok zincirinin yöneticisi var mı?": "Is there an administrator of the blockchain?",
          "Bir blok zincirinde ağa dahil olabilen her kullanıcı eşit hakka sahiptir ve hem yöneten hem de yönetilendir. Dağıtılmış ve merkezi olmayan devasa bir veri tabanı olarak akılda canlandırılabilecek olan blok zinciri içerisinde kullanıcı dilediği zaman katılma ve ayrılma hakkına sahiptir.": "Every user who can join the network has equal rights and is both governed and managed in Blockchain. You can think of Blockchain as a huge distributed and decentralized database where the user has the right to join and leave at any given time.",
          "DeFi Nedir?": "What is DeFi?",
          "DeFi, yasal sınırlara ihtiyaç duyulmadan ve herhangi bir aracı kurum olmadan işlem yapılabilen finansal sistemlerine denir. DeFi platformları kullanıcılara; finansal yatırım hizmetleri, sigorta hizmetleri, bankacılık işlemleri gibi birçok alanda hizmet sunmaktadır.": "DeFi is called financial systems that can be transacted without the need for legal boundaries and without any intermediary institution. DeFi platforms provide users with services in many areas such as financial investment services, insurance services, and banking transactions.",
          "Piyasa": "Market",
          "Çatallanma nedir?": "What is forking?",
          "Bir blockchain ya da Türkçesi ile blok zincirinin iki farklı potansiyel zincirden ilerleyebilecek şekilde ayrılmasına çatallanma denir. İngilizce kaynaklarda bu isim “fork” olarak geçer. Çatallanma ile beraber blockchain içerisine yeni işlevler ya da yeni bir zincir eklenebilir. Çatallanmanın çeşitli sebepleri olabilir. Çatallanmaya en çok neden olan konular ise protokollerin değişmesi ve yeni bloklar açılabilmesidir. Hard fork yani sert çatallanma ve soft fork yani yumuşak çatallanma şeklinde iki çeşidi bulunur.": "The separation of a blockchain in such a way that it can progress from two different potential chains is called forking. New functions or a new chain can be added to the blockchain with forking. There can be various reasons for forking where the most common are the change of protocols and the opening of new blocks. There are two types of forking, one is being hard fork, the other soft fork.",
          "Piyasa Yapıcı (Maker) ve Piyasa Alıcı (Taker) Emir Nedir?": "What is a Market Maker and a Taker Order?",
          "Piyasa Yapıcı ve Piyasa Alıcı emirleri, piyasa için likiditeyi sağlayan emirler ile bu likidi kullanan emirlere denir. Piyasa Yapıcı emirler ve Piyasa Alıcı emirler piyasada farklı iki taraf olduğu için komisyon oranları birbirinden farklıdır.": "Market Maker and Market Taker orders provide and use liquidity for the markets. Since Market Maker orders and Market Taker orders are two different parties in the market, the commission rates are different from each other.",
          "Arbitraj nedir?": "What is Arbitrage?",
          "Kripto para piyasasında farklı borsalar üzerinden satışa sunulan dijital paraların, kar elde etmek için borsalar arası alım satım işlemlerine kripto arbitraj denir. Kripto arbitraj yaparken önemli olan detay ise işlem süresidir ve arbitraj işlemini yaparken çok hızlı hareket edilmeye çalışılır. Alım satım yapılarak takas edilebilen kripto paralar birçok kripto borsasından takip edilebilir.": "Crypto arbitrage is the process of buying and selling digital currencies offered for sale on different exchanges in the cryptocurrency to profit. The most important thing to look for when performing crypto arbitrage is the processing time. It is very important to act very quickly making the arbitrage process. Cryptocurrencies that can be traded by buying and selling can be followed on many crypto stock markets.",


          "AML Politikası": "AML Policy",
          "SKYBITPRO BİLİŞİM KARA PARA AKLAMAYI ÖNLEME (AML) POLİTİKASI": "SKYBITPRO IT ANTI-MONEY LAUNDERING (AML) POLICY",
          "Para aklama, yasa dışı kaynakların nakit veya görünürde meşru olan yatırımlara dönüştürülmesiyle örtbas edilmesidir.": "Money laundering is the cover-up of illegal resources by converting these resources into cash or seemingly legitimate investments.",
          "Skybitpro olarak müşterilerimize ve hesap güvenliğine büyük önem vermekle birlikte en iyi müşteri hizmetlerini sunma konusunu son derece ciddiye alırız. Her ne surette olursa olsun kara para aklanmasının önlenmesi amacıyla Kara Para Aklama Politikası’nı harfiyen uygularız. Bu kapsamda, Kara Para Aklama Politikası (bundan sonra “AML Politikası” olarak anılacaktır) Skybitpro tarafından para aklamayı önlemek amacıyla belirlenen prosedürleri ve mekanizmaları özetler.": "We pay great importance to our customers and account security, and take it very seriously to provide the best customer service. We strictly implement the Money Laundering Policy in order to prevent money laundering under any circumstances. The Money Laundering Policy (hereinafter referred to as the “AML Policy”) summarizes the procedures and mechanisms established by Skybitpro to prevent money laundering.",
          "Kara Para Aklama Önleme Politikası’nın amacı, risk temelli bir yaklaşımla Skybitpro Teknoloji Anonim Şirketi bünyesindeki müşterileri, işlemleri ve hizmetleri değerlendirerek riski azaltmak, suç gelirlerinin aklanmasının ve terörizmin finansmanının önlenmesi hakkında çalışanların bilinçlendirilmesi ve bilgi edinilmesi ve bu kapsamda Şirket’in faaliyetlerini, yürürlükteki mevzuat uyarınca gerçekleştirdiğinin müşterilere bildirilmesidir.": "The purpose of the Anti-Money Laundering Policy is to reduce the risk by evaluating the customers, transactions and services of Skybitpro Teknoloji Anonim Şirketi with a risk-based approach, to raise awareness of the employees and to obtain information about the prevention of money laundering and financing of terrorism, and by doing so, notifying the customers that the Company's actions are in accordance with the legislation in force. ",
          "AML Politikası kapsamında, Şirket faaliyetlerine halel gelmemesi amacıyla haklarında AML politikası işletilen müşteriler, www.skybitpro.com adresinden erişilen platforma (“Platform”) üye olan ve Platform’da sunulan hizmetlerden yararlanan ve karşılığında işbu AML Politikası’ndaki hususları kabul eden gerçek kişiyi ifade eder.": "Within the scope of the AML Policy, the customers for whom AML policy is operated in order not to harm the Company's activities, refer to the natural person who is a member of the platform (“Platform”) accessed from www.skybitpro.com, benefits from the services offered on the Platform and accepts the matters in this AML Policy in return.",
          "Skybitpro, AML Politikası’nı uluslararası hukuk norm ve standartlarına, yürürlükteki mevzuata uygun olarak düzenlemiş olup uygulanması zımnında, tüm müşterilerin kimliklerini makul düzeye kadar doğrulama, müşteriye ait işlemlerin izlenmesi için riske dayalı bir yaklaşım uygulama, müşteriler tarafından gerçekleştirilen herhangi bir şüpheli işlemi yürürlükteki mevzuat çerçevesinde ilgili kurum ve kuruluşlara bildirme ve işlemleri kaydetme, Şirket nezdinde AML Politikası’nın icra edilmesi ve uygulanabilirliğini koordine etmek için gerekli organizasyonu sağlama gibi birçok tedbir almıştır.": "Skybitpro has prepared its AML Policy in accordance with international legal norms and standards and applicable legislation and has taken many measures within the implementation of the policy, such as verifying the identities of all customers to a reasonable level, applying a risk-based approach to monitoring customer transactions, reporting any suspicious transactions carried out by the customers to the relevant institutions and organizations within the framework of the current legislation and recording the transactions, execution of the AML Policy before the Company and providing the necessary organization to coordinate its implementation.",
          "Buna göre Skybitpro aşağıdaki politikaları izler:": "Accordingly, Skybitpro follows the following policies:",
          "Suçlular ve/veya teröristlerle iş ilişkilerine girmemek,": "Not make business relations with criminals and/or terrorists,",
          "Suç ve/veya terörist faaliyetlerden kaynaklanan işlemleri işleme koymamak,": "Not processing transactions arising from criminal and/or terrorist activities,",
          "Suç ve/veya terörist faaliyetlerle ilgili herhangi bir işlemi kolaylaştırmamak.": "Not facilitating any action related to criminal and/or terrorist activities.",
          "Risk Değerlendirmesi": "Risk Assessment",
          "Skybitpro gerek ulusal gerekse uluslararası gerekliliklere uygun olarak kara para aklama ve terörizmin finansmanı için riske dayalı bir yaklaşım benimser. Dolayısıyla kara para aklamayı önleme ve terörizmin finanse edilmesine yönelik tedbirler, belirlenen risklerle orantılıdır ve kaynakların etkin bir şekilde adanmasına izin verir. Kaynaklar, öncelik temeline göre kullanılır ve en büyük risklere en büyük önem verilir.": "Skybitpro takes a risk-based approach to money laundering and terrorist financing in accordance with both national and international requirements. Thus, measures to prevent money laundering and financing terrorism are commensurate with the risks identified and allow for the effective dedication of resources. Resources are used on a priority basis and the greatest risks are given the highest consideration.",
          "Skybitpro, müşterilerinin finansal faaliyetlerinin izlenmesinde riske dayalı bir yaklaşım benimsemesi sebebi ile kara para aklama ve terörizmin finansmanını önlemek amacıyla aşağıdaki yöntemlerle risk analizi yapıp risk analizi sonucu elde ettiği bilgiler çerçevesinde ilgili müşterileri izlemeye alabilir.": "Since Skybitpro adopts a risk-based approach in monitoring the financial activities of its customers, in order to prevent money laundering and financing of terrorism, it can perform risk analysis with the following methods and monitor the relevant customers within the framework of the information obtained as a result of the risk analysis.",
          "Yüksek risk grubunda yer alan müşteri ve işlemler aşağıdaki gibidir:": "Customers and transactions in the high risk group are as follows:",
          "Tek bir kripto para alım-satım işleminin veya birden çok bağlantılı işlemlerin toplam tutarı 500.000 TL’ye eşit veya fazla ise,": "If the total amount of a single crypto currency transaction or multiple linked transactions is equal to or more than 500.000 TL,",
          "Tek bir kripto para takas işleminin veya birden çok bağlantılı işlemin toplam tutarı 500.000TL’ye eşit veya fazla ise,": "If the total amount of a single crypto currency exchange or multiple linked transactions is equal to or more than 500.000TL,",
          "Yürürlükteki mevzuat çerçevesinde şüpheli işlem bildirimini gerektiren durumlarda,": "In cases requiring suspicious transaction reporting within the framework of the current legislation,",
          "Önceden edinilmiş kimlik bilgilerinin doğruluğu ve yeterliliği konusunda şüphe olduğu takdirde,": "If there is doubt about the accuracy and adequacy of previously acquired credentials,",
          "Üçüncü kişi yararlanıcıları gizleme potansiyeline sahip karmaşık işlemleri gerçekleştirmesi halinde,": "If the third party carries out complex transactions that have the potential to hide beneficiaries,",
          "Para kaynaklarının kolayca doğrulanamayacağı durumlarda,": "Where funds cannot be easily verified,",
          "Ekonomik veya görünür herhangi bir meşru amaca sahip olağandışı işlemlerde.": "In unusual transactions with an economic or apparent legitimate purpose.",

          "İşlem İzleme": "Transaction Monitoring",
          "Müşterilerin işlemlerinin izlenmesi ve elde edilen verilerin analizi de risk değerlendirmesi ve şüpheli işlemlerin tespiti için önemli bir araçtır. Para aklama şüphesinin olması halinde, Skybitpro tüm işlemleri izleme (yüksek risk grubundaki müşterilerin işlemleri, kompleks ve olağandışı işlemler, yüksek riskli ülkelerle yapılan işlemler, müşterilere ait bilgileri ve belgeler, kripto para alım satım ve transferine ilişkin tutulması gereken yazılı ve zorunlu bilgiler, müşteri tarafından gerçekleştirilen bir işlemin, söz konusu işlemle ilgili bilgilere uygun olup olmadığı vb.) ve aşağıdakileri yapma hakkına sahiptir:": "Monitoring customers' transactions and analyzing the obtained data is also an important tool for risk assessment and detection of suspicious transactions. In case of suspicion of money laundering, Skybitpro monitors all transactions (transactions of high-risk customers, complex and unusual transactions, transactions with high-risk countries, customer information and documents, written and mandatory information to be kept regarding crypto currency trading and transfer, customer whether a transaction performed is in accordance with the information about the transaction in question, etc.) and has the right to:",
          "Şüpheli işlemlerin ilgili kolluk birimlerine bildirilmesi,": "Report suspicious transactions to the relevant law enforcement units,",
          "Müşteriden ek bilgi ve belgeler sunmasını talep edilmesi,": "Request the customer to submit additional information and documents,",
          "Müşteri hesabının askıya alınması veya kapatılması.": "Suspend or close down customer accounts.",
          "Yukarıdaki liste tam kapsamlı bir liste olmayıp AML Politika Uyum Sorumlusu, müşterilerin işlemlerini günlük olarak izleyerek müşterileri raporlar ve şüpheli olarak değerlendirip değerlendirmeyeceğini belirler.": "The above list is not exhaustive, and the AML Policy Compliance Officer monitors customers' transactions on a daily basis, reports them and determines whether to consider them suspicious.",
          "Müşterini Tanı Politikası": "Know-Your-Customer Policy",
          "Müşterini Tanı (Know-Your-Customer) Politikası’na www.skybitpro.com üzerinden erişim sağlanabilir.": "Know-Your-Customer Policy is accessible via www.skybitpro.com.",
          "Doğrulama Prosedürü": "Verification Procedure",
          "Skybitpro, para aklamayla mücadele standartlarını ve Müşterini Tanı Politikası’na (KYC) uyumu belirlemek için kendi prosedürlerini oluşturacaktır.": "Skybitpro establishes its own procedures to determine anti-money laundering standards and compliance with the Know-Your-Customer Policy (KYC).",
          "Skybitpro müşterileri, bir doğrulama prosedürünü tamamlar. Skybitpro, AML politikasının amaçları için müşterilerinin kimlik bilgilerini toplama hakkını saklı tutar. Bu bilgiler, Skybitpro Gizlilik Politikası uyarınca işlenir ve güvenli bir şekilde saklanır.": "Skybitpro customers complete a verification procedure. Skybitpro reserves the right to collect the identification of its customers for the purposes of the AML policy. This information is processed and securely stored in accordance with the Skybitpro Privacy Policy.",
          "Skybitpro ikinci bir müşteri kimlik belgesi talep edebilir. (Müşterinin tam adını ve gerçek adresini içeren elektrik/su faturası, 3 ay içinde yapılmış bir işleme ait banka dekontu gibi)": "Skybitpro may request a second customer identification document. (Electricity/water bill containing the customer's full name and real address, bank receipt of a transaction made within 3 months)",
          "Skybitpro, müşterilerin ibraz ettiği belgelerin ve bilgilerin doğruluğunu teyit ettikten sonra tehlikeli veya şüpheli olarak tanımlanan müşteriler hakkında ek bilgi isteme hakkını saklı tutar.": "Skybitpro reserves the right to request additional information about customers identified as dangerous or suspicious after confirming the accuracy of documents and information submitted by customers.",
          "Müşterinin kimlik bilgileri değiştirilmişse veya faaliyetleri şüpheli bulunmuşsa, Skybitpro geçmişte kimliği doğrulanmış olsa dahi müşteriden güncellenmiş belgeler talep etme hakkına sahiptir.": "If the identity of the client has been changed or his/her activities have been found suspicious, Skybitpro has the right to request updated documents from the client, even if his/her identity has been verified in the past.",
          "Raporlama": "Reporting",
          "Skybitpro’nun sağlamış olduğu hizmetler çerçevesinde, kara para aklama ve terörizmin finansmanına dair şüphe uyandıran hallerde, gerekli incelemeler sonucunda şüpheli işlem olarak belirlenen işlemler tutarına bakılmaksızın yürürlükteki mevzuat uyarınca yetkili makamlara bildirilir. Şüpheli işlemi gerçekleştiren gerçek kişiler ve bunların yasal temsilcileri, şüpheli işlemi bildirme yükümlülüğüne uymayan yönetici ve personeller her türlü hukuki, idari ve cezai yaptırımdan sorumlu olacaktır.": "Within the framework of the services provided by Skybitpro, in cases where there are suspicions about money laundering and terrorist financing, the transactions determined as suspicious transactions as a result of the necessary investigations are reported to the competent authorities in accordance with the applicable legislation, regardless of the amount. The real persons who carried out the suspicious transaction and their legal representatives, the managers and personnel who do not comply with the obligation to report the suspicious transaction will be responsible for all kinds of legal, administrative and criminal sanctions.",
          "Kara Parayı Aklamayı Önleme Sorumlusu": "Anti-Money Laundering Officer",
          "Kara Para Aklamayı Önleme Sorumlusu, AML Politikası’na uymayı sağlamakla görevli bir Skybitpro çalışanıdır.": "The Anti-Money Laundering Officer is a Skybitpro employee charged with ensuring compliance with the AML Policy.",
          "Kara Para Aklamayı Önleme Sorumlusu;": "Anti-Money Laundering Officer is responsible for:",
          "Müşterilerin kimlik bilgilerinin toplanması,": "Collecting the identification of customers,",
          "Mevcut yasa ve yönetmeliklere uygun olarak gerekli tüm raporları oluşturmak, gözden geçirmek, arz etmek ve saklamaya yönelik iç politika ve prosedürleri oluşturmak ve güncellemek,": "Creating and updating internal policies and procedures for creating, reviewing, supplying and keeping all necessary reports in accordance with current laws and regulations,",
          "Müşterilerin sıra dışı faaliyetlerinden kaynaklanan önemli sapmaların izlenmek ve analiz etmek,": "Monitoring and analyzing significant deviations arising from unusual activities of customers,",
          "Belge, dosya, form ve günlük oturum giriş çıkışlarını kaydetme ve alma için bir kayıt yönetim sistemini uygulamak,": "Implementing a records management system for recording and retrieving documents, files, forms and daily log entries and exits,",
          "Risk değerlendirmelerini düzenli olarak güncellemek konularında görevlendirilmiştir.": "Regularly updating risk assessments.",
          "Eğitim, Güncelleme ve İç Denetim": "Training, Update and Internal Audit",
          "Skybitpro, yürürlükteki mevzuata uygun surette çıkardığı personel politikası ve prosedürler ile eğitim kapsamındaki tüm yükümlülüklerini yerine getirir. Bu kapsamda Kara Para Aklamanın Önlenmesi Prosedürü başta olmak üzere personeline birçok eğitim verir ve işbu bilgilerin güncel tutulmasını sağlar.": "Skybitpro fulfills all its obligations under the personnel policy and procedures and training it has issued in accordance with the current legislation. In this context, it provides various trainings to its personnel, especially the Anti-Money Laundering Procedure, and ensures that this information is kept up-to-date.",
          "Skybitpro “Kara Para Aklama Ve Terörizm Finansmanına Karşı Kanun”, yönetmelik ve tebliğlerle ilgili faaliyetlerinin yürürlükteki mevzuat, Şirket politikaları ve usullerine uygun olup olmadığı konusunda periyodik olarak denetim yapar.": "Skybitpro periodically audits whether its activities related to the “Law Against Money Laundering and Terrorism Financing”, regulations and communiqués comply with the applicable legislation, Company policies and procedures.",

          "ALIŞ-SATIŞ": "BUY-SELL",
          "Komisyonlar": "Commissions",
          "Skybitpro'da yapacağınız işlemlerde aşağıda belirtilen komisyon oranları uygulanır.": "The following commission rates are applied to your transactions on Skybitpro.",
          "HACİM / 30 GÜN": "VOLUME/ 30 DAYS",
          "Piyasa Yapıcı": "Market Maker",
          "Piyasa Alıcı": "Market Taker",
          "YATIRMA-ÇEKME":"DEPOSIT-WITHDRAW",
          "Ücretler": "Fees",
          "Skybitpro'da yapacağınız işlemlerde aşağıda belirtilen ücretler uygulanır.": "The following fees are applied to your transactions on Skybitpro.",
          "YÖNTEM": "METHOD",
          "YATIRMA": "DEPOSIT",
          "ÇEKME": "WITHDRAWAL",
          "Ücretsiz": "Free",
          "Okuyunuz": "Read",
          "BTC, XRP, XLM kripto para birimlerinde hiçbir komisyon alınmamaktadır. ETH çekim işlemlerinden 0.005 ETH, AVAX çekim işlemlerinden 0,01 AVAX, LTC çekim işlemlerinden 0,001 LTC, ALGO çekim işlemlerinden 0,01 ALGO, DOT için 0,1 DOT, USDT için 20 USDT, LINK için 0,6 LINK, FTM için 15 FTM, HOT için 3000 HOT, SHIB için 750.000 SHIB, SAND için 8 SAND, MANA için 3 MANA blok zinciri ücreti (fee) alınmaktadır. Alınan fee, yatırımcının gönderdiği tutardan düşmektedir.": "No commission is charged on BTC, XRP, XLM cryptocurrencies. 0.005 ETH for ETH withdrawals, 0.01 AVAX for AVAX withdrawals, 0.001 LTC for LTC withdrawals, 0.01 ALGO for ALGO withdrawals, 0.1 DOT for DOT, 20 USDT for USDT, 0.6 LINK for LINK, 15 for FTM FTM charges 3000 HOT for HOT, 750,000 SHIB for SHIB, 8 SAND for SAND, 3 MANA blockchain fee for MANA. The fee received is deducted from the amount sent by the investor.",

          "Cebinde, Her Anında Seninle!": "By your side, in your pocket, all the time!",
          "MOBİL UYGULAMA": "MOBILE APP",
          "Kullanıcı dostu, pratik ve son teknoloji tasarımla hem profesyoneller hem de yeni başlayanlar için.": "For both professionals and beginners with user-friendly, practical and cutting-edge design.",
          "Şimdi Skybitpro mobil uygulaması ile dilediğin an kripto dünyasına tek tıkla bağlan. Bitcoin ve diğer kripto paraları kolayca al, sat.": "Skybitpro mobile app allows you to connect to the crypto world with one click whenever you want. Buy and sell bitcoin and other cryptocurrencies, safe and easy.",
          "Kullanıcı dostu platformumuz ve arayüzlerimiz, üyelerimizin tüm işlemlerini kolay ve hızlı bir şekilde yapabilmesi için tasarlanacak. Ayrıca gelişmiş mobil uygulamamız sayesinde işlemlerinizi dilediğiniz yerden takip edebilirsiniz.": "Our user-friendly platform and interfaces will be designed so that our members can perform all their transactions easily and quickly. You can follow your transactions anywhere with our advanced mobile application.",
          "FİNANSAL": "FINANCIAL",

          "Skybit Pro’nun sunduğu üst düzey hizmet anlayışı sayesinde, Forex piyasasının dinamiklerini ayrıcalıklı işlem gerçekleştirme avantajlarıyla deneyimleyeceksiniz!": "Thanks to the high-level service concept offered by Skybit Pro, you will experience the dynamics of the Forex market with the advantages of performing privileged transactions!",
          "iPhone 14 Pro Max Kampanyası": "iPhone 14 Pro Max Campaign",
          "Yeni Yıl Kampanyası": "New Year Campaign",
          "Skybit Pro Hesabını Aç iPhone 14 Pro Max'i Kap!": "Create a Skybit Pro Account and Win an iPhone 14 Pro Max!",
          "SkybitPro'ya Yatırımını Yap Hediyeleri Kap!": "Trading to SkybitPro and Win Gifts!",
          "Kampanyadan sadece kampanya başlangıç tarihi itibariyle hesap açılışı yapan ve kimliğini doğrulayan yatırımcılar faydalanabilir.": "Only investors who have opened an account and verified their identity as of the campaign start date can benefit from the campaign.",
          "Kampanya, 1 Aralık 2022 saat 09:30 ile 1 Ocak 2024 saat tarihleri arasındaki işlemleri kapsayacak şekilde geçerli olacaktır.":"The campaign will be valid for transactions between 1 December 2022 at 09:30 and 1 January 2024.",
          "Kampanya dahilinde açılan hesaplarda gerçekleşen işlem hacimleri, üç aylık dönem sonunda kontrol edilecek olup, son 3 (üç) ay  boyunca 150.000 Dolar dolar olan yatırımcılarımız kazanacaktır.":"The transaction volumes realized in the accounts opened within the scope of the campaign will be checked at the end of the quarterly period, and our investors will earn $150,000 for the last 3 (three) months.",
          "Kampanya şartlarını karşılayan yatırımcılar iPhone 14 Pro Max – 256 GB kazanmaya hak kazanacaklardır.": "Investors who meet the campaign conditions will be entitled to win iPhone 14 Pro Max – 256 GB.",
          "iPhone 14 Pro Max – 256 GB kazanan yatırımcılara aygıt kampanya bitimini takip eden 30 iş günü içinde teslim edilecektir.": "The device will be delivered to investors who win iPhone 14 Pro Max – 256 GB within 30 working days following the end of the campaign.",
          "Yatırımcıların SKYBIT PRO kampanya kapsamındaki hesabından şartlı virman işlemi gerçekleştirilmesi halinde yatırımcılar kampanya haklarını kaybedeceklerdir.": "If a conditional transfer transaction is made from the account of the investors within the scope of the SKYBIT PRO campaign, the investors will lose their campaign rights.",
          "Her yatırımcı kendi adına açılmış hesap ile kampanyadan sadece bir defa faydalanabilir.":"Each investor can benefit from the campaign only once with the accounts opened in their own name.",
          "iPhone 14 Pro Max Kampanyası başka hiçbir kampanya ile birleştirilemez.": "iPhone 14 Pro Max Campaign cannot be combined with any other campaign.",
          "SKYBIT PRO kampanya koşullarını değiştirme ve kampanyayı sonlandırma hakkını kazanılmış haklar saklı kalmak koşuluyla ve önceden bilgi verilmek suretiyle saklı tutar.": "SKYBIT PRO reserves the right to change the campaign conditions and to terminate the campaign, provided that the acquired rights are reserved and by giving prior notice.",
          "Kampanyaya 18 yaşından küçükler katılamaz.": "Persons under the age of 18 cannot participate in the campaign.",

          "Skybit Pro’nun Büyük Yeni Yıl Yarışması Başladı": "Skybit Pro's Big New Year Contest Has Started",
          "20 Aralık Salı ve 31 Aralık Cumartesi Gününe kadar yapacağınız her 500 USD üzeri 1 Çekiliş Hakkı demektir":"Every 500 USD or more you make until Tuesday, December 20 and Saturday, December 31, means 1 Draw",
          "Kampanyadan sadece belirtilen tarihler arasında yapılacak yatırımlar için geçerlidir.":"The campaign is only valid for investments to be made between the specified dates.",
          "Kampanya, 20 Aralık 2022 saat 09:30 ile 31 Aralık 2022 24:00 saat tarihleri arasındaki işlemleri kapsayacak şekilde geçerli olacaktır.":"The campaign will be valid for transactions between 20 December 2022 at 09:30 and 31 December 2022 at 24:00.",
          "Minimum 500 USD tutarındaki her yatırım 1 çekiliş hakkı sayılacaktır. Bu tarihler arasında yapılacak 500 USD ve katları şeklinde ki yatırımlar ekstra çekiliş hakkı sayılacaktır.":"Each investment with a minimum amount of 500 USD will be counted as 1 drawing right. Investments in the form of 500 USD and its multiples to be made between these dates will be considered as an extra drawing right.",
          "Kampanya şartlarını karşılayan yatırımcılar 10 adet iPhone 14 Pro Max – 256 GB, 20 adet Apple Watch Ultra, 5 Adet Apple Ipad kazanmaya hak kazanacaklardır.":"Investors who meet the conditions of the campaign will be entitled to win 10 iPhone 14 Pro Max – 256 GB, 20 Apple Watch Ultra, 5 Apple Ipads.",

          "Kartın talep edilebilmesi için  Skybit Pro’da gerçek hesap açılmış olmak gerekmektedir.":"A real account must be opened on Sky bit Pro to claim the card.",
          "Kartın talep edilebilmesi için  minimum yatırım tutarı 5000$ olmalıdır.":"The minimum investment amount for the card to be requested should be $ 5000.",
          "Kart talep edilmesi sonrası en geç 1 ay içerisinde yatırımcının adresine gönderimi sağlanacaktır.":"After the card is requested, it will be sent to the investor's address no later than 1 month.",
          "Kart talebinde bulunabilmek için Skybit Pro web sitesi iletişim kısmından, whatsapp numaralarımızdan veya canlı destekten müşteri temsilcileriyle iletişime geçebilirsiniz.":"To request a card, you can contact customer representatives from the Sky bit Pro website contact section, our whatsapp numbers or live support.",
          "Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilirse hiç bir mazeret belirtmeden Skybit Pro tarafından kart iptal edilir.":"If the investor is found to be engaged in illegal activities or committing irregularities, the card will be canceled by Skybit Pro without specifying any excuse.",

          "Sky Kart Kampanyası":"Sky Card Campaign",
          "":"",
          "":"",
          "":"",
          "":"",

          "Opps! Sayfa Bulunamadı": "Opps! Page Not Found",
          "Silinmiş veya hiç var olmamış bir sayfaya erişmeye çalışıyorsunuz.": "You are trying to access a deleted or nonexistent page.",
          "ANASAYFAYA DÖN" : "Go BACK HOME"
          
        }
      },
      ita: {
        translation: {
          "Mobil Uygulama": "Applicazione mobile",
          "Piyasa": "Mercato",
          "Yatırma": "Depositare",
          "Çekme": "Prelevare Denaro",
          "Bize Ulaşın": "Contattaci",

          "Skybitpro, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. Skybitpro ile dijital para borsalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "",

          "Skybitpro'yu KEŞFEDİN": "SCOPRI Skybitpro",
          "Kaldıraçlı kripto para alım-satım işlemleriniz için Skybitpro yanınızda. Güvenilir bir şekilde yatırım yapmak, kazanç sağlamak için güçlü altyapımızı kullanmak, 7/24 hizmet anlayışımız sayesinde web sitemiz üzerinden dilediğiniz zaman kripto para alıp satmak için hemen üye olabilirsiniz.": "Usa il servizio di Skybitpro per fare trading su criptovalute con leva. Ora puoi diventare un membro per investire in modo affidabile, per guadagnare profitti con la nostra forte infrastruttura e anche per acquistare e vendere criptovalute sul nostro sito web in qualsiasi momento con il nostro servizio 24/7.",
          "ÜYE OLUN": "REGISTRATI",
          "GİRİŞ YAPIN": "ACCEDI",
          "ÜYE OL": "REGISTRATI",
          "KAYIT OL": "REGISTRATI",
          "GİRİŞ YAP": "ACCEDI",

          "Hakkımızda": "Chi Siamo",
          "KURUMSAL": "AZIENDALE",
          "Türkiye’nin en güvenli, en hızlı kripto para alım-satım platformu ile tanışın.": "Incontra la piattaforma di trading di criptovalute più sicura e veloce della Turchia.",
          "2019’dan bu yana faaliyet gösteren Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda yatırım yapmak isteyenlerin işini kolaylaştıran çözümler sunar. Skybitpro platformunun; hızlı altyapısı, kullanıcı dostu arayüzü ve 7/24 ulaşılabilir olması sayesinde kullanıcılar, alım-satım işlerini en hızlı şekilde ve kolayca gerçekleştirir.": "Operativo dal 2019, Skybitpro offre soluzioni che lo rendono più facile per chi vuole investire nel trading di criptovalute con leva. Gli utenti possono eseguire le loro transazioni nel modo più semplice e veloce attraverso l'infrastruttura veloce, l'interfaccia intuitiva e disponibilità 24/7 della piattaforma Skybitpro.",
          "Müşteri odaklı bir yatırım platformu olan Skybitpro, kullanıcılarının beklentilerini en iyi şekilde karşılamayı hedefler. Uzman kadrosunun da desteği ile müşteri memnuniyetini üst düzeyde tutmak için çalışır. Faaliyet gösterdiği ilk günden bu yana güvenilirliğini hiç kaybetmez, güvenlik konusunda kullanıcılarından da tam not alır.": "Come una piattaforma di investimento orientata ai clienti, Skybitpro mira a soddisfare le aspettative dei suoi utenti nel modo migliore. Lavora per massimizzare la soddisfazione del cliente con il supporto del suo personale esperto. Da quando ha cominciato la sua attività, come confermano feedback diretti dagli utenti, ha sempre dimostrato la sua affidabilità.",
          "Başarısını güçlü sermayesi ile destekleyen Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda sektörün lider markaları arasında yer almaktadır. Kullanıcılarının yatırım işlemlerinde daha çok kazanç sağlaması için teknik altyapı ve imkanları gün geçtikçe artırmayı hedefleyen markamız sayesinde siz de geleceğinize yatırım yapmaya hemen başlayabilirsiniz. Güvenli, hızlı, kesintisiz bir şekilde yatırım yapmak için hemen Skybitpro’ya üye olabilirsiniz.": "Come uno dei marchi leader del settore nel trading di criptovalute con leva, Skybitpro supporta il suo successo con il suo forte capitale. Puoi iniziare a investire nel tuo futuro con il nostro marchio che mira ad aumentare l'infrastruttura tecnica e le opportunità giorno dopo giorno affinché i suoi utenti possano guadagnare di più nelle loro transazioni di investimento. Quindi, puoi diventare un membro di Skybitpro per un investimento sicuro, veloce e lordo.",

          "Neden Skybitpro?": "Perché Skybitpro?",

          "Kaldıraçlı kripto para alım ve satım işlemler yapmaya yeni başladıysanız ya da daha fazla tecrübe edinmek istiyorsanız finansal eğitimlerimize katılabilirsiniz. Uzman eğitmenler tarafından, online olarak gerçekleştirilecek eğitimlere katılarak kripto para ve yatırım dünyası hakkında daha fazla bilgi sahibi olabilirsiniz.": "",
          "Online finansal eğitimlerimiz hakkında daha detaylı bilgi sahibi olmak için bizimle hemen iletişime geçebilirsiniz.": "",

          "Gizlilik Politikası": "Informativa Sulla Privacy",
          "GİZLİLİK": "PRIVACY",
          "Skybitpro Yazılım (“Şirketimiz” veya “Skybitpro”) olarak, web servis ve dijital kaynaklarımızın herhangi birini kullanan müşterilerimizin veya üçüncü kişilerin (“Kullanıcılar”) kişisel verilerinin korunması, bu hususlarda şeffaflık ve karşılıklı güven oluşturmak önceliklerimizdendir. Web adresimiz www.skybitpro.com veya uzantıları ve bunlarla birlikte ama bunlarla sınırlı olmamak üzere sosyal medya sayfalarımızı ve/veya dijital kanallarımızı ziyaret etmeniz, hizmet ve ürünlerimizi kullandığınız veya destek hizmetlerimizden yararlandığınız durumlar gibi Skybitpro’yu online ve dijital kanallarımız üzerinden ziyaret ettiğiniz anlarda otomatik olan veya olmayan yöntemlerle kaydettiğimiz ve elektronik olarak bizimle paylaştığınız kişisel verileriniz, öncelikle taleplerinizin yerine getirilmesini temin edebilmek, daha sonra ise size daha iyi hizmet sunmak amacıyla kullanılacaktır.": 'In qualità di Skybitpro Software ("La nostra azienda" o "Skybitpro"), è nostra priorità proteggere i dati personali dei nostri clienti o di terzi ("Utenti") che utilizzano i nostri servizi web e risorse digitali e stabilire trasparenza e fiducia reciproca in queste cose. La tua visita al nostro sito web www.skybitpro.com o alle sue estensioni, incluse, ma non limitate a, le nostre pagine di social media e/o canali digitali, quando visiti Skybitpro attraverso i nostri canali online e digitali ad esempio quando utilizzi i nostri servizi e prodotti o usufruisci dei nostri servizi di support, i tuoi dati personali che condividi con noi elettronicamente e che salviamo con metodi automatizzati o non automatici, prima di tutto, sarà utilizzato per garantire che le tue richieste vengano soddisfatte e poi per fornirti un servizio migliore.',
          "Kişiler verilerinizin Skybitpro tarafından işlenmesi ile ilgili daha detaylı bilgiye Kişisel Verilerinin Korunması’ndan ve Çerez Politikası’ndan ulaşabilirsiniz.": "Puoi trovare informazioni più dettagliate sul trattamento dei tuoi dati personali da parte di Skybitpro nella Protezione Dei Dati Personali e Politica Dei Cookie.",
          "Onaylı veri tabanımızda yer almanız halinde ise, Şirketimiz tarafından sunulan ürün ve hizmetlerle ilgili olarak tanıtım, pazarlama ve promosyon faaliyetleri yapılması için ileti gönderilebilecektir. Şirketimiz tarafından gönderilen iletileri almak istemiyorsanız, size sunulan reddetme hakkını kullanarak örneğin; “Skybitpro’dan yeni ürün ve hizmet tanıtımına ilişkin herhangi bir e-posta almak istemiyorsanız lütfen tıklayınız.” bağlantısına tıklayarak, ilgili kanala ait listemizden çıkabilirsiniz.": 'Se sei incluso nel nostro database approvato, potrebbero essere inviati messaggi per attività pubblicitarie, di marketing e promozionali riguardanti i prodotti e servizi offerti dalla nostra Azienda. Se non desideri ricevere messaggi inviati dalla nostra Azienda, puoi cancellarti dalla nostra lista del canale pertinente utilizzando il diritto di rifiuto (ad esempio, cliccando sul seguente link "Se non desideri ricevere alcuna e-mail da Skybitpro relativa alla promozione di nuovi prodotti e servizi, fai clic su").',

          "Sorumluluğun Sınırlandırılması": "Limitazione Di Responsabilità",
          "Bu internet sitesinde verilen bilgiler, “olduğu gibi”, “mevcut olduğu şekilde” sağlanmaktadırlar. Skybitpro, bu bilgilerin doğruluklarını, yeterliliklerini ve eksiksizliklerini garanti etmemekte ve bu bilgilerdeki hatalar ya da eksiklikler nedeniyle sorumluluğu, açık bir şekilde reddetmektedir. Bu bilgilerle üçüncü şahısların haklarının ihlal edilmemesi; mülkiyet, belirli bir amaç için uygunluk ve/veya bilgisayar virüsü bulunmamasına ilişkin garantiler dahil ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti vermemektedir.": "Le informazioni pubblicate su questo sito web sono fornite \"così come sono\", \"come disponibili\". Skybitpro non garantisce l'accuratezza, l'adeguatezza o la completezza di queste informazioni e declina espressamente la responsabilità per errori o omissioni in queste informazioni. Non fornisce alcuna garanzia, implicita, esplicita o legale, incluse ma non limitate a garanzie di non violazione dei diritti di terze parti con queste informazioni, proprietà, idoneità per uno scopo particolare e/o assenza di virus.",
          "Bu internet sitesinin kullanımı sırasında herhangi bir performans arızası, hata, eksiklik, kesinti kusur, işletme ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı zarar, ziyan ve masraflar da dahil ancak bunlarla sınırlı olmamak üzere hiçbir zarar ve ziyandan, Skybitpro ve/veya çalışanları, bu tür bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar.": "Skybitpro e/o i suoi dipendenti non saranno responsabili per eventuali perdite o danni, inclusi ma non limitati a danni diretti o indiretti, perdite e spese derivanti da qualsiasi interruzione delle prestazioni, errore, carenza, interruzione, difetto, ritardo nel funzionamento e/o trasferimento, virus informatico e/o guasto della linea e/o del sistema durante l'uso di questo sito web, anche se Skybitpro e/o i suoi dipendenti sono stati informati della possibilità di tali danni e perdite.",
          "Gerek bu internet sitesinden bağlantı verilen sitelerdeki, gerekse de diğer internet sitelerine bağlantıların riski, kullanıcıya aittir. Bu sitelerde verilen bilgilerin doğruluğu ile bu sitelerden verilen diğer bağlantıların nitelikleri Skybitpro tarafından araştırılmamış ve doğrulanmamıştır.": "Il rischio sia dei siti collegati da questo sito web e dei collegamenti ad altri siti web è dell'utente. L'accuratezza delle informazioni fornite su questi siti e la qualità di altri collegamenti forniti da questi siti non sono state esaminate e verificate da Skybitpro.",
          "Skybitpro, bu internet sitesinde yer alan bütün ürün ve hizmetleri, sayfaları, bilgileri, görsel unsurları önceden bildirimde bulunmadan değiştirme hakkını saklı tutar.": "Skybitpro si riserva il diritto di modificare tutti i prodotti e servizi, pagine, informazioni ed elementi visivi su questo sito web senza preavviso.",

          "ŞARTLAR VE KOŞULLAR": "TERMINI E CONDIZIONI",
          "ŞARTLAR": "TERMINI",
          "Taraflar": "Parti",
          "İşbu sözleşme ve sözleşmenin ayrılmaz birer parçası olan eklerden oluşan Skybitpro Kullanım Sözleşmesi (bundan böyle kısaca “Kullanım Sözleşmesi” olarak anılacaktır), “Skybitpro Yazılım Anonim Şirketi” ile Site’ye üye olan “Kullanıcı” arasında, Kullanıcı’nın Site’ye elektronik ortamda üye olması anında, Site’de sunulan hizmetleri kullanırken karşılıklı hak ve yükümlülükleri belirtmek amacı ile düzenlenmiştir.": "Questo contratto e Contratto di Utilizzo di Skybitpro composta da allegati che sono parte inscindibile del contratto (di seguito denominato “Contratto di Utilizzo”), tra “Skybitpro Software Società per Azioni” e “Utente” che è membro del Sito, quando l'Utente diventa un membro del Sito elettronicamente, durante l'utilizzo dei servizi presentati sul sito è rilasciato allo scopo di specificare i diritti e gli obblighi reciproci.",
          "Tanımlar": "Definizioni",
          "Skybitpro Yazılım Anonim Şirketi.": "Skybitpro Software Società per Azioni.",
          "Kripto Para": "Criptovaluta",
          "Kriptografik/şifreli olarak güvenli işlem yapmaya ve ek sanal para arzına olanak sağlayan dijital değerlere, kripto-para denir. Kripto-paralar, alternatif para birimidirler, dijitaldirler ve aynı zamanda sanal paradırlar.": "I valori digitali che consentono transazioni sicure crittograficamente/criptati ed ulteriore offerta di denaro virtuale sono chiamati criptovaluta. Le criptovalute sono valute alternative, sono digitali e sono anche denaro virtuale.",
          "Kripto-paralar, merkezi elektronik paraların ve bankacılık sistemlerindekinin aksine, merkezi olmayan yapıdadırlar. Merkezi olmayan bu yapının kontrolü Blok-Zincir (BlockChain) işlem veri tabanları tarafından gerçekleştirilir (*).": "A differenza dei sistemi bancari e monetari elettronici centralizzati, le criptovalute sono decentralizzate. Questa struttura decentralizzata è controllata dai database delle transazioni BlockChain (*).",
          "Bitcoin Cüzdanı": "Portafoglio Bitcoin",
          "Bitcoin bir kripto para’dır. Bitcoin adreslerinizi ve onların şifrelerini tutan bir bilgisayar dosyasıdır. Bitcoin adresler arasında transfer edilir. Bitcoin adresleri rastgele oluşturulan kriptografik açık anahtar eşleridir. Açık adresler yaklaşık 33 harf ve sayı kullanılarak oluşturulan “1” ya da “3” ile başlayan dizilerdir. Bitcoin adresi genelde bu örnekteki şekildedir: 1KUsvypQkcs91Ffdkjkk8ddW7RDWKF75Yc.": 'Bitcoin è una criptovaluta. Bitcoin è un file di computer che salva i tuoi indirizzi e le loro password. Bitcoin viene trasferito tra indirizzi. Gli indirizzi Bitcoin sono coppie di chiavi pubbliche crittografiche che vengono creati casualmente. Gli indirizzi pubblici sono stringhe che iniziano con "1" o "3" ottenute utilizzando circa 33 lettere e numeri. L\'indirizzo Bitcoin è generalmente come nell\'esempio: 1KUsvypQkcs91Ffdkjkk8ddW7RDWKF75Yc.',
          "Kullanıcılar": "Utente",
          "Site’ye üye olan ve Site’de sunulan Hizmet’lerden yararlanan gerçek kişi.": "La persona fisica che è un membro del Sito e beneficia dei Servizi presentati sul Sito.",
          "Site": "Sito",
          "www.skybitpro.com alan adından ve bu alan adına bağlı alt alan adlarından oluşan internet sitesi.": "Sito web è costituito dal nome di dominio www.skybitpro.com e dai sottodomini collegati a questo dominio.",
          "Hak ve Yükümlülükler": "Diritti e Obblighi",
          "Skybitpro hizmetine bağlı tüm servislerin, alan adlarının, yazılım kodlarının, ara yüzlerinin, içeriklerinin, ürün incelemelerinin, videolarının, algoritmalarının, çizimlerinin, modellerinin, tasarımlarının ve diğer tüm fikri sınai hakların sahibi, (üçüncü partilerden sağlanan içerik ve uygulamalar hariç olmak üzere) Skybitpro Yazılım Anonim Şirketi’dir. Sunulan servislerin yazılım, tasarım ve telif hakları tamamen Skybitpro’ya aittir. Servislerin ve hizmetlerin, bununla bağlantılı sayfaların kopyalanmasına, çoğaltılmasına ve yayılmasına, ayrıca tersine mühendislik işlemlerine tabi tutulmasına hiçbir şekilde izin verilmemektedir.": "Skybitpro Software Società per Azioni è il titolare dei diritti di tutti i servizi, nomi di dominio, codici software, interfacce, contenuti, recensioni di prodotti, video, algoritmi, disegni, modelli, design e tutti gli altri diritti di proprietà intellettuale connessi al servizio Skybitpro (esclusi contenuti e applicazioni forniti da terze parti). Skybitpro è il titolare dei diritti di software, design e copyright dei servizi presentati. La copia, riproduzione e diffusione dei servizi e delle pagine ad essi collegate, nonché l'ingegneria inversa non sono consentiti in alcun modo.",
          "Kullanıcı; hesabını ve haklarını üçüncü şahıslara devredemez, satamaz, kendi üyeliğini her ne nam ve ad altında olursa olsun başkasına kullandıramaz. Bu durumda Skybitpro kullanıcının hesabını iptal etme, durdurma, askıya alma hakkına sahiptir. Kullanıcının kripto para varlıkları bu durumdan etkilenmez.": "L'utente non può trasferire il proprio account e diritti a terzi, non può venderlo, non può consentire a qualcuno di utilizzare la propria iscrizione con qualsiasi nome o marchio. In questo caso, Skybitpro è il titolare dei diritti di cancellazione, interruzione, sospensione dell'account dell'utente. Le risorse crittografiche dell'utente non sono interessate per questo motivo.",
          "Site’ye üye olurken verilen bilgilerin doğruluğundan ve gizliliğinden, ayrıca şifre ve kullanıcı adının korunmasından, başka kimselerle paylaşılmamasından kullanıcının kendisi sorumludur. Bu bilgilerin yetkisiz kişiler tarafından ele geçirilmesi ve Skybitpro servislerinin kullanılması halinde Skybitpro hiçbir sorumluluk kabul etmemektedir.": "L'utente è responsabile dell'accuratezza e della riservatezza delle informazioni fornite durante la registrazione al Sito e anche responsabile della protezione della password e del nome utente e di non condividerli con altri. Skybitpro declina ogni responsabilità quando queste informazioni ottenute da persone non autorizzate e vengono utilizzati i servizi Skybitpro.",
          "Site’nin, kullanıcının hatasından doğan veya doğacak, her türlü zarar ve/veya kayıplardan dolayı Skybitpro yönetim kurulu üyeleri, yöneticileri, çalışanları ve bu sitede yer alan bilgileri hazırlayan kişilerin hiçbir hukuki ve cezai sorumluluğu bulunmamaktadır.": "A causa di tutti i tipi di danni e/o perdite del sito derivanti da o in connessione con l'errore dell'utente, i membri del consiglio di amministrazione di Skybitpro, i dirigenti, i dipendenti e le persone che preparano i dati su questo sito sono immuni da qualsiasi responsabilità penale e civile.",
          "Kullanıcılar yalnızca hukuka uygun amaçlarla Site üzerinde işlem yapabilirler. Kullanıcıların hukuka aykırı işlem yapmalarının fark edilmesi halinde, Skybitpro hiçbir şekilde sorumlu olmadığı gibi, Skybitpro, her türlü hukuki yola başvurma ve gerekli güvenlik tedbirlerini alma, hakkına sahiptir.": "Gli utenti possono effettuare transazioni sul Sito solo per scopi legali. Skybitpro non è mai responsabile quando è stato notato che gli utenti effettuano transazioni illegali e Skybitpro ha il diritto di intraprendere qualsiasi azione legale e prendere le necessarie precauzioni di sicurezza.",
          "Kullanıcıların Site üzerinde aldığı hizmetleri kullanarak yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Skybitpro’nun hiçbir hukuki ya da idari sorumluluğu bulunmamaktadır.": "Gli utenti hanno la responsabilità penale e civile per ogni transazione e azione durante l'utilizzo dei servizi presentati sul sito. Skybitpro rifiuta qualsiasi responsabilità legale o amministrativa.",
          "Kullanıcılar sitede hesap sahibi olmak için bir üye formu doldururlar. İşlem yapmak isteyen kullanıcıların kimlik ve adres bilgilerini belgelemek zorundadırlar. Aksi takdirde, Skybitpro’ün işlem yapmamak ya da hesabı durdurma hakkı tek taraflı olarak saklıdır.": "Gli utenti compilano un modulo di adesione per avere un account sul sito. Gli utenti che desiderano effettuare transazioni devono autenticare la propria identità e le informazioni sull'indirizzo. Altrimenti, Skybitpro si riserva unilateralmente il diritto di non intraprendere alcuna azione o di sospendere l'account.",
          "Skybitpro, işlemlerin şüpheli görülmesi halinde, kullanıcıların yaptıkları para transferlerini iade etme hakkına sahiptir.": "Skybitpro si riserva il diritto di rimborsare i trasferimenti di denaro effettuati dagli utenti quando è stato notato che le transazioni sono sospette.",
          "Para yatırma işlemlerinin, Kullanıcı ile aynı ismin altına kayıtlı olan banka hesabından yapılması zorunludur.": "Operazioni di depositi devono essere effettuati dal conto bancario registrato con lo stesso nome dell'Utente.",
          "ATM’ler üzerinden kartsız bankacılık yöntemleri ile transfer yapılmamalıdır. Bu tip işlemlerin saptanması durumunda, Skybitpro’nun söz konusu transferleri iade etme hakkına sahiptir.": "I trasferimenti non devono essere effettuati tramite i bancomat con metodi bancari senza carta. Skybitpro si riserva il diritto di rimborsare i relativi trasferimenti in caso di questo genere di operazioni.",
          "Para yatırma ve çekme işlemleri sürelerinde beyan edilen sürelerin dışında işlem yapılması durumunda Skybitpro doğacak veya doğabilecek zararlar veya kayıplardan sorumlu değildir.": "Skybitpro non è responsabile per danni e/o perdite derivanti da o in connessione con transazioni effettuate fuori dal tempo dichiarato durante le operazioni di deposito e prelievo",
          "Skybitpro reddettiği ve gerçekleştirmediği transferlerin iadesi için kimlik ibrazı ve transfer gerçekleştiren kişinin banka hesabı bilgisini isteme hakkında sahiptir.": "Skybitpro si riserva il diritto di richiedere l'identificazione e le informazioni bancarie della persona che effettua il trasferimento, per il rimborso dei trasferimenti rifiutati o non riusciti.",
          "Skybitpro para yatırma ve çekme kurallarında değişiklik yapma hakkına tek taraflı olarak sahiptir. Para yatırma ve çekme kuralları, site üzerindeki yardım sayfalarında ve diğer sayfalarda bulunan uyarılar ve kuralların bütünüdür.": "Skybitpro si riserva unilateralmente il diritto di modificare le regole di deposito e prelievo. Le regole di deposito e prelievo sono costituite da tutti gli avvisi e le regole nelle pagine di aiuto e in altre pagine del sito.",
          "Skybitpro destek hizmetlerini yalnızca destek@skybitpro.com e-posta adresi üzerinden sağlamaktadır. Skybitpro bu e-posta adresi dışında bir yöntem ile kullanıcılarına destek hizmeti sağlamaz, destek yazışmalarında şifre sormaz, kullanıcılarından kripto para göndermeleri için bir kripto para adresi bildirmez. Bu husus bir tebliğ niteliğinde olup, Skybitpro bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş bir e-posta sebebiyle kullanıcılarının uğradığı ya da uğraması muhtemel zarar ve kayıplardan sorumlu tutulamaz.": "Skybitpro fornisce i servizi di supporto solo tramite l'indirizzo e-mail: support@skybitpro.com. Skybitpro non fornisce servizi di supporto ai propri utenti ad eccezione di questo indirizzo e-mail, non richiede password durante la corrispondenza di supporto, non fornisce un indirizzo di criptovaluta agli utenti per l'invio di criptovaluta. Questa materia è come un rescritto, Skybitpro non può essere ritenuto responsabile quando gli utenti hanno subito o potrebbero subire danni o perdite sulla base di una e-mail inviata da un indirizzo e-mail diverso da quello indicato.",
          "Kullanıcılar kendi hesaplarının güvenliklerini sağlamak zorundadır. Tavsiye edilen güvenlik önlemleri:": "Gli utenti devono garantire sicurezza del proprio account. Misure di sicurezza consigliate:",
          "Güçlü bir şifre kullanılması": "Utilizzo di una password complessa",
          "Kullanıcı adı ve şifresinin kimseyle paylaşılmaması": "Non condividere nome utente e password con nessuno",
          "Site’de kullanılan şifrenin yalnızca Site’de kullanılması": "La password utilizzata sul sito deve essere utilizzata solo sul sito",
          "Site’ye her zaman “https” yoluyla bağlantı kurulması ve siteye girişlerde “https://www.Skybitpro.com” adreslerinin kontrol edilmesidir. Tarafımızca bildirilen güvenlik önlemleri, tavsiye niteliğinde olup, söz konusu güvenlik önlemleri alınmış olmasına rağmen, kullanıcının doğmuş ya da doğacak olan zararlarından Skybitpro hiçbir şekilde sorumlu tutulamaz.": 'Collegandosi sempre al sito tramite "https" e controllo degli indirizzi "https://www.Skybitpro.com" quando si accede al sito. Le misure di sicurezza da noi dichiarate sono raccomandazioni, Skybitpro non può mai essere ritenuto responsabile quando gli utenti hanno subito o potrebbero subire danni nonostante queste misure di sicurezza siano state prese.',
          "Skybitpro bir kripto para alış satış platformudur. Site üzerinden kullanıcılar birbirlerine açık arttırma veya eksiltme yöntemi ile kripto para birimlerini alır veya satarlar. Skybitpro bir aracı sitedir. Sitede oluşan fiyatları Skybitpro belirlemez. Fiyatlarda olan değişikliklerden dolayı kullanıcıların zarar veya kayıplara uğramasından ve doğan ya da doğacak zararlardan Skybitpro sorumlu tutulamaz.": "Skybitpro è una piattaforma di trading di criptovalute. Gli utenti acquistano o vendono tra di loro le unità di criptovalute tramite asta o asta inversa sul sito. Skybitpro è un sito intermediario. Skybitpro non determina i prezzi sul sito. Skybitpro non può essere ritenuto responsabile per utenti che hanno danni o perdite e per danni derivanti da o in connessione con le variazioni di prezzo.",
          "Skybitpro benzer alış satış platformlarından bağımsız bir şirkettir ve herhangi bir kuruluşun temsilcisi değildir. Kullanıcı tarafından Skybitpro haricinde bir alış satış platformunda yapılan alış satış işlemlerinden ve bu işlemlerden doğan ya da doğacak zararlardan Skybitpro hiçbir şekilde sorumlu tutulamaz.": "Skybitpro è una società indipendente da piattaforme di trading simili e non è un rappresentante di alcuna organizzazione. Skybitpro non può mai essere ritenuto responsabile per transazioni di acquisto e vendita effettuate dall'utente su una piattaforma di acquisto e vendita diversa da Skybitpro, e per danni derivanti da o in connessione con queste transazioni.",
          "Kripto para birimlerine yatırım yapmanın getirdiği belli başlı riskler vardır. Kullanıcılar işbu sözleşmenin kabul edilip onaylanması ile kripto para birimlerine yatırımının risklerini anladıklarını ve yatırımın sebep olduğu ya da olabileceği zarar ve ziyanlardan Skybitpro’yu sorumlu tutmayacaklarını beyan ve taahhüt ederler. Fiyat değişimlerinden oluşan kâr ve zararlardan kullanıcılar sorumludur. Kullanıcıların, bir kayıp ya da zarar yaşaması durumunda Skybitpro’den hiçbir hak talep edemezler.": "Se c'è un investimento per le criptovalute significa che ci sono dei rischi precisi. In caso di accettazione ed approvazione di questo contratto, gli utenti dichiarano e si impegnano a comprendere i rischi dell'investimento in criptovalute, e non riterranno Skybitpro responsabile per eventuali perdite o danni causati dall'investimento. Gli utenti sono responsabili di profitti e perdite derivanti dalle variazioni di prezzo. Gli utenti non possono avanzare diritti su di Skybitpro in caso di perdita o danno.",
          "Skybitpro üzerinden satın alınan kripto paraların nasıl kullanıldığının, nereye ve ne amaçla transfer edildiğinin, bir suçta kullanılıp kullanılmadığının, teknik olarak takibi mümkün değildir. Kripto paraların kötüye kullanılmasından doğan ya da doğacak, kullanıcı ya da üçüncü kişilerin zarar ve kayıplarından, menfi veya müspet zararlarından Skybitpro sorumlu tutulamaz.": "Tecnicamente, non è possibile seguire come vengono utilizzate le criptovalute acquistate su Skybitpro, dove e perché vengono trasferite, se sono usate per un crimine o no. Skybitpro non può essere ritenuto responsabile per danni, perdite, danni negativi o positivi dell'utente o di terzi derivanti da o in connessione con l'abuso di criptovalute.",
          "Kripto para kullanımı ile ilgili yasal sorumluluklar ve vergi mükellefiyetlerinden kullanıcılar bizzat sorumludur. Skybitpro, Kullanıcıların kazanç ve kayıplarından oluşan vergisel mükellefiyetlerinden sorumlu tutulamaz.": "Gli utenti sono personalmente responsabili delle responsabilità legali e dell'imposta dovuta relative all'uso delle criptovalute. Skybitpro non può essere ritenuto responsabile per l'imposta dovuta degli utenti a seguito del loro guadagno e perdita.",
          "Skybitpro, Kullanıcılar adına muhafaza edilen hesaplarda bulunan kripto paraların güvenliği için basiretli bir tüccar olarak azamı özeni göstermekle yükümlüdür. Kullanıcılara ait kripto paralar herhangi bir siber saldırıya karşı internet bağlantısı olmayan ortamlarda (cold storage) saklanır. Ancak Skybitpro’nun tüm bu çabalarına rağmen yine de oluşabilecek hırsızlık/dolandırıcılık olaylarından dolayı Skybitpro sorumlu tutulamaz.": "Come commerciante prudente, Skybitpro è responsabile della massima cura per la sicurezza delle criptovalute negli account protetti per conto dell'utente. Le criptovalute degli utenti sono conservate in piattaforme senza connessione a internet contro eventuali attacchi informatici. Ma, nonostante tutti questi sforzi di Skybitpro, Skybitpro non può ancora essere ritenuto responsabile per rapine/frodi che potrebbero verificarsi.",
          "Site, gerçek kişilere hizmet verir.": "Il sito rende un servizio per persone reali.",
          "Kullanıcıların 18 yaşından büyük olması zorunludur. Kullanıcı işbu sözleşme kapsamında 18 yaşından büyük olduğunu beyan ve taahhüt etmektedir. Skybitpro, herhangi bir nedenle sahibinin 18 yaşından küçük olduğunu tespit ettiği veya 18 yaşından küçüklerce kullanıldığından şüphe ettiği Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir. 18 yaşından büyük olduğunu taahhüt eden Kullanıcı, bildirdiği tüm bilgilerin doğruluğundan res’en sorumludur. Skybitpro, Kullanıcı ile ilgili tüm işlemlerde bu beyana itimat eder ve buna göre davranır.": "Gli utenti devono avere più di 18 anni. L'utente dichiara e si impegna di avere più di 18 anni nell'ambito di questo contratto. Skybitpro ha il diritto di chiudere/sospendere l'account Utente senza preavviso se per qualsiasi motivo confermano che il proprietario ha meno di 18 anni, o sospettano che sia utilizzato da persone di età inferiore ai 18 anni. Gli utenti che garantiscono di avere più di 18 anni si assumono la responsabilità personale per l'accuratezza di tutte le informazioni che dichiarano. Skybitpro si fida di questa dichiarazione e agisce di conseguenza in tutte le transazioni relative all'Utente.",
          "Her Kullanıcı, sadece bir Kullanıcı hesabına sahip olabilir. Kullanıcı Site’yi sadece işbu Sözleşme’de tanımlanan hizmetlerden faydalanılması amacı ile kullanabilir. Skybitpro, aynı kişi tarafından kullanılan birden fazla Kullanıcı hesabı bulunması durumunda ilgili Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir.": "Ogni Utente può avere un solo account Utente. L'Utente può utilizzare il Sito esclusivamente per beneficiare dei servizi individuati nel presente Contratto. Skybitpro ha il diritto di chiudere/sospendere il relativo account Utente senza preavviso se determinano più account utente utilizzati dalla stessa persona.",
          "Skybitpro, işlemlerin teknik hatalardan dolayı, gerçekçi olmayan fiyatlardan gerçekleşmesi gibi durumlarda, sistemi düzeltmek ve doğru çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri alabilir. İptal ve geri alma sırasında bu Kullanıcı hesapları dondurulabilir, eğer herhangi bir ödeme yapıldı ise haksız yapılmış ödemenin iadesi istenebilir, iade edilmemesi halinde Skybitpro her türlü hukuki yola başvurma hakkına sahiptir. Bu tarz işlemlerden dolayı Skybitpro sorumlu tutulamaz ve hak talep edilemez.": "Nei casi in cui si verificano transazioni a prezzi irrealistici a causa di un guasto tecnico, Skybitpro può annullare o ritirare tali transazioni al fine di correggere il sistema e garantirne il corretto funzionamento. Questi account utente possono essere congelati durante la cancellazione e il ritiro, il rimborso del pagamento iniquo può essere richiesto se un pagamento è stato effettuato, Skybitpro ha il diritto di intraprendere azioni legali in caso di mancato rimborso. Skybitpro non può essere ritenuto responsabile e non può essere rivendicato per quel tipo di transazioni.",
          "Skybitpro, internet sitesinde oluşabilecek teknik arızalardan dolayı hiçbir şekilde sorumlu tutulamaz. Kısa süreli yada uzun süreli teknik arızalardan dolayı doğrudan veya dolaylı olarak doğan ve doğabilecek hiçbir zarardan Skybitpro sorumlu tutulamaz.": "Skybitpro non può mai essere ritenuto responsabile per guasti tecnici che possono verificarsi sul sito web. Skybitpro non può essere ritenuto responsabile per eventuali danni diretti o indiretti derivanti da o in connessione con guasti tecnici a breve o lungo termine.",
          "Kullanıcıların Skybitpro ile sözlü ve yazılı iletişimde, saygısızlık ve hakaret içeren tavırları karşısında, Skybitpro`nun Kullanıcı hesabını dondurma, kapatma ve hukuki yollara başvurma hakkı saklıdır.": "Skybitpro si riserva il diritto di congelare, chiudere l'account dell'Utente e intraprendere azioni legali contro gli atteggiamenti irrispettosi e offensivi degli Utenti durante la comunicazione verbale e scritta con Skybitpro.",
          "Ücretlendirme": "Tariffa",
          "Skybitpro, Hizmet’lerle ilgili ücretleri Site’nin Yardım bölümünde ilan edecektir. Site’nin yardım bölümünden ulaşılabilmektedir. Ücretler, yardım bölümünde ilan edildiği andan itibaren geçerlilik kazanacaktır.": "Skybitpro annuncerà le tariffe per i Servizi nella sezione Aiuto del Sito. È possibile accedervi dalla sezione di aiuto del sito. Le tariffe diventeranno valide dal momento in cui vengono annunciate nella sezione di aiuto.",
          "Skybitpro, her alış ve satış işlemlerinde kullanıcılarından kendi belirlediği bir oran üzerinden komisyon (hizmet bedeli) alma hakkına sahiptir. Skybitpro, zaman zaman bu ücretler ve oranlar üzerinde değişiklik yapma hakkına sahiptir.": "Skybitpro ha il diritto di ottenere una commissione (costo del servizio) dai suoi utenti in linea con un tasso da loro determinato per ogni transazione di acquisto e vendita. Skybitpro ha il diritto di modificare queste commissioni e tariffe occasionalmente.",
          "Skybitpro, her para çekme işleminde kullanıcılarından kendi belirlediği bir işlem ücreti alma hakkına sahiptir. Kullanıcı, iş bu sözleşmenin kabul ve onaylanması ile, bu işlem ücretinin alınmasını kabul etmiş sayılacaktır.": "Skybitpro ha il diritto di ricevere una commissione di transazione per ogni prelievo a seconda della sua decisione. Si riterrà che l'utente abbia accettato la ricezione di questa commissione di transazione in caso di accettazione ed approvazione di questo contratto.",
          "Kripto para transferleri iade edilemez, dolayısıyla Skybitpro tarafından tahsis edilen komisyon veya işlem ücretlerinin iadesi mümkün değildir ve kullanıcıların bu ücretlerin iadesini isteme hakkı bulunmamaktadır.": "I trasferimenti di criptovaluta non sono rimborsabili, quindi le commissioni o i costi di transazione assegnate da Skybitpro non sono rimborsabili e gli utenti non hanno il diritto di richiedere il rimborso di tali commissioni. ",
          "İş bu sözleşmenin kullanıcı tarafından kabul ve onaylanması halinde, Kişisel Verilerin Korunması hakkındaki kanundan doğan hak ve yükümlülükleri, Skybitpro ile Kullanıcı karşılıklı olarak kabul edilmiş sayılacak ve Kanun kapsamına uygun olarak davranacaklarını kabul ve tahahhüt ederler.": "Skybitpro e l'Utente si considerano accettati reciprocamente i diritti e gli obblighi derivanti dalla Legge Sulla Protezione Dei Dati Personali e accettano e si impegnano a stare dalla parte giusta della legge in caso di accettazione ed approvazione di questo contratto.",
          "Skybitpro, Kullanıcıların şahsi bilgilerini (kimlik, fatura, adres, e-posta, telefon, faks, demografik bilgiler veya müşteri numarası vb.) yasal zorunluluklar veya kullanıcının açık rızası haricinde üçüncü şahıslara vermeyeceğini taahhüt eder.": "Skybitpro si impegna a non fornire le informazioni personali degli Utenti (ID, fattura, indirizzo, e-mail, telefono, fax, dati demografici o numero cliente, ecc.) a terzi, salvo obblighi legali o consenso esplicito dell'utente.",
          "Kullanıcıların vermiş olduğu bilgiler Kullanım Sözleşmesi’nde belirtilen kurallar ve amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır.": "Le informazioni fornite dagli utenti non saranno utilizzati in nessun contesto eccetto regole e scopi specificati nel Contratto di Utilizzo e non saranno condivise con terze parti.",
          "Skybitpro, kullanıcıların IP adreslerini, Site’ye erişim sağladıkları cihazların modellerini, işletim sistemlerini ve tarayıcı bilgilerini tespit etmekte ve kayıt altına almaktadır. Skybitpro, bu kayıtları kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak, sistem ve kullanıcı hesap güvenliğini sağlamak, sahtecilik ile mücadele etmek ve kanuni yükümlülüklere uymak amaçları için kullanabilir.": "Skybitpro determina e conserva gli indirizzi IP degli utenti, i modelli dei dispositivi con cui accedono al Sito, i loro sistemi operativi e le informazioni sul browser. Skybitpro può utilizzare questi elementi per identificare gli utenti in modo generale, per raccogliere informazioni demografiche complete, per fornire sicurezza al sistema e all'account utente, per contrastare le frodi e per adempiere agli obblighi legali.",
          "Skybitpro, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla birçok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde iş birliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. Skybitpro, iletişim faaliyetlerinde, kanunların öngördüğü şekilde izinli iletişim/pazarlama yapacağını, kullanıcının isteği dışında iletişim yapmamayı, kullanıcının sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder.": "Skybitpro può collaborare in modo diverso con molte istituzioni e organizzazioni di terze parti per offrire servizi e lavori più efficienti. Questa collaborazione può avvenire tramite pubblicità, sponsorizzazione, marketing basato su autorizzazioni, condivisione di dati e altri metodi commerciali legali. Skybitpro dichiara e si impegna a svolgere attività di comunicazione/marketing autorizzate in attività di comunicazione in conformità con la legge, non comunicherà contro la volontà dell'utente, fornirà strumenti che consentiranno all'utente di disiscriversi gratuitamente e facilmente.",
          "Skybitpro, Site içerisinde başkaca sitelere link(bağlantı) sağlayabilir. Anlaşmalı olduğu 3. Partilerin reklamlarını ve/veya çeşitli hizmetlere ilişkin başvuru formlarını yayınlayabilir, Kullanıcıları bu formlar ve reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. Skybitpro, bu bağlantı yoluyla erişilen diğer sitelerin gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.": "Skybitpro può fornire collegamenti ad altri siti all'interno del Sito. Può pubblicare annunci e/o moduli di richiesta di terze parti contraenti, relativi a vari servizi, e può indirizzare gli Utenti a siti di inserzionisti o terze parti contraenti attraverso questi moduli e annunci. Skybitpro non ha alcuna responsabilità per le pratiche e politiche sulla privacy o il contenuto degli altri siti a cui si accede tramite questo collegamento.",
          "Skybitpro, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar:": "Skybitpro, fatta salva la presente clausola sulla privacy, può condividere le informazioni degli Utenti con terze parti nelle seguenti situazioni:",
          "Kanun, Yönetmelik vb. yetkili hukuki makamlar tarafından çıkarılan ve yürürlükte bulunan yazılı hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,": "In situazioni in cui la condivisione è necessaria in conformità con le normative legali come Legge, Regolamento ecc.; ed in situazioni in cui è necessario osservare gli obblighi imposti dalle norme scritte di legge in vigore,",
          "Skybitpro’un kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,": "In situazioni in cui è necessario per Skybitpro adempiere alle disposizioni degli contratti tra Skybitpro e gli utenti,",
          "Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda kullanıcılarla ilgili bilgi talep edilmesi hallerinde,": "In situazioni in cui vengono richieste informazioni sugli utenti nell'ambito di una ricerca o indagine svolta dagli organi amministrativi e/o giudiziari autorizzati,",
          "Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu hallerde.": "Quando è necessario fornire informazioni per proteggere i diritti o la sicurezza degli utenti.",
          "Skybitpro, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanıcıyı veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.": "Skybitpro, con questo avviso, si impegna a mantenere strettamente private e riservate le informazioni riservate loro fornite, a considerare un obbligo di mantenerle segrete e ad adottare tutte le precauzioni necessarie per garantirne la riservatezza, a impedire che tutte o parte delle informazioni riservate diventino di pubblico dominio o vengano divulgate a un utente non autorizzato o a terzi e dimostrare la massima diligenza.",
          "Sorumsuzluk Beyanı": "Clausola Di Non Responsabilità",
          "Skybitpro.com Site’si üzerinde sunulan her tür içerik, 3. Partilerden ve kamuya açık kaynaklardan toplanan verilerden oluşmaktadır. Tüm veriler, analizler, raporlar, istatistikler herhangi bir düzenleme veya yönlendirme olmadan bilgileri otomatik işleme tabi tutmuş bir yazılım tarafından işlenmekte ve objektif olarak sunulmaktadır. Skybitpro tarafından sunulan her türlü haber ve raporlar; sadece bilgilendirmeye ve tavsiyeye yöneliktir ve doğrudan çözüm, sonuç, hukuki görüş, politik ve sosyolojik araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir. Bu tür durumlarda Skybitpro hiçbir sorumluluk üstlenmez.": "Qualsiasi contenuto presentato sul Sito Skybitpro.com è costituito da dati raccolti da terze parti e fonti pubbliche. Tutti i dati, le analisi, i report, le statistiche sono elaborati e presentati in modo obiettivo da un software che ha elaborato automaticamente le informazioni senza alcuna modifica o guida. Tutte le notizie e i rapporti inviati da Skybitpro sono solo a scopo informativo e di consulenza e non presentano una conoscenza diretta di soluzioni, conclusioni, opinioni legali, ricerche politiche e sociologiche e non sono garantiti per essere accurati. I dati possono essere contraddittori o incoerenti tra loro. In tali casi, Skybitpro non si assume alcuna responsabilità.",
          "Skybitpro, kendisi tarafından sunulan içeriklerin kullanıcı yoluyla herhangi bir kişi veya kuruluş tarafından elde edilecek sonuçlarla ilgili olarak, her türlü ticarete elverişlilik, performans, pazarlanabilirlik, belli bir amaca uygunluk veya belirli bir amaca uygunluk garantileri de dâhil olmak üzere ister açık ister örtülü olsun, hiçbir şekilde garanti vermez. Tüm içerikler kullanıcıya “olduğu gibi” sunulmaktadır. Skybitpro, içeriklerin kullanılmasından doğabilecek kâr kayıplarından ya da menfi zararlardan sorumlu tutulamaz. Skybitpro tarafından sunulan içeriklerde, herhangi bir Kişiye/Kuruma/Şirkete/Markaya yapılan atıf, bu Kişilerin/Kurumların/Şirketlerin/Markaların piyasa değerine, çeşitli kriterlerdeki sıralamalarına, marka değereine etki yapacak veya hisse senetlerinin satın alınmasına, satılmasına veya elde tutulmasına yönelik bir tavsiye değildir.": 'Skybitpro non rilascia garanzie esplicite o implicite, inclusa qualsiasi commerciabilità, prestazioni, vendibilità e idoneità per uno scopo particolare, per quanto riguarda i risultati del contenuto fornito da Skybitpro, a qualsiasi persona o organizzazione, inoltrato dall\'utente. Tutto il contenuto viene presentato all\'utente "così com\'è". Skybitpro non può essere ritenuto responsabile per eventuali perdite di profitti o perdite che potrebbero derivare dall\'uso dei contenuti. I riferimenti a qualsiasi Persona/Istituzione/Azienda/Marchio nel contenuto fornito da Skybitpro non sono un suggerimento per influenzare i valori di mercato di questi Individui/Istituzioni/Aziende/Marchi, alle loro classifiche in vari criteri, valori di marchio o all\'acquisto, vendita o mantenimento di scorte.',
          "Uygulanacak Hukuk ve Yetki": "Legge Applicabile e Giurisdizione",
          "Site’nin kullanımından doğan ve/veya yasal uyarıda yer alan koşul ve hükümlere ilişkin ve/veya bu Site ile bağlantılı olarak çıkabilecek uyuşmazlıklarda işbu Kullanım Sözleşmesi geçerlidir.": "Il Contratto di Utilizzo è valido per la risoluzione di controversie derivanti dall'utilizzo del Sito e/o in merito ai termini e alle condizioni indicati nelle note legali e/o in relazione a questo Sito.",
          "Sözleşme Değişiklikleri": "Modifiche del Contratto",
          "Skybitpro bu yasal uyarıda yer alan tüm koşulları ve hükümleri önceden bir ihbara gerek kalmaksızın değiştirme ve güncelleme hakkına tek taraflı olarak haizdir.": "Skybitpro si riserva il diritto di modificare e aggiornare unilateralmente tutti i termini e le condizioni indicati in questa nota legale senza alcun avviso.",
          "Yürürlük ve Kabul": "Applicazione e Accettazione",
          "Bu internet sitesinin herhangi bir sayfasına girerek, Kullanım Sözleşmesi’nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz. Kullanım Sözleşmesi Skybitpro tarafından Site üzerinde ilan edildiği tarihte yürürlük kazanır.": "Visitando una qualsiasi pagina di questo Sito, l'utente accetta, dichiara e si impegna di aver letto tutto il contratto e di aver compreso tutti i contenuti del Contratto di Utilizzo, e di aver approvato tutte le disposizioni del Contratto di Utilizzo. Il Contratto di Utilizzo entra in vigore alla data in cui Skybitpro lo pubblica sul Sito.",
          "Kullanım Sözleşmesi’nin şartlarını kabul etmeyen kullanıcıların Site’yi ve Site üzerinden sağlanan servisleri kullanmamaları gerekmektedir. Aksi halde doğmuş ya da doğacak olan zararlardan Skybitpro sorumlu tutulamaz.": "L'utente che non accetta il Contratto di Utilizzo non deve utilizzare il Sito e non deve beneficiare dei servizi forniti sul Sito. In caso contrario, Skybitpro non può essere ritenuto responsabile per eventuali perdite derivanti da o in connessione.",

          "İŞLEM PLATFORMU": "PIATTAFORMA COMMERCIALE",
          "Platformumuzu Keşfedin": "Scopri La Nostra Piattaforma",
          "İnternetin olduğu her yerde yatırım yapmanın tadını çıkarın.": "È un piacere investire ovunque ci sia internet.",
          "Sirix Trader, kripto para piyasalarına erişim sağlayan en güvenilir platformlardan biridir. İnternet bağlantısının olduğu her yerde kolayca işlem yapmanıza olanak tanıyan Sirix Trader platformu sayesinde:": "Sirix Trader è una delle piattaforme più sicure che dà accesso ai mercati delle criptovalute. La piattaforma Sirix Trader ti consente di fare trading facilmente ovunque ci sia una connessione internet, quindi:",
          "Piyasayı canlı ve anlık olarak takip edebilir,": "Puoi seguire il mercato in diretta e attualmente,",
          "Sunduğu veriler sayesinde işlemler yapmadan önce çeşitli değişkenler konusunda daha kolay öngörüde bulunabilir,": "Puoi prevedere più facilmente variabili diverse prima delle transazioni attraverso i dati che offre,",
          "İşlemlerinizi anlık olarak, zaman kaybetmeden gerçekleştirebilirsiniz.": "Puoi eseguire le tue transazioni attualmente senza perdere tempo.",
          "Skybitpro’yu Keşfedin": "Scopri Skybitpro",
          "Web Sitemizi Kullanmaya Başlayın!": "Inizia A Navigare Sul Nostro Sito!",
          "Skybitpro’ya üye olun ve kazanç sağlamaya başlayın.": "Iscriviti a Skybitpro e inizia a guadagnare.",
          "Sirix Web Trader platformlu web sitemiz sayesinde kripto para piyasalarına dilediğiniz zaman ulaşabilir, kripto para işlemlerinizi kolayca gerçekleştirebilirsiniz. Herhangi bir indirme işlemi yapmadan, doğrudan üye olarak ya da hesap satın alarak size özel ayrıcalıkların tadını çıkarabilirsiniz.": "Puoi accedere ai mercati di criptovalute quando vuoi e gestire facilmente le tue transazioni di criptovalute attraverso il nostro sito web con la piattaforma Sirix Web Trader. Puoi godere di privilegi speciali quando ti registri direttamente o acquisti un account senza alcun processo di scaricamento.",
          "Web sitemizi kullanarak:": "Durante la navigazione sul nostro sito web:",
          "Kaldıraçlı kripto para alım-satım işlemlerinizi kolayca gerçekleştirebilir,": "Puoi gestire facilmente il trading di criptovaluta con leva finanziaria,",
          "Kesintisiz ve sorunsuz bir şekilde işlem yapabilir,": "Puoi effettuare transazioni senza interruzioni e senza alcun problema,",
          "Size özel hesap türlerini seçerek yatırımlarınızı daha ayrıcalıklı ve avantajlı şekilde gerçekleştirebilirsiniz.": "Puoi investire in modo più privilegiato e vantaggioso quando scegli i tipi di conti che sono speciali per te.",
          "Mobil Uygulamamızı İndirin!": "Scarica La Nostra App Mobile!",
          "Sadece bir cep telefonu ile yatırımlarınızı her yerden takip edin.": "Monitora i tuoi investimenti da tutte le parti con solo un cellulare.",
          "Skybitpro’nun Sirix Mobil Trader platformlu mobil uygulaması sayesinde, tüm hizmetlerimize cep telefonunuzdan da ulaşabilirsiniz. Bunun için hem iOS hem de Android işletim sistemleriyle uyumlu mobil uygulamamızı indirmeniz yeterli.": "Puoi anche accedere a tutti i nostri servizi dal tuo cellulare con l'applicazione mobile di Skybitpro che ha la piattaforma Sirix Mobile Trader. Pertanto, è sufficiente scaricare la nostra applicazione mobile compatibile con iOS ed Android.",
          "Kullanıcı dostu bir arayüze sahip mobil uygulamamızı indirerek:": "Scarica la nostra app mobile con la sua interfaccia intuitiva ed avrai la possibilità di:",
          "Yatırımlarınızı dilediğiniz yerde yapabilir,": "Investire dove vuoi,",
          "Tüm piyasayı cep telefonunuz üzerinden takip edebilir,": "Seguire il mercato con tuo cellulare,",
          "Anlık işlemlerinizi kaçırmadan, hızlıca gerçekleştirebilirsiniz.": "Eseguire le tue transazioni istantanee rapidamente.",
          "Platformlarımız": "Le nostre piattaforme",
          "WEB UYGULAMAMIZ": "LA NOSTRA APP WEB",
          "MOBİL UYGULAMAMIZ": "LA NOSTRA APP MOBILE",

          "FİNANSAL EĞİTİM": "EDUCAZIONE FINANZIARIA",
          "İŞLEM PLATFORMLARI": "PIATTAFORME COMMERCIALI",
          "KOMİSYONLAR": "COMMISSIONI",
          "HAKKIMIZDA": "CHI SIAMO",
          "BİZE ULAŞIN": "CONTATTACI",
          "Tüm Hakları Saklıdır.": "Tutti i diritti riservati.",
          "AML POLİTİKASI": "POLITICA AML",
          "GİZLİLİK POLİTİKASI": "POLITICA SULLA RISERVATEZZA",
          "Sayfalar": "Pagine",
          "İletişim": "Comunicazione",

          "Finansal Eğitim": "Educazione Finanziaria",
          "Kaldıraçlı kripto para alım ve satım işlemler yapmaya yeni başladıysanız ya da daha fazla tecrübe edinmek istiyorsanız finansal eğitimlerimize katılabilirsiniz. Uzman eğitmenler tarafından, online olarak gerçekleştirilecek eğitimlere katılarak kripto para ve yatırım dünyası hakkında daha fazla bilgi sahibi olabilirsiniz.": "Se hai appena iniziato a fare trading su criptovalute con leva o se vuoi acquisire più esperienza, puoi partecipare ai nostri corsi di formazione finanziaria. Puoi imparare di più sul mondo delle criptovalute e degli investimenti partecipando a corsi di formazione online tenuti da insegnanti esperti.",
          "Online finansal eğitimlerimiz hakkında daha detaylı bilgi sahibi olmak için bizimle hemen iletişime geçebilirsiniz.": "Puoi contattarci direttamente per avere informazioni più dettagliate sulla nostra formazione finanziaria online.",

          "Tüm kripto para işlemlerinizi": "Esegui tutte le tue transazioni di",
          "hızınız kesilmeden, güvenle yapın!": "criptovaluta in sicurezza, senza rallentare!",
        }
      }
    }
  });

export default i18n;