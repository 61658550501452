import React, { useEffect, useState } from 'react'
// import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Snowfall from 'react-snowfall'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { Link } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';



function Popup() {
    const [show, setShow] = useState(false);
    let language = localStorage.getItem("skybit-language")

    const handleClose = () => {
        setShow(false)
    };
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 3000);
    }, [])



    return (
        <Modal className='modal' centered='true' size='lg' show={show} onHide={handleClose}>
            <Modal.Body scrollable={true} >
                <Link to='/skycard'>
                    {language === 'en' ? <img className='img-fluid modal-image' src='images/skybitpro/SkyCardModaleng.png' alt="New Year Campaign" /> : <img className='img-fluid modal-image' src='images/skybitpro/SkyCardModaltr.png' alt="New Year Campaign" />}
                </Link>
                <button onClick={handleClose} style={{ position: 'absolute', top: 10, right: 20, border: 'none', background: 'rgba(0,0,0,0.4)', color: 'white', scale: '1.2', fontWeight: '600' }}>X</button>
            </Modal.Body>
        </Modal >

    )
}

export default Popup