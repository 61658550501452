import React from 'react'
import { useTranslation } from 'react-i18next'

function CardCampaign() {
    const { t } = useTranslation()

    let language = localStorage.getItem("skybit-language")
    return (
        <>
            <div className='d-flex justify-content-center mt-5'>
                {language === 'en' ? <img style={{minHeight: '16rem'}} className='img-fluid mt-5 w-100' src='images/skybitpro/skycard-eng.png' alt="Iphone 14" /> : 
                <img style={{minHeight: '16rem'}} className='img-fluid mt-5 w-100' src='images/skybitpro/skycard-tr.png' alt="Iphone 14" />}
            </div>
            <ul className='text-center mt-4 text-light px-4'>
                <li className='mt-2'>{t("Kartın talep edilebilmesi için  Skybit Pro’da gerçek hesap açılmış olmak gerekmektedir.")}</li>
                <li className='mt-2'>{t("Kartın talep edilebilmesi için  minimum yatırım tutarı 5000$ olmalıdır.")}</li>
                <li className='mt-2'>{t("Kart talep edilmesi sonrası en geç 1 ay içerisinde yatırımcının adresine gönderimi sağlanacaktır.")}</li>
                <li className='mt-2'>{t("Kart talebinde bulunabilmek için Skybit Pro web sitesi iletişim kısmından, whatsapp numaralarımızdan veya canlı destekten müşteri temsilcileriyle iletişime geçebilirsiniz.")}</li>
                <li className='mt-2'>{t("Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilirse hiç bir mazeret belirtmeden Skybit Pro tarafından kart iptal edilir.")}</li>
            </ul>
            </>
    )
}

export default CardCampaign