import { useSnackbar } from 'notistack';
import * as React from 'react';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <div onClick={() => closeSnackbar(snackbarKey)}>
      <i className="fas fa-times" style={{color:"#fff"}}></i>
    </div>
  );
}

export default SnackbarCloseButton;