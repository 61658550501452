import React from 'react';
import { useTranslation } from 'react-i18next';

const Faqs = (props) => {
    const { t } = useTranslation();
    
    return (
        <section style={{marginBottom: '-5rem'}} className="section" id="faqs">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-10">
                        <div className="section-head section-head-s2">
                            <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("SSS")}>{t("Sıkça Sorulan Sorular")}</h2>
                        </div>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="nav tab-nav tab-nav-btn tab-nav-btn-bdr tab-nav-center pdb-r animated" data-animate="fadeInUp" data-delay="0.3">
                                <li><a className="active" data-toggle="tab" href="#tab-2-1">Skybitpro</a></li>
                                <li><a data-toggle="tab" href="#tab-2-2">Bitcoin</a></li>
                                <li><a data-toggle="tab" href="#tab-2-3">Blockchain</a></li>
                                <li><a data-toggle="tab" href="#tab-2-4">{t("Piyasa")}</a></li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab-2-1">
                                    <div className="accordion" id="acc-1">
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <h5 className="accordion-title accordion-title-sm" data-toggle="collapse" data-target="#acc-1-1">{t("Skybitpro nedir?")} <span className="accordion-icon" /></h5>
                                            <div id="acc-1-1" className="collapse show" data-parent="#acc-1">
                                                <div className="accordion-content">
                                                    <p>{t("2019’dan bu yana faaliyet gösteren Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda yatırım yapmak isteyenlerin işini kolaylaştıran çözümler sunar.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-1-2">{t("Skybitpro güvenilir mi?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-1-2" className="collapse" data-parent="#acc-1">
                                                <div className="accordion-content">
                                                    <p>{t("Müşteri odaklı bir yatırım platformu olan Skybitpro, kullanıcılarının beklentilerini en iyi şekilde karşılamayı hedefler. Uzman kadrosunun da desteği ile müşteri memnuniyetini üst düzeyde tutmak için çalışır. Faaliyet gösterdiği ilk günden bu yana güvenilirliğini hiç kaybetmez, güvenlik konusunda kullanıcılarından da tam not alır.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.6">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-1-3">{t("Komisyonlar ne kadar?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-1-3" className="collapse" data-parent="#acc-1">
                                                <div className="accordion-content">
                                                    <p>{t("Skybitpro'da işlemlerin tutarına ve şekline göre çeşitli komisyonlar uygulanır. Tüm işlemlere Katma Değer Vergisi (KDV) dahildir ve ekstra bir ücret talep edilmez. Ayrıca komisyonlar, emir verildiği tarihte uygulanır. Emir tarihinden sonra komisyon bedellerinin azalması ya da artması dikkate alınmaz.")}</p>
                                                    <a href="/commissions">{t("Komisyonları görmek için tıklayın")}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-2-2">
                                    <div className="accordion" id="acc-2">
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <h5 className="accordion-title accordion-title-sm" data-toggle="collapse" data-target="#acc-2-1">{t("Bitcoin fiyatları nasıl belirlenir?")} <span className="accordion-icon" /></h5>
                                            <div id="acc-2-1" className="collapse show" data-parent="#acc-2">
                                                <div className="accordion-content">
                                                    <p>{t("Sahibi ve merkezi olmayan Bitcoin’in fiyatları, piyasa tarafından belirlenir. TOMYA gibi alım satım platformlarında arz ve talebe göre fiyatlar belirlenir. Platformlar birbirine bağlı olmadığı için küçük fiyat farklılıkları gözlemlenebilir. Ancak fiyat uçurumu yaşanmaz. Çünkü farklı platformlardan alınıp platform değişikliği yapılan kripto paralar sayesinde fiyatlar birbiri ile benzer şekilde azalır ya da artar.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-2-2">{t("Bitcoin’in sahibi var mı?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-2-2" className="collapse" data-parent="#acc-2">
                                                <div className="accordion-content">
                                                    <p>{t("Bitcoin’in herhangi bir sahibi ya da merkezi yoktur. Bitcoin, mining yani madencilik yapan kişiler tarafından üretilir. Dağınık şekilde örgütlenmiş, birbiri ardına eklenen ve kayıt tutan blok zincir yöntemi ile Bitcoin akışı gerçekleşir. Burada da bir sahiplik yoktur ve her kullanıcı eşit söz hakkına sahiptir.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.6">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-2-3">{t("Bitcoin nakit paraya çevrilebilir mi?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-2-3" className="collapse" data-parent="#acc-2">
                                                <div className="accordion-content">
                                                    <p>{t("Bitcoin’i nakit olarak kullanabilmek için kripto para borsalarına dahil olunması gerekir. Borsaya dahil olduktan sonra Türk Lirası olarak EFT ya da havale yöntemi ile paranızı hesabınıza aktarabilirsiniz.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-2-3">
                                    <div className="accordion" id="acc-3">
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <h5 className="accordion-title accordion-title-sm" data-toggle="collapse" data-target="#acc-3-1">{t("Blok zinciri (Blockchain) ne demek?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-3-1" className="collapse show" data-parent="#acc-3">
                                                <div className="accordion-content">
                                                    <p>{t("Blok zinciri temel olarak bir ağdır. Ancak bugüne kadar sıkça kullanılan sorumluluğun dağıtıldığı ağlardan farklı olarak merkezî bir yapı söz konusu değildir. Bilgiler bloklar üzerinde tutulur ve her blok, kendinden önce gelen blok ile ilişkilidir. Blokların tamamı kriptografi yöntemi ile birbirine bağlanır ve güvenliği sağlanır. Böylece dışarıdan her türlü müdahalenin önüne geçilir.")}</p>
                                                    <p>{t("Bilgilerin yer aldığı blokların açık anahtar denen özel bir kodu bulunur. Bu kod ile ilgili bloğa erişmek mümkündür. Gizli anahtar ise kullanıcıya aittir ve özeldir. Gizli anahtar ile kullanıcı, bilgilerini gözlemleyebilir. Kısacası blok zinciri; dağıtılmış bir muhasebe defteri gibidir, merkezi yoktur ve halka açık olmasına rağmen bilginin değiştirilebilmesi için onay gerekir. Günümüzde blockchain yöntemi, kripto paralar dışında çok sayıda farklı alanda kullanılıyor. Ancak yaygınlaşıp adından söz ettirebilmesini sağlayan Bitcoin oldu.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-3-2">{t("Blok zincirinin yöneticisi var mı?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-3-2" className="collapse" data-parent="#acc-3">
                                                <div className="accordion-content">
                                                    <p>{t("Bir blok zincirinde ağa dahil olabilen her kullanıcı eşit hakka sahiptir ve hem yöneten hem de yönetilendir. Dağıtılmış ve merkezi olmayan devasa bir veri tabanı olarak akılda canlandırılabilecek olan blok zinciri içerisinde kullanıcı dilediği zaman katılma ve ayrılma hakkına sahiptir.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.7">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-3-4">{t("DeFi Nedir?")}<span className="accordion-icon" /></h5>
                                            <div id="acc-3-4" className="collapse" data-parent="#acc-3">
                                                <div className="accordion-content">
                                                    <p>{t("DeFi, yasal sınırlara ihtiyaç duyulmadan ve herhangi bir aracı kurum olmadan işlem yapılabilen finansal sistemlerine denir. DeFi platformları kullanıcılara; finansal yatırım hizmetleri, sigorta hizmetleri, bankacılık işlemleri gibi birçok alanda hizmet sunmaktadır.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-2-4">
                                    <div className="accordion" id="acc-4">
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <h5 className="accordion-title accordion-title-sm" data-toggle="collapse" data-target="#acc-4-1">{t("Çatallanma nedir?")} <span className="accordion-icon" /></h5>
                                            <div id="acc-4-1" className="collapse show" data-parent="#acc-4">
                                                <div className="accordion-content">
                                                    <p>{t("Bir blockchain ya da Türkçesi ile blok zincirinin iki farklı potansiyel zincirden ilerleyebilecek şekilde ayrılmasına çatallanma denir. İngilizce kaynaklarda bu isim “fork” olarak geçer. Çatallanma ile beraber blockchain içerisine yeni işlevler ya da yeni bir zincir eklenebilir. Çatallanmanın çeşitli sebepleri olabilir. Çatallanmaya en çok neden olan konular ise protokollerin değişmesi ve yeni bloklar açılabilmesidir. Hard fork yani sert çatallanma ve soft fork yani yumuşak çatallanma şeklinde iki çeşidi bulunur.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-4-2">{t("Piyasa Yapıcı (Maker) ve Piyasa Alıcı (Taker) Emir Nedir?")} <span className="accordion-icon" /></h5>
                                            <div id="acc-4-2" className="collapse" data-parent="#acc-4">
                                                <div className="accordion-content">
                                                    <p>{t("Piyasa Yapıcı ve Piyasa Alıcı emirleri, piyasa için likiditeyi sağlayan emirler ile bu likidi kullanan emirlere denir. Piyasa Yapıcı emirler ve Piyasa Alıcı emirler piyasada farklı iki taraf olduğu için komisyon oranları birbirinden farklıdır.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-s3 animated" data-animate="fadeInUp" data-delay="0.6">
                                            <h5 className="accordion-title accordion-title-sm collapsed" data-toggle="collapse" data-target="#acc-4-3">{t("Arbitraj nedir?")} <span className="accordion-icon" /></h5>
                                            <div id="acc-4-3" className="collapse" data-parent="#acc-4">
                                                <div className="accordion-content">
                                                    <p>{t("Kripto para piyasasında farklı borsalar üzerinden satışa sunulan dijital paraların, kar elde etmek için borsalar arası alım satım işlemlerine kripto arbitraj denir. Kripto arbitraj yaparken önemli olan detay ise işlem süresidir ve arbitraj işlemini yaparken çok hızlı hareket edilmeye çalışılır. Alım satım yapılarak takas edilebilen kripto paralar birçok kripto borsasından takip edilebilir.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Faqs;