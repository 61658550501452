import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AML from '../pages/AML';
import Commissions from '../pages/Commissions';
import Contact from '../pages/Contact';
import AboutUs from '../pages/AboutUs';
import EconomicCalendar from '../pages/EconomicCalendar';
import FinancialEducation from '../pages/FinancialEducation';
import Homepage from '../pages/Homepage';
import NotFound from '../pages/NotFound';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import TradingPlatforms from '../pages/TradingPlatforms';
import MobileApp from '../pages/MobileApp';
import Exchange from '../pages/Exchange';
import Promotions from '../pages/Promotions/Promotions';
import Iphone from '../pages/Promotions/campaigns/Iphone';
import Header from '../components/Header/Header';
import CardCampaign from '../pages/Promotions/campaigns/CardCampaign';

const Router = (props) => {
  return (
    <div>
      <BrowserRouter>
      <Header/>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/markets" component={Exchange} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/economic-calendar" component={EconomicCalendar} />
          <Route exact path="/financial-education" component={FinancialEducation} />
          <Route exact path="/trading-platforms" component={TradingPlatforms} />
          <Route exact path="/commissions" component={Commissions} />
          <Route exact path="/mobile-app" component={MobileApp} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/aml" component={AML} />
          <Route exact path="/promotions" component={Promotions} />
          <Route exact path="/iphone-14-campaign" component={Iphone} />
          <Route exact path="/skycard" component={CardCampaign} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
export default Router;