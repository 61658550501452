import React from 'react';
import { useTranslation } from 'react-i18next';

const AltComponent = (props) => {
    //const login = 'https://my.skybitpro.com/login?lang=tr';
    const register = 'https://my.skybitpro.com/register?lang=tr';
    const login = 'sca.' + window.location.hostname
    const { t } = useTranslation();

    return (
        <div className="banner tc-light">
            <div className="nk-block nk-block-sm nk-block-header my-auto">
                <div className="container container-xxl">
                    <div className="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px">
                        <div className="col-lg-6 order-lg-last">
                            <div className="banner-gfx banner-gfx-auto animated" data-animate="fadeInUp" data-delay=".7">
                                <img style={{scale: '1.1'}} src="images/skybitpro/appimage.png" alt="header" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-sm-10 text-center text-lg-left">
                            <div className="banner-caption">
                                <p className="lead animated" data-animate="fadeInUp" data-delay=".9">
                                    {t("Kaldıraçlı kripto para alım-satım işlemleriniz için Skybitpro yanınızda. Güvenilir bir şekilde yatırım yapmak, kazanç sağlamak için güçlü altyapımızı kullanmak, 7/24 hizmet anlayışımız sayesinde web sitemiz üzerinden dilediğiniz zaman kripto para alıp satmak için hemen üye olabilirsiniz.")}</p>
                                <div className="cpn-action animated" data-animate="fadeInUp" data-delay={1}>
                                    <a className="btn btn-primary btn-round mr-5 mt-4" href={register} target="_blank">{t("ÜYE OLUN")}</a>
                                    <a className="btn btn-round mt-4" href={`https://${login}`} target="_blank">{t("GİRİŞ YAPIN")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AltComponent;