import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const AboutUs = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-6">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("KURUMSAL")}>{t("Hakkımızda")}</h2>
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("Türkiye’nin en güvenli, en hızlı kripto para alım-satım platformu ile tanışın.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-xxl-6 col-xl-6 col-lg-8">
                                    <div className="nk-block-img mb-5 nk-block-plx animated" data-animate="fadeInUp" data-delay="0.3">
                                        <img className="shadow rounded" src="images/skybitpro/about-img.jpg" alt="" />
                                        {/* <img className="nk-block-img-plx plx-screen shadow rounded" src="images/app-screens/sc-small-d.jpg" alt="" /> */}
                                        <img className="nk-block-img-plx plx-circle plx-circle-s1" src="images/gfx/circle-a.png" alt="" />
                                        <img className="nk-block-img-plx plx-polygon plx-polygon-s1" src="images/gfx/polygon-a.png" alt="" />
                                        <img className="nk-block-img-plx plx-triangle plx-triangle-s1" src="images/gfx/triangle-a.png" alt="" />
                                    </div>
                                </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-8">
                                        <div className="nk-block-text">
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">

                                                <div className="feature-text">
                                                    <p>{t("2019’dan bu yana faaliyet gösteren Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda yatırım yapmak isteyenlerin işini kolaylaştıran çözümler sunar. Skybitpro platformunun; hızlı altyapısı, kullanıcı dostu arayüzü ve 7/24 ulaşılabilir olması sayesinde kullanıcılar, alım-satım işlerini en hızlı şekilde ve kolayca gerçekleştirir.")}</p>
                                                </div>
                                            </div>
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">

                                                <div className="feature-text">
                                                    <p>{t("Müşteri odaklı bir yatırım platformu olan Skybitpro, kullanıcılarının beklentilerini en iyi şekilde karşılamayı hedefler. Uzman kadrosunun da desteği ile müşteri memnuniyetini üst düzeyde tutmak için çalışır. Faaliyet gösterdiği ilk günden bu yana güvenilirliğini hiç kaybetmez, güvenlik konusunda kullanıcılarından da tam not alır.")}</p>
                                                </div>
                                            </div>
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                                <div className="feature-text">
                                                    <p>{t("Başarısını güçlü sermayesi ile destekleyen Skybitpro, kaldıraçlı kripto para alım-satım işlemleri konusunda sektörün lider markaları arasında yer almaktadır. Kullanıcılarının yatırım işlemlerinde daha çok kazanç sağlaması için teknik altyapı ve imkanları gün geçtikçe artırmayı hedefleyen markamız sayesinde siz de geleceğinize yatırım yapmaya hemen başlayabilirsiniz. Güvenli, hızlı, kesintisiz bir şekilde yatırım yapmak için hemen Skybitpro’ya üye olabilirsiniz.")}</p>
                                                </div>
                                            </div>
                                            <div className="text-center text-sm-left animated" data-animate="fadeInUp" data-delay="0.9">
                                                <a href="/" className="btn btn-round btn-primary mt-5">{t("Skybitpro'yu KEŞFEDİN")}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section style={{marginTop: '-7rem', marginBottom: '-6rem'}} className="section" id="ecosystems">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-6">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="SKYBITPRO">{t("Neden Skybitpro?")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row text-center align-items-lg-start gutter-vr-40px">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-1 animated" data-animate="fadeInUp" data-delay="0.3">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("GÜVENLİ DEPOLAMA")}</h5>
                                                <p>{t("Dijital varlıkları, soğuk cüzdanlar ve Çok Taraflı hesaplama (MPC) teknolojisinin bir kombinasyonunu kullanarak yönetiyoruz.")} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-2 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("ÖNCE GÜVENLİK")}</h5>
                                                <p>{t("Fonlarınızı güvende tutmak için en son teknolojileri kullanıyoruz ve güvenlik açıklarının ve istismar girişimlerinin önünde yer alıyoruz.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("HIZLI PARA ÇEKME")}</h5>
                                                <p>{t("Çok taraflı bilgi işlem kullanarak, sıkı güvenlik standartlarımızı korurken günün 24 saati hızlı para çekme işlemleri sunabiliyoruz.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-4 animated" data-animate="fadeInUp" data-delay="0.6">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("7/24 DESTEK")}</h5>
                                                <p>{t("Bir sorununuz mu var yoksa bize bir şey mi sormanız gerekiyor? Müşteri destek ekibimiz 7/24 yardıma hazırdır.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-image bg-contain bg-bottom-center bg-ecosystems animated d-none d-sm-block" data-animate="fadeInUp" data-delay="0.2">
                                    <img src="images/globe-particle.png" alt="globe" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default AboutUs;