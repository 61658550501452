import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = (props) => {

  const {t} = useTranslation()

    return (
        <div className="container">
        <main className="nk-pages tc-light px-0 text-center mt-5 mb-5">
          <div className="my-auto py-5">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-md-7 col-sm-9">
                <div className="position-relative">
                  <h2 className="title-xxl-grad title-ele-head mt-5">404</h2>
                  <h5 className="pb-2">{t("Opps! Sayfa Bulunamadı")}</h5>
                  <p >{t("Silinmiş veya hiç var olmamış bir sayfaya erişmeye çalışıyorsunuz.")}</p>
                  <a href="/" className="btn btn-primary btn-md btn-round">{t("ANASAYFAYA DÖN")}</a>
                </div> 
              </div>
            </div>
          </div>
        </main>
      </div>
    );
};
export default NotFound;