import React, { useState, useEffect } from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import Banner from '../../components/Banner/Bannner';
import ContactComp from '../../components/ContactComp/ContactComp';
import Faqs from '../../components/Faqs/Faqs';
import PriceList from '../../components/PriceList/PriceList';
import { useTranslation } from 'react-i18next';
import Contact from '../Contact';
import Modal from 'react-modal';
import Popup from '../../components/Modal/Popup';


const Homepage = (props) => {
    const { t } = useTranslation();

    

    return (
        <div style={{ overflowX: "hidden" }}>
            <Popup />
            <Banner />
            <div style={{ marginTop: '-4rem' }} className="nk-wrap">
                <main className="nk-pages tc-light">
                    <PriceList
                        url="https://api.coin-stats.com/v4/coins?&limit=10"
                        title="Fiyat Listesi"
                        backTitle="GÜNCEL"
                    />
                    <section style={{ marginBottom: '-6rem' }} className="section" id="docs">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-10">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="SKYBITPRO">
                                            {t("Kripto Para İşlemleri İçin Güvenilir Adres")}
                                        </h2>
                                        <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("Hızlı altyapı, 7/24 ulaşma imkanı ve alanında uzman kadromuzun desteği sayesinde kripto para işlemleri ile geleceğe yatırım yapmak hem çok kolay hem de çok güvenli.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row gutter-vr-50px">
                                    <div className="col-sm-6 col-lg-4 p-5">
                                        <div className="doc animated p-5" data-animate="fadeInUp" data-delay="0.3">
                                            <div className="doc-photo doc-shape doc-shape-a">
                                                <img src="images/icons/icon-h.png" alt="" />
                                            </div>
                                            <div className="doc-text">
                                                <h5 className="doc-title title-sm">{t("Hızlı Altyapı Avantajı")}</h5>
                                                <small>{t("Hızlı altyapı avantajımız ile işlemlerinizi kesintisiz bir şekilde gerçekleştirin. Herhangi bir sorun ile karşılaşmadan, tüm kripto para alım ve satım işlemlerinizi kolayca gerçekleştirin.")}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 p-5">
                                        <div className="doc animated p-5" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="doc-photo doc-shape doc-shape-b">
                                                <img src="images/icons/icon-i.png" alt="" />
                                            </div>
                                            <div className="doc-text">
                                                <h5 className="doc-title title-sm">{t("Uzman Kadro Desteği")}</h5>
                                                <small>{t("İşlem yaparken karşılaştığınız herhangi sorunu hızlıca çözmek, platformumuz ile ilgili tüm konularda destek almak ve aklınıza takılan sorulara kolayca yanıt bulmak için alanında uzman ekibimizden destek alın.")}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 p-5">
                                        <div className="doc animated p-5" data-animate="fadeInUp" data-delay="0.5">
                                            <div className="doc-photo doc-shape doc-shape-c">
                                                <img src="images/icons/icon-j.png" alt="" />
                                            </div>
                                            <div className="doc-text">
                                                <h5 className="doc-title title-sm">{t("7/24 Ulaşabilme İmkanı")}</h5>
                                                <small>{t("Kripto para almak ya da satmak için belirli saatleri beklemenize gerek yok. Çalıştığımız bankalar üzerinden 7/24 para transfer işlemleri yapın, dilediğiniz saatte kripto para alıp satın.")}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section ov-h bg-dark pl-5">
                        <div className="container">
                            <div style={{ marginTop: '-4rem' }} className="nk-block nk-block-features-s2">
                                <div className="row align-items-center flex-row-reverse justify-content-between gutter-vr-30px">
                                    <div className="col-12 mb-5 text-center">
                                        <div className="section-head pb-lg-0">
                                            <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">{t("3 Kolay Adımda Kripto Para Almaya Başlayın!")}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row features-list flex-wrap gutter-vr-30px">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="feature feature-s1 feature-s1-1 animated" data-animate="fadeInUp" data-delay=".4">
                                            <div className="feature-icon feature-icon-s5 feature-icon-s5-1">
                                                <em className="text-light fas fa-user-plus" />
                                            </div>
                                            <div className="feature-text feature-text-s1">
                                                <h5>{t("1. Adım")}</h5>
                                                <span className="text-light title-lg">{t("Kayıt Ol")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6" style={{ paddingLeft: "30px" }}>
                                        <div className="feature feature-s1 feature-s1-1 animated" data-animate="fadeInUp" data-delay=".5">
                                            <div className="feature-icon feature-icon-s5 feature-icon-s5-1">
                                                <em className="text-light fas fa-check" />
                                            </div>
                                            <div className="feature-text feature-text-s1">
                                                <h5>{t("2. Adım")}</h5>
                                                <span className="text-light title-lg">{t("Hesabını Doğrula")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6" style={{ paddingLeft: "120px" }}>
                                        <div className="feature feature-s1 feature-s1-1 animated" data-animate="fadeInUp" data-delay=".6">
                                            <div className="feature-icon feature-icon-s5 feature-icon-s5-1">
                                                <em className="text-light fas fa-credit-card" />
                                            </div>
                                            <div className="feature-text feature-text-s1">
                                                <h5>{t("3. Adım")}</h5>
                                                <span className="text-light title-lg">{t("Para Yatır")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cpn-action animated text-center" data-animate="fadeInUp" data-delay={1}>
                            <a className="btn btn-primary btn-round mt-5" href="/markets">{t("HEMEN BAŞLA")}</a>
                        </div>
                    </section>
                    <section style={{ marginTop: '-3rem', marginBottom: '-8rem' }} className="section" id="ecosystems">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-6">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="SKYBITPRO">{t("Neden Skybitpro ?")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row text-center align-items-lg-start gutter-vr-40px">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-1 animated" data-animate="fadeInUp" data-delay="0.3">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("GÜVENLİ DEPOLAMA")}</h5>
                                                <p>{t("Dijital varlıkları, soğuk cüzdanlar ve Çok Taraflı hesaplama (MPC) teknolojisinin bir kombinasyonunu kullanarak yönetiyoruz.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-2 animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("ÖNCE GÜVENLİK")}</h5>
                                                <p>{t("Fonlarınızı güvende tutmak için en son teknolojileri kullanıyoruz ve güvenlik açıklarının ve istismar girişimlerinin önünde yer alıyoruz.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-3 animated" data-animate="fadeInUp" data-delay="0.5">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("HIZLI PARA ÇEKME")}</h5>
                                                <p>{t("Çok taraflı bilgi işlem kullanarak, sıkı güvenlik standartlarımızı korurken günün 24 saati hızlı para çekme işlemleri sunabiliyoruz.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="feature feature-s6 feature-s6-4 animated" data-animate="fadeInUp" data-delay="0.6">
                                            <div className="feature-text">
                                                <h5 className="title title-sm ttu">{t("7/24 DESTEK")}</h5>
                                                <p>{t("Bir sorununuz mu var yoksa bize bir şey mi sormanız gerekiyor? Müşteri destek ekibimiz 7/24 yardıma hazırdır.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-image bg-contain bg-bottom-center bg-ecosystems animated d-none d-sm-block" data-animate="fadeInUp" data-delay="0.2">
                                    <img src="images/globe-particle.png" alt="globe" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Contact />
                    <Faqs />
                    <AltComponent />
                </main>
            </div>
        </div>
    );
};
export default Homepage;