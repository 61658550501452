import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PriceList = (props) => {
    const login = 'https://my.skybitpro.com/login?lang=tr';
    const { t } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(props.url)
            .then((response) => setData(response.data.coins))
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            axios.get(props.url)
                .then((response) => setData(response.data.coins))
                .catch(err => console.log(err));
        }, 5000)
        //destroy interval on unmount
        return () => clearInterval(interval)
    });
    function Status(change) {
        const status = change <= 0;
        return status;

    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <section style={{marginBottom: '-7rem'}} className="section" id="mvp">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-7">
                        <div className="section-head section-head-s2">
                            <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t(props.backTitle)}>{t(props.title)}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container container-xxl">
                <div className="nk-block">
                    <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                        <div className="col-12">
                            <div className=" bg-theme animated  table-scroll" data-animate="fadeInUp" data-delay=".3">
                                <table className="table table-token">
                                    <tbody>
                                        <tr>
                                            <td className="table-head"></td>
                                            <td className="table-head">{t("SEMBOL")}</td>
                                            <td className="table-head text-right">{t("SON FİYAT")} <small>(USDT)</small></td>
                                            <td className="table-head text-right">{t("24S DEĞİŞİM")}</td>
                                            <td className="table-head text-right">{t("1H DEĞİŞİM")}</td>
                                            <td className="table-head text-right">{t("24S HACİM")}</td>
                                        </tr>
                                        {
                                            data.map(item => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className="table-head">
                                                            <img src={item.ic} width="20px"></img>
                                                        </td>
                                                        <td className="table-des td-width">{item.n} <small>({item.s})</small></td>
                                                        <td className="table-des td-width text-right">$ {numberWithCommas(parseFloat(item.pu).toFixed(2))}</td>
                                                        <td className="table-des td-width text-right" style={Status(item.p1) ? { color: "#f00" } : { color: "#0f0" }}>
                                                            %{item.p1}
                                                        </td>
                                                        <td className="table-des td-width text-right" style={Status(item.p7) ? { color: "#f00" } : { color: "#0f0" }}>
                                                            % {item.p7}
                                                        </td>
                                                        <td className="table-des td-width text-right">$ {numberWithCommas(parseFloat(item.v).toFixed(0))}</td>
                                                        <td className="table-des text-right"><a className="btn btn-primary btn-outline" style={{padding:"2px"}} href={login}>{t("SATIN AL")}</a></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                props.title === "Fiyat Listesi" ?
                                    <div className="cpn-action animated text-center" data-animate="fadeInUp" data-delay={1}>
                                        <a className="btn btn-primary btn-round mt-5" href="/markets">{t("PİYASA EKRANINI GÖRÜNTÜLE")}</a>
                                    </div> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PriceList;
