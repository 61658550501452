import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const Terms = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <section  style={{marginBottom: '-8rem'}} className="section" id="mvp">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-7">
                                    <div className="section-head section-head-s2">
                                        <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("ŞARTLAR")}>{t("ŞARTLAR VE KOŞULLAR")}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container container-xxl">
                            <div className="nk-block">
                                <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                    <div className="col-12">
                                        <div className="nk-block-text">
                                            <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                                <div className="feature-text">
                                                    <h4 className='mb-3'>1. {t("Taraflar")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>1.1.</h5>
                                                        <p>{t("İşbu sözleşme ve sözleşmenin ayrılmaz birer parçası olan eklerden oluşan Skybitpro Kullanım Sözleşmesi (bundan böyle kısaca “Kullanım Sözleşmesi” olarak anılacaktır), “Skybitpro Yazılım Anonim Şirketi” ile Site’ye üye olan “Kullanıcı” arasında, Kullanıcı’nın Site’ye elektronik ortamda üye olması anında, Site’de sunulan hizmetleri kullanırken karşılıklı hak ve yükümlülükleri belirtmek amacı ile düzenlenmiştir.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>2. {t("Tanımlar")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>2.1. Skybitpro</h5>
                                                        <p>{t("Skybitpro Yazılım Anonim Şirketi.")}</p>
                                                        <h5>2.2. {t("Kripto Para")}</h5>
                                                        <p>{t("Kriptografik/şifreli olarak güvenli işlem yapmaya ve ek sanal para arzına olanak sağlayan dijital değerlere, kripto-para denir. Kripto-paralar, alternatif para birimidirler, dijitaldirler ve aynı zamanda sanal paradırlar.")}</p>
                                                        <p>{t("Kripto-paralar, merkezi elektronik paraların ve bankacılık sistemlerindekinin aksine, merkezi olmayan yapıdadırlar. Merkezi olmayan bu yapının kontrolü Blok-Zincir (BlockChain) işlem veri tabanları tarafından gerçekleştirilir (*).")}</p>
                                                        <h5>2.3. {t("Bitcoin Cüzdanı")}</h5>
                                                        <p>{t('Bitcoin bir kripto para’dır. Bitcoin adreslerinizi ve onların şifrelerini tutan bir bilgisayar dosyasıdır. Bitcoin adresler arasında transfer edilir. Bitcoin adresleri rastgele oluşturulan kriptografik açık anahtar eşleridir. Açık adresler yaklaşık 33 harf ve sayı kullanılarak oluşturulan “1” ya da “3” ile başlayan dizilerdir. Bitcoin adresi genelde bu örnekteki şekildedir: 1KUsvypQkcs91Ffdkjkk8ddW7RDWKF75Yc.')}</p>
                                                        <h5>2.4. {t("Kullanıcılar")}</h5>
                                                        <p>{t("Site’ye üye olan ve Site’de sunulan Hizmet’lerden yararlanan gerçek kişi.")}</p>
                                                        <h5>2.5. {t("Site")}</h5>
                                                        <p>{t("www.skybitpro.com alan adından ve bu alan adına bağlı alt alan adlarından oluşan internet sitesi.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>3. {t("Hak ve Yükümlülükler")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>3.1.</h5>
                                                        <p>{t("Skybitpro hizmetine bağlı tüm servislerin, alan adlarının, yazılım kodlarının, ara yüzlerinin, içeriklerinin, ürün incelemelerinin, videolarının, algoritmalarının, çizimlerinin, modellerinin, tasarımlarının ve diğer tüm fikri sınai hakların sahibi, (üçüncü partilerden sağlanan içerik ve uygulamalar hariç olmak üzere) Skybitpro Yazılım Anonim Şirketi’dir. Sunulan servislerin yazılım, tasarım ve telif hakları tamamen Skybitpro’ya aittir. Servislerin ve hizmetlerin, bununla bağlantılı sayfaların kopyalanmasına, çoğaltılmasına ve yayılmasına, ayrıca tersine mühendislik işlemlerine tabi tutulmasına hiçbir şekilde izin verilmemektedir.")}</p>
                                                        <h5>3.2.</h5>
                                                        <p>{t("Kullanıcı; hesabını ve haklarını üçüncü şahıslara devredemez, satamaz, kendi üyeliğini her ne nam ve ad altında olursa olsun başkasına kullandıramaz. Bu durumda Skybitpro kullanıcının hesabını iptal etme, durdurma, askıya alma hakkına sahiptir. Kullanıcının kripto para varlıkları bu durumdan etkilenmez.")}</p>
                                                        <h5>3.3.</h5>
                                                        <p>{t("Site’ye üye olurken verilen bilgilerin doğruluğundan ve gizliliğinden, ayrıca şifre ve kullanıcı adının korunmasından, başka kimselerle paylaşılmamasından kullanıcının kendisi sorumludur. Bu bilgilerin yetkisiz kişiler tarafından ele geçirilmesi ve Skybitpro servislerinin kullanılması halinde Skybitpro hiçbir sorumluluk kabul etmemektedir.")}</p>
                                                        <h5>3.4.</h5>
                                                        <p>{t("Site’nin, kullanıcının hatasından doğan veya doğacak, her türlü zarar ve/veya kayıplardan dolayı Skybitpro yönetim kurulu üyeleri, yöneticileri, çalışanları ve bu sitede yer alan bilgileri hazırlayan kişilerin hiçbir hukuki ve cezai sorumluluğu bulunmamaktadır.")}</p>
                                                        <h5>3.5.</h5>
                                                        <p>{t("Kullanıcılar yalnızca hukuka uygun amaçlarla Site üzerinde işlem yapabilirler. Kullanıcıların hukuka aykırı işlem yapmalarının fark edilmesi halinde, Skybitpro hiçbir şekilde sorumlu olmadığı gibi, Skybitpro, her türlü hukuki yola başvurma ve gerekli güvenlik tedbirlerini alma, hakkına sahiptir.")}</p>
                                                        <h5>3.6.</h5>
                                                        <p>{t("Kullanıcıların Site üzerinde aldığı hizmetleri kullanarak yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Skybitpro’nun hiçbir hukuki ya da idari sorumluluğu bulunmamaktadır.")}</p>
                                                        <h5>3.7.</h5>
                                                        <p>{t("Kullanıcılar sitede hesap sahibi olmak için bir üye formu doldururlar. İşlem yapmak isteyen kullanıcıların kimlik ve adres bilgilerini belgelemek zorundadırlar. Aksi takdirde, Skybitpro’ün işlem yapmamak ya da hesabı durdurma hakkı tek taraflı olarak saklıdır.")}</p>
                                                        <h5>3.8.</h5>
                                                        <p>{t("Skybitpro, işlemlerin şüpheli görülmesi halinde, kullanıcıların yaptıkları para transferlerini iade etme hakkına sahiptir.")}</p>
                                                        <h5>3.9.</h5>
                                                        <p>{t("Para yatırma işlemlerinin, Kullanıcı ile aynı ismin altına kayıtlı olan banka hesabından yapılması zorunludur.")}</p>
                                                        <h5>3.10.</h5>
                                                        <p>{t("ATM’ler üzerinden kartsız bankacılık yöntemleri ile transfer yapılmamalıdır. Bu tip işlemlerin saptanması durumunda, Skybitpro’nun söz konusu transferleri iade etme hakkına sahiptir.")}</p>
                                                        <h5>3.11.</h5>
                                                        <p>{t("Para yatırma ve çekme işlemleri sürelerinde beyan edilen sürelerin dışında işlem yapılması durumunda Skybitpro doğacak veya doğabilecek zararlar veya kayıplardan sorumlu değildir.")}</p>
                                                        <h5>3.12.</h5>
                                                        <p>{t("Skybitpro reddettiği ve gerçekleştirmediği transferlerin iadesi için kimlik ibrazı ve transfer gerçekleştiren kişinin banka hesabı bilgisini isteme hakkında sahiptir.")}</p>
                                                        <h5>3.13.</h5>
                                                        <p>{t("Skybitpro para yatırma ve çekme kurallarında değişiklik yapma hakkına tek taraflı olarak sahiptir. Para yatırma ve çekme kuralları, site üzerindeki yardım sayfalarında ve diğer sayfalarda bulunan uyarılar ve kuralların bütünüdür.")}</p>
                                                        <h5>3.14.</h5>
                                                        <p>{t("Skybitpro destek hizmetlerini yalnızca destek@skybitpro.com e-posta adresi üzerinden sağlamaktadır. Skybitpro bu e-posta adresi dışında bir yöntem ile kullanıcılarına destek hizmeti sağlamaz, destek yazışmalarında şifre sormaz, kullanıcılarından kripto para göndermeleri için bir kripto para adresi bildirmez. Bu husus bir tebliğ niteliğinde olup, Skybitpro bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş bir e-posta sebebiyle kullanıcılarının uğradığı ya da uğraması muhtemel zarar ve kayıplardan sorumlu tutulamaz.")}</p>
                                                        <h5>3.15.</h5>
                                                        <p>{t("Kullanıcılar kendi hesaplarının güvenliklerini sağlamak zorundadır. Tavsiye edilen güvenlik önlemleri:")}</p>
                                                        <div className='ml-2 mb-2'>
                                                            <h5>3.15.1.</h5>
                                                            <p>{t("Güçlü bir şifre kullanılması")}</p>
                                                            <h5>3.15.2.</h5>
                                                            <p>{t("Kullanıcı adı ve şifresinin kimseyle paylaşılmaması")}</p>
                                                            <h5>3.15.3.</h5>
                                                            <p>{t("Site’de kullanılan şifrenin yalnızca Site’de kullanılması")}</p>
                                                            <h5>3.15.4.</h5>
                                                            <p>{t("Site’ye her zaman “https” yoluyla bağlantı kurulması ve siteye girişlerde “https://www.Skybitpro.com” adreslerinin kontrol edilmesidir. Tarafımızca bildirilen güvenlik önlemleri, tavsiye niteliğinde olup, söz konusu güvenlik önlemleri alınmış olmasına rağmen, kullanıcının doğmuş ya da doğacak olan zararlarından Skybitpro hiçbir şekilde sorumlu tutulamaz.")}</p>
                                                        </div>
                                                        <h5>3.16.</h5>
                                                        <p>{t("Skybitpro bir kripto para alış satış platformudur. Site üzerinden kullanıcılar birbirlerine açık arttırma veya eksiltme yöntemi ile kripto para birimlerini alır veya satarlar. Skybitpro bir aracı sitedir. Sitede oluşan fiyatları Skybitpro belirlemez. Fiyatlarda olan değişikliklerden dolayı kullanıcıların zarar veya kayıplara uğramasından ve doğan ya da doğacak zararlardan Skybitpro sorumlu tutulamaz.")}</p>
                                                        <h5>3.17.</h5>
                                                        <p>{t("Skybitpro benzer alış satış platformlarından bağımsız bir şirkettir ve herhangi bir kuruluşun temsilcisi değildir. Kullanıcı tarafından Skybitpro haricinde bir alış satış platformunda yapılan alış satış işlemlerinden ve bu işlemlerden doğan ya da doğacak zararlardan Skybitpro hiçbir şekilde sorumlu tutulamaz.")}</p>
                                                        <h5>3.18.</h5>
                                                        <p>{t("Kripto para birimlerine yatırım yapmanın getirdiği belli başlı riskler vardır. Kullanıcılar işbu sözleşmenin kabul edilip onaylanması ile kripto para birimlerine yatırımının risklerini anladıklarını ve yatırımın sebep olduğu ya da olabileceği zarar ve ziyanlardan Skybitpro’yu sorumlu tutmayacaklarını beyan ve taahhüt ederler. Fiyat değişimlerinden oluşan kâr ve zararlardan kullanıcılar sorumludur. Kullanıcıların, bir kayıp ya da zarar yaşaması durumunda Skybitpro’den hiçbir hak talep edemezler.")}</p>
                                                        <h5>3.19.</h5>
                                                        <p>{t("Skybitpro üzerinden satın alınan kripto paraların nasıl kullanıldığının, nereye ve ne amaçla transfer edildiğinin, bir suçta kullanılıp kullanılmadığının, teknik olarak takibi mümkün değildir. Kripto paraların kötüye kullanılmasından doğan ya da doğacak, kullanıcı ya da üçüncü kişilerin zarar ve kayıplarından, menfi veya müspet zararlarından Skybitpro sorumlu tutulamaz.")}</p>
                                                        <h5>3.20.</h5>
                                                        <p>{t("Kripto para kullanımı ile ilgili yasal sorumluluklar ve vergi mükellefiyetlerinden kullanıcılar bizzat sorumludur. Skybitpro, Kullanıcıların kazanç ve kayıplarından oluşan vergisel mükellefiyetlerinden sorumlu tutulamaz.")}</p>
                                                        <h5>3.21.</h5>
                                                        <p>{t("Skybitpro, Kullanıcılar adına muhafaza edilen hesaplarda bulunan kripto paraların güvenliği için basiretli bir tüccar olarak azamı özeni göstermekle yükümlüdür. Kullanıcılara ait kripto paralar herhangi bir siber saldırıya karşı internet bağlantısı olmayan ortamlarda (cold storage) saklanır. Ancak Skybitpro’nun tüm bu çabalarına rağmen yine de oluşabilecek hırsızlık/dolandırıcılık olaylarından dolayı Skybitpro sorumlu tutulamaz.")}</p>
                                                        <h5>3.22.</h5>
                                                        <p>{t("Site, gerçek kişilere hizmet verir.")}</p>
                                                        <h5>3.23.</h5>
                                                        <p>{t("Kullanıcıların 18 yaşından büyük olması zorunludur. Kullanıcı işbu sözleşme kapsamında 18 yaşından büyük olduğunu beyan ve taahhüt etmektedir. Skybitpro, herhangi bir nedenle sahibinin 18 yaşından küçük olduğunu tespit ettiği veya 18 yaşından küçüklerce kullanıldığından şüphe ettiği Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir. 18 yaşından büyük olduğunu taahhüt eden Kullanıcı, bildirdiği tüm bilgilerin doğruluğundan res’en sorumludur. Skybitpro, Kullanıcı ile ilgili tüm işlemlerde bu beyana itimat eder ve buna göre davranır.")}</p>
                                                        <h5>3.24.</h5>
                                                        <p>{t("Her Kullanıcı, sadece bir Kullanıcı hesabına sahip olabilir. Kullanıcı Site’yi sadece işbu Sözleşme’de tanımlanan hizmetlerden faydalanılması amacı ile kullanabilir. Skybitpro, aynı kişi tarafından kullanılan birden fazla Kullanıcı hesabı bulunması durumunda ilgili Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir.")}</p>
                                                        <h5>3.25.</h5>
                                                        <p>{t("Skybitpro, işlemlerin teknik hatalardan dolayı, gerçekçi olmayan fiyatlardan gerçekleşmesi gibi durumlarda, sistemi düzeltmek ve doğru çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri alabilir. İptal ve geri alma sırasında bu Kullanıcı hesapları dondurulabilir, eğer herhangi bir ödeme yapıldı ise haksız yapılmış ödemenin iadesi istenebilir, iade edilmemesi halinde Skybitpro her türlü hukuki yola başvurma hakkına sahiptir. Bu tarz işlemlerden dolayı Skybitpro sorumlu tutulamaz ve hak talep edilemez.")}</p>
                                                        <h5>3.26.</h5>
                                                        <p>{t("Skybitpro, internet sitesinde oluşabilecek teknik arızalardan dolayı hiçbir şekilde sorumlu tutulamaz. Kısa süreli yada uzun süreli teknik arızalardan dolayı doğrudan veya dolaylı olarak doğan ve doğabilecek hiçbir zarardan Skybitpro sorumlu tutulamaz.")}</p>
                                                        <h5>3.27.</h5>
                                                        <p>{t("Kullanıcıların Skybitpro ile sözlü ve yazılı iletişimde, saygısızlık ve hakaret içeren tavırları karşısında, Skybitpro`nun Kullanıcı hesabını dondurma, kapatma ve hukuki yollara başvurma hakkı saklıdır.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>4. {t("Ücretlendirme")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>4.1.</h5>
                                                        <p>{t("Skybitpro, Hizmet’lerle ilgili ücretleri Site’nin Yardım bölümünde ilan edecektir. Site’nin yardım bölümünden ulaşılabilmektedir. Ücretler, yardım bölümünde ilan edildiği andan itibaren geçerlilik kazanacaktır.")}</p>
                                                        <h5>4.2.</h5>
                                                        <p>{t("Skybitpro, her alış ve satış işlemlerinde kullanıcılarından kendi belirlediği bir oran üzerinden komisyon (hizmet bedeli) alma hakkına sahiptir. Skybitpro, zaman zaman bu ücretler ve oranlar üzerinde değişiklik yapma hakkına sahiptir.")}</p>
                                                        <h5>4.3.</h5>
                                                        <p>{t("Skybitpro, her para çekme işleminde kullanıcılarından kendi belirlediği bir işlem ücreti alma hakkına sahiptir. Kullanıcı, iş bu sözleşmenin kabul ve onaylanması ile, bu işlem ücretinin alınmasını kabul etmiş sayılacaktır.")}</p>
                                                        <h5>4.4.</h5>
                                                        <p>{t("Kripto para transferleri iade edilemez, dolayısıyla Skybitpro tarafından tahsis edilen komisyon veya işlem ücretlerinin iadesi mümkün değildir ve kullanıcıların bu ücretlerin iadesini isteme hakkı bulunmamaktadır.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>5. {t("Gizlilik Politikası")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>5.1.</h5>
                                                        <p>{t("İş bu sözleşmenin kullanıcı tarafından kabul ve onaylanması halinde, Kişisel Verilerin Korunması hakkındaki kanundan doğan hak ve yükümlülükleri, Skybitpro ile Kullanıcı karşılıklı olarak kabul edilmiş sayılacak ve Kanun kapsamına uygun olarak davranacaklarını kabul ve tahahhüt ederler.")}</p>
                                                        <h5>5.2.</h5>
                                                        <p>{t("Skybitpro, Kullanıcıların şahsi bilgilerini (kimlik, fatura, adres, e-posta, telefon, faks, demografik bilgiler veya müşteri numarası vb.) yasal zorunluluklar veya kullanıcının açık rızası haricinde üçüncü şahıslara vermeyeceğini taahhüt eder.")}</p>
                                                        <h5>5.3.</h5>
                                                        <p>{t("Kullanıcıların vermiş olduğu bilgiler Kullanım Sözleşmesi’nde belirtilen kurallar ve amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır.")}</p>
                                                        <h5>5.4.</h5>
                                                        <p>{t("Skybitpro, kullanıcıların IP adreslerini, Site’ye erişim sağladıkları cihazların modellerini, işletim sistemlerini ve tarayıcı bilgilerini tespit etmekte ve kayıt altına almaktadır. Skybitpro, bu kayıtları kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak, sistem ve kullanıcı hesap güvenliğini sağlamak, sahtecilik ile mücadele etmek ve kanuni yükümlülüklere uymak amaçları için kullanabilir.")}</p>
                                                        <h5>5.5.</h5>
                                                        <p>{t("Skybitpro, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla birçok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde iş birliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. Skybitpro, iletişim faaliyetlerinde, kanunların öngördüğü şekilde izinli iletişim/pazarlama yapacağını, kullanıcının isteği dışında iletişim yapmamayı, kullanıcının sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder.")}</p>
                                                        <h5>5.6.</h5>
                                                        <p>{t("Skybitpro, Site içerisinde başkaca sitelere link(bağlantı) sağlayabilir. Anlaşmalı olduğu 3. Partilerin reklamlarını ve/veya çeşitli hizmetlere ilişkin başvuru formlarını yayınlayabilir, Kullanıcıları bu formlar ve reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. Skybitpro, bu bağlantı yoluyla erişilen diğer sitelerin gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.")}</p>
                                                        <h5>5.7.</h5>
                                                        <p>{t("Skybitpro, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar:")}</p>
                                                        <div className='ml-2 mb-2'>
                                                            <h5>5.7.1.</h5>
                                                            <p>{t("Kanun, Yönetmelik vb. yetkili hukuki makamlar tarafından çıkarılan ve yürürlükte bulunan yazılı hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,")}</p>
                                                            <h5>5.7.2.</h5>
                                                            <p>{t("Skybitpro’un kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,")}</p>
                                                            <h5>5.7.3.</h5>
                                                            <p>{t("Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda kullanıcılarla ilgili bilgi talep edilmesi hallerinde,")}</p>
                                                            <h5>5.7.4.</h5>
                                                            <p>{t("Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu hallerde.")}</p>
                                                        </div>
                                                        <h5>5.8.</h5>
                                                        <p>{t("Skybitpro, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanıcıyı veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>6. {t("Sorumsuzluk Beyanı")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>6.1.</h5>
                                                        <p><a href="/"></a>{t("Skybitpro.com Site’si üzerinde sunulan her tür içerik, 3. Partilerden ve kamuya açık kaynaklardan toplanan verilerden oluşmaktadır. Tüm veriler, analizler, raporlar, istatistikler herhangi bir düzenleme veya yönlendirme olmadan bilgileri otomatik işleme tabi tutmuş bir yazılım tarafından işlenmekte ve objektif olarak sunulmaktadır. Skybitpro tarafından sunulan her türlü haber ve raporlar; sadece bilgilendirmeye ve tavsiyeye yöneliktir ve doğrudan çözüm, sonuç, hukuki görüş, politik ve sosyolojik araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir. Bu tür durumlarda Skybitpro hiçbir sorumluluk üstlenmez.")}</p>
                                                        <h5>6.2.</h5>
                                                        <p>{t("Skybitpro, kendisi tarafından sunulan içeriklerin kullanıcı yoluyla herhangi bir kişi veya kuruluş tarafından elde edilecek sonuçlarla ilgili olarak, her türlü ticarete elverişlilik, performans, pazarlanabilirlik, belli bir amaca uygunluk veya belirli bir amaca uygunluk garantileri de dâhil olmak üzere ister açık ister örtülü olsun, hiçbir şekilde garanti vermez. Tüm içerikler kullanıcıya “olduğu gibi” sunulmaktadır. Skybitpro, içeriklerin kullanılmasından doğabilecek kâr kayıplarından ya da menfi zararlardan sorumlu tutulamaz. Skybitpro tarafından sunulan içeriklerde, herhangi bir Kişiye/Kuruma/Şirkete/Markaya yapılan atıf, bu Kişilerin/Kurumların/Şirketlerin/Markaların piyasa değerine, çeşitli kriterlerdeki sıralamalarına, marka değereine etki yapacak veya hisse senetlerinin satın alınmasına, satılmasına veya elde tutulmasına yönelik bir tavsiye değildir.")}</p>
                                                    </div>
                                                    <h4 className='mb-3'>7. {t("Uygulanacak Hukuk ve Yetki")}</h4>
                                                    <p>{t("Site’nin kullanımından doğan ve/veya yasal uyarıda yer alan koşul ve hükümlere ilişkin ve/veya bu Site ile bağlantılı olarak çıkabilecek uyuşmazlıklarda işbu Kullanım Sözleşmesi geçerlidir.")}</p>
                                                    <h4 className='mb-3'>8. {t("Sözleşme Değişiklikleri")}</h4>
                                                    <p>{t("Skybitpro bu yasal uyarıda yer alan tüm koşulları ve hükümleri önceden bir ihbara gerek kalmaksızın değiştirme ve güncelleme hakkına tek taraflı olarak haizdir.")}</p>
                                                    <h4 className='mb-3'>9. {t("Yürürlük ve Kabul")}</h4>
                                                    <div className='ml-2 mb-4'>
                                                        <h5>9.1.</h5>
                                                        <p>{t("Bu internet sitesinin herhangi bir sayfasına girerek, Kullanım Sözleşmesi’nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz. Kullanım Sözleşmesi Skybitpro tarafından Site üzerinde ilan edildiği tarihte yürürlük kazanır.")}</p>
                                                        <h5>9.2.</h5>
                                                        <p>{t("Kullanım Sözleşmesi’nin şartlarını kabul etmeyen kullanıcıların Site’yi ve Site üzerinden sağlanan servisleri kullanmamaları gerekmektedir. Aksi halde doğmuş ya da doğacak olan zararlardan Skybitpro sorumlu tutulamaz.")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
            </div>
        </div>
    );
};
export default Terms;