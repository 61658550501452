import React, { useRef, useState } from 'react';
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../store/language";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Header = (props) => {
    const { t, i18n } = useTranslation();
    const dropdown = useRef()
    const [mouse, setMouse] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('skybit-language') === null) {
            localStorage.setItem('skybit-language', "en");
            props.setLanguage("en")
            i18n.changeLanguage("en")
        }
        else {
            props.setLanguage(localStorage.getItem('skybit-language'))
            i18n.changeLanguage(localStorage.getItem('skybit-language'))
        }
    }, [])

    function changeLanguages(lang) {
        props.setLanguage(lang);
        localStorage.setItem('skybit-language', lang);
        i18n.changeLanguage(lang)
        return null
    }

    const openDropdown = () => {
        dropdown.current.className = `dropdown-menu show`
    }
    const closeDropdown = (e) => {
            dropdown.current.className = `dropdown-menu`
        
    }

    //my.skybitpro.com
    const wt = 'my.' + window.location.hostname + "/login?lang=en"
    const register = 'my.' + window.location.hostname + "/register?lang=en"
    const login = 'sca.' + window.location.hostname
    const deposit = 'sca.' + window.location.hostname + "/deposit"
    const withdrawal = 'sca.' + window.location.hostname + "/withdraw"
    return (
        <div className="header-main fixed-top" style={{ padding: "0" }}>
            <div className="">
                <nav className="navbar navbar-expand-lg navbar-dark" style={{ background: "#01a781", zIndex: "99999" }}>
                    <a className="navbar-brand ml-2" href="/"><img src="images/skybitpro/nav-logo.png" width="130px" alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse ml-5" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/markets">{t("Piyasa")}</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="/financial-education">Borsa</a>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to={{pathname : `https://${deposit}`}} target="_blank">{t("Yatırma")}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={{pathname : `https://${withdrawal}`}} target="_blank">{t("Çekme")}</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/mobile-app">{t("Mobil Uygulama")}</a>
                            </li>
                            <li onMouseMove={openDropdown} onMouseLeave={(e) => closeDropdown(e.target)} className="nav-item dropdown pb-1">
                                <a className="nav-link" href="/promotions">{t("Promosyonlar")}</a>
                                <ul ref={dropdown} onMouseMove={openDropdown} className="dropdown-menu" >
                                    <li className="first-menu" onMouseLeave={(e) => closeDropdown(e.target)}><a  href="#"></a></li>
                                    <li className='text-center' onMouseLeave={(e) => closeDropdown(e.target)}><a className="dropdown-item" href="/iphone-14-campaign">{t("iPhone 14 Pro Max Kampanyası")}</a></li>
                                    <li className='text-center' onMouseLeave={(e) => closeDropdown(e.target)}><a className="dropdown-item" href="/skycard">{t("Sky Kart Kampanyası")}</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto" style={{ cursor: "pointer" }}>
                            <li className="nav-item">
                                <a className="nav-link" href="/about-us">{t("Hakkımızda")}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link mr-3" href="/contact">{t("Bize Ulaşın")}</a>
                            </li>
                            <li className="nav-item dropdown mr-2">
                                {/* <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    {props.language === "tr" ? <img src='images/flags/turkey.png' width={26} className="mr-1" /> : null}
                                    {props.language === "en" ? <img src='images/flags/united-kingdom.png' width={26} className="mr-1" /> : null}
                                    {props.language === "ita" ? <img src='images/flags/italy.png' width={26} className="mr-1" /> : null}
                                </a> */}
                                {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item mr-4" onClick={() => { changeLanguages("tr") }}><img src='images/flags/turkey.png' width={20} className="mr-3" />TR (Türkçe)</a>
                                    <a className="dropdown-item mr-4" onClick={() => { changeLanguages("en") }}><img src='images/flags/united-kingdom.png' width={20} className="mr-3" />EN (English)</a>
                                </div> */}
                            </li>
                            <li className="nav-item">
                                <Link className="btn btn-headermenu-register btn-round" to={{pathname : `https://${wt}`}} target="_blank">{t("WEB TRADER")}</Link>
                                <Link className="btn btn-headermenu-register btn-round" to={{pathname : `https://${register}`}} target="_blank">{t("ÜYE OL")}</Link>
                                <Link className="btn btn-headermenu-login btn-round" to={{pathname : `https://${login}`}} target="blank">{t("GİRİŞ YAP")}</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        language: state.language.language,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (language) => dispatch(setLanguage(language)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
