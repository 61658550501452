import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
    const date = new Date().getFullYear();
    const login = 'https://my.skybitpro.com/login?lang=tr';
    const register = 'https://my.skybitpro.com/register?lang=tr';
    const { t } = useTranslation();

    return (
        <footer className="nk-footer-bar section section-s tc-light">
            <div className="container container-xxl">
                <div className='footer-divider' />
                <div className='row'>
                    <div className='col-xs-12 col-md-4 col-lg-3 mb-3'>
                        <a href="/"><img src="images/skybitpro/nav-logo.png" width="160px" alt="logo" /></a>
                        <p>{t("Tüm kripto para işlemlerinizi")} <br />{t("hızınız kesilmeden, güvenle yapın!")}</p>
                        <ul className="social-links social-links-s2 mt-3" data-animate="fadeInUp" data-delay="0.6">
                            <li><a href="https://twitter.com/skybit_pro" target={'_blank'} ><em className="fab fa-twitter" /></a></li>
                            <li><a href="https://www.instagram.com/skybitpro/" target={'_blank'}><em className="fab fa-instagram" /></a></li>
                            <li><a href="https://www.facebook.com/SkybitPro-109171235040518" target={'_blank'}><em className="fab fa-facebook-f" /></a></li>
                        </ul>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3 mb-3'>
                        <h5 className='mb-3'>Skybitpro</h5>
                        <ul className="footer-nav">
                            <li className='mb-2'><a href={register} target="_blank">{t("ÜYE OL")}</a></li><br />
                            <li className='mb-2'><a href={login} target="_blank">{t("GİRİŞ YAP")}</a></li><br />
                        </ul>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3 mb-3'>
                        <h5 className='mb-3'>{t("Sayfalar")}</h5>
                        <ul className="footer-nav">
                            <li className='mb-2'><a href="/financial-education">{t("FİNANSAL EĞİTİM")}</a></li><br />
                            <li className='mb-2'><a href="/trading-platforms">{t("İŞLEM PLATFORMLARI")}</a></li><br />
                            <li className='mb-2'><a href="/commissions">{t("KOMİSYONLAR")}</a></li><br />
                            <li className='mb-2'><a href="/about-us">{t("HAKKIMIZDA")}</a></li><br />
                            <li className='mb-2'><a href="/contact">{t("BİZE ULAŞIN")}</a></li>
                        </ul>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3 mb-3'>
                        <h5 className='mb-3'>{t("İletişim")}</h5>
                        <ul className="footer-nav">
                            {/* <li className='mb-2'><a href="tel:+447781521818"><em className="footer-icon fas fa-phone" />+44 7781 521 818</a></li><br /> */}
                            <li className='mb-2'><a href="https://api.whatsapp.com/send?phone=447781521818" target='_blank'><em className="footer-icon fas fa-phone" />+44 7781 521 818</a></li><br />
                            <li className='mb-2'><a style={{ textTransform: "none" }} href="mailto:support@skybitpro.com"><em className="footer-icon fas fa-envelope" />support@skybitpro.com</a></li><br />
                            <li className='mb-2'><a href="https://www.google.com/maps?q=New+Liverpool+House,+London+EC2M+7LD,+United+Kingdom&rlz=1C1GCEA_enTR987TR987&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjmzLjmncr2AhU3RfEDHS8HBnYQ_AUoAXoECAEQAw"><em className="footer-icon fas fa-map-pin" /><span>New Liverpool House, London <br />EC2M 7LD, United Kingdom</span></a></li><br />
                        </ul>
                    </div>
                </div>
                 {/* <div className='footer-divider' />
                  {/*<div className="nk-block block-partners">
                     {/* <ul className="has-carousel justify-content-center animated" data-items={6} data-items-mobile={2} data-auto="false" data-loop="false" data-animate="fadeInUp" data-delay="0.75">
                        <li className="partner-logo"><img src="images/skybitpro/banks/Akbank_Logo.svg" alt="" /></li>
                        <li className="partner-logo"><img src="images/skybitpro/banks/Fibabank_Logo.svg" alt="" /></li>
                        {/* <li className="partner-logo"><img src="images/skybitpro/banks/Garanti_Logo.svg" alt="bank" /></li> */}
                        {/*  <li className="partner-logo"><img src="images/skybitpro/banks/Turkiye_Finans_Logo.svg" alt="" /></li> */}
                         {/* <li className="partner-logo"><img src="images/skybitpro/banks/Is_Bankasi_Logo.svg" alt="" /></li>*/}
                         {/* <li className="partner-logo"><img src="images/skybitpro/banks/Vakifbank_Logo.svg" alt="" /></li>*/}
                         {/* <li className="partner-logo"><img src="images/skybitpro/banks/Ziraat_Logo.svg" alt="" /></li>*/}
                    {/*  </ul>*/}
                 {/* </div>*/}
                <div className='footer-divider' />
                <div className="row gutter-vr-10px">
                    <div className="col-lg-6 order-lg-last text-lg-right">
                        <ul className="footer-nav">
                            <li><a href="/aml">{t("AML POLİTİKASI")}</a></li>
                            <li><a href="/privacy">{t("GİZLİLİK POLİTİKASI")}</a></li>
                            <li><a href="/terms">{t("ŞARTLAR VE KOŞULLAR")}</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="copyright-text copyright-text-s2">Copyright © {date} <a href="/">Skybitpro</a> - {t("Tüm Hakları Saklıdır.")}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;