import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import { useTranslation } from 'react-i18next';

const TradingPlatforms = (props) => {
    const { t } = useTranslation();

    return (
        <div className='nk-wrap mt-4'>
            <div className="nk-pages tc-light">
                <section className="section" id="mvp">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-7">
                                <div className="section-head section-head-s2">
                                    <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title={t("İŞLEM PLATFORMU")}>{t("Platformumuzu Keşfedin")}</h2>
                                    <p className="animated" data-animate="fadeInUp" data-delay="0.2">{t("İnternetin olduğu her yerde yatırım yapmanın tadını çıkarın.")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container container-xxl">
                        <div style={{marginBottom: '2rem'}} className="nk-block">
                            <div className="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px">
                                <div className="col-xxl-6 col-xl-6 col-lg-8">
                                    <div className="nk-block-img nk-block-plx animated" data-animate="fadeInUp" data-delay="0.3">
                                        <img style={{scale: '1.6'}} className="shadow rounded" src="images/skybitpro/sirix-mobile-big.png" alt="" />
                                        {/* <img className="nk-block-img-plx plx-screen shadow rounded" src="images//skybitpro/sirix-mobile-big.png" alt="" /> */}
                                        <img className="nk-block-img-plx plx-circle plx-circle-s1" src="images/gfx/circle-a.png" alt="" />
                                        <img className="nk-block-img-plx plx-polygon plx-polygon-s1" src="images/gfx/polygon-a.png" alt="" />
                                        <img className="nk-block-img-plx plx-triangle plx-triangle-s1" src="images/gfx/triangle-a.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-xxl-5 col-xl-6 col-lg-8">
                                    <div className="nk-block-text">
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-text">
                                                <p>{t("Sirix Trader, kripto para piyasalarına erişim sağlayan en güvenilir platformlardan biridir. İnternet bağlantısının olduğu her yerde kolayca işlem yapmanıza olanak tanıyan Sirix Trader platformu sayesinde:")}</p>
                                            </div>
                                        </div>
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.5">
                                            <div className="feature-icon feature-icon-md">
                                                <em className="icon icon-xs icon-circle fas fa-check" />
                                            </div>
                                            <div className="feature-text">
                                                <p>{t("Sunduğu veriler sayesinde işlemler yapmadan önce çeşitli değişkenler konusunda daha kolay öngörüde bulunabilir,")}</p>
                                            </div>
                                        </div>
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.4">
                                            <div className="feature-icon feature-icon-md">
                                                <em className="icon icon-xs icon-circle fas fa-check" />
                                            </div>
                                            <div className="feature-text">
                                                <p>{t("Piyasayı canlı ve anlık olarak takip edebilir,")}</p>
                                            </div>
                                        </div>
                                        <div className="feature feature-inline animated" data-animate="fadeInUp" data-delay="0.6">
                                            <div className="feature-icon feature-icon-md">
                                                <em className="icon icon-xs icon-circle fas fa-check" />
                                            </div>
                                            <div className="feature-text">
                                                <p>{t("İşlemlerinizi anlık olarak, zaman kaybetmeden gerçekleştirebilirsiniz.")}</p>
                                            </div>
                                        </div>
                                        <div className="text-center text-sm-left animated" data-animate="fadeInUp" data-delay="0.9">
                                            <a href="/" className="btn btn-round btn-primary mt-5">{t("Skybitpro'yu KEŞFEDİN")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section  style={{marginTop: '-7rem', marginBottom: '-5rem'}} className="section" id="platform">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-6">
                                <div className="section-head section-head-s2">
                                    <h2 className="title title-xl animated" data-animate="fadeInUp" data-delay="0.1" title="SKYBITPRO">{t("Platformlarımız")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container container-xxl">
                        <div className="nk-block">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-8">
                                    <ul className="nav tab-nav tab-nav-btn-bdr-s2 justify-content-center justify-content-sm-between pb-4 pb-sm-5 animated" data-animate="fadeInUp" data-delay="0.2">
                                        <li><a className="active" data-toggle="tab" href="#tab-1-1">{t("WEB UYGULAMAMIZ")}</a></li>
                                        <li className="tab-nav-sap d-none d-sm-block" />
                                        <li><a data-toggle="tab" href="#tab-1-2">{t("MOBİL UYGULAMAMIZ")}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab-1-1">
                                    <div className="row align-items-center justify-content-between gutter-vr-40px">
                                        <div className="col-lg-6 order-lg-last">
                                            <div className="nk-block-img nk-block-ca animated" data-animate="fadeInUp" data-delay="0.2">
                                                <div className="nk-circle-animation nk-df-center fast" />
                                                <img style={{scale: '1.4'}} className="shadow rounded" src="images/skybitpro/sirix-web.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="nk-block-text mgb-m30">
                                                <h2 className="title title-md animated" data-animate="fadeInUp" data-delay="0.2">{t("Web Sitemizi Kullanmaya Başlayın!")}</h2>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.3">{t("Skybitpro’ya üye olun ve kazanç sağlamaya başlayın.")}</p>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.4">{t("Sirix Web Trader platformlu web sitemiz sayesinde kripto para piyasalarına dilediğiniz zaman ulaşabilir, kripto para işlemlerinizi kolayca gerçekleştirebilirsiniz. Herhangi bir indirme işlemi yapmadan, doğrudan üye olarak ya da hesap satın alarak size özel ayrıcalıkların tadını çıkarabilirsiniz.")}</p>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.5">{t("Web sitemizi kullanarak:")}</p>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.4">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Kaldıraçlı kripto para alım-satım işlemlerinizi kolayca gerçekleştirebilir,")}</p>
                                                    </div>
                                                </div>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.5">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Kesintisiz ve sorunsuz bir şekilde işlem yapabilir,")}</p>
                                                    </div>
                                                </div>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.6">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Size özel hesap türlerini seçerek yatırımlarınızı daha ayrıcalıklı ve avantajlı şekilde gerçekleştirebilirsiniz.")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-1-2">
                                    <div className="row align-items-center justify-content-between gutter-vr-40px">
                                        <div className="col-lg-6 order-lg-last">
                                            <div className="nk-block-img nk-block-ca animated" data-animate="fadeInUp" data-delay="0.2">
                                                <div className="nk-circle-animation nk-df-center fast" />
                                                <img style={{scale: '1.5'}} className="shadow rounded" src="images/skybitpro/sirix-mobile.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="nk-block-text mgb-m30">
                                                <h2 className="title title-md animated" data-animate="fadeInUp" data-delay="0.2">{t("Mobil Uygulamamızı İndirin!")}</h2>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.3">{t("Sadece bir cep telefonu ile yatırımlarınızı her yerden takip edin.")}</p>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.4">{t("Skybitpro’nun Sirix Mobil Trader platformlu mobil uygulaması sayesinde, tüm hizmetlerimize cep telefonunuzdan da ulaşabilirsiniz. Bunun için hem iOS hem de Android işletim sistemleriyle uyumlu mobil uygulamamızı indirmeniz yeterli.")}</p>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.5">{t("Kullanıcı dostu bir arayüze sahip mobil uygulamamızı indirerek:")}</p>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.3">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Tüm piyasayı cep telefonunuz üzerinden takip edebilir,")}</p>
                                                    </div>
                                                </div>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.4">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Yatırımlarınızı dilediğiniz yerde yapabilir,")}</p>
                                                    </div>
                                                </div>
                                                <div className="feature feature-inline feature-middle animated" data-animate="fadeInUp" data-delay="0.5">
                                                    <div className="feature-icon feature-icon-md">
                                                        <em className="icon icon-xs icon-circle fas fa-check" />
                                                    </div>
                                                    <div className="feature-text">
                                                        <p>{t("Anlık işlemlerinizi kaçırmadan, hızlıca gerçekleştirebilirsiniz.")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AltComponent />
            </div>
        </div>
    );
};
export default TradingPlatforms;