import React, { useState, useEffect } from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import InputMask from 'react-input-mask';
import connector from "../../helpers/connector"
import { Store } from 'react-notifications-component';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import { useTranslation } from 'react-i18next';

const Contact = (props) => {
    let brandType = 0;
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // var formUrl = `https://skybitpro.testanka.com/api/v1/Submit?brand=${brandType}`;
    const { t } = useTranslation();

    const [fullname, setFullName] = useState("");
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [time, setTime] = useState(0)

    const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    var notification = {
        title: "",
        message: "",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
            duration: 3000,
            onScreen: true
        },
        dismissable: { click: true },
        dismissIcon: <i className="fas fa-times"></i>,
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
    };

    var data = {
        "firstName": fullname,
        "lastName": surname,
        "email": email,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    }
    // var data = {
    //     domain: window.location.protocol + '//' + window.location.hostname,
    //     formSystemName: "RealAccountForm",
    //     formValues: {
    //         "firstName": fullname,
    //         "email": email,
    //         "lastName": surname,
    //         "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    //         "country": "Turkey",
    //         "countryCode": "+90",
    //         "approvedConditions": true,
    //     },
    //     languageIsoCode: 'tr',
    //     siteSystemName: 'Skybitpro',

    // }
    useEffect(() => {
        if (fullname === "" || email === "" || phone === "" || surname === "" || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [fullname, email, phone, surname, validator]);

    function EmailChange(e) {
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    let timer = new Date()
    const handleTime = () => {
        let clickTime = timer.getTime()
        localStorage.setItem('SkyClickTime', clickTime)
        setTime(600000)
        reduceTime()
    }

    const reduceTime = async () => {
        if (localStorage.getItem('SkyClickTime')) {
            setInterval(() => {
                setTime((time) => time - 1000)
            }, 1000);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('SkyClickTime')) {
            let newTime = timer.getTime()
            let oldTime = localStorage.getItem('SkyClickTime')
            let timeDifference = newTime - oldTime
            if (timeDifference > 600000) {
                setTime(0)
            } else {
                setTime((600000 - timeDifference))
                reduceTime()
            }
        }
    }, [])

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        if (millis === 0) {
            localStorage.removeItem('SkyClickTime')
            setTime(0)
        }
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    //timer settings end


    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    Store.addNotification({ ...notification, title: 'Mail İsteğiniz Alındı!', type: "success", message: "En kısa süre içerisinde geri dönüş sağlayacağız." })
                    setIsSend(false)
                    handleTime()
                }
                else {
                    Store.addNotification({ ...notification, title: 'Bir Hata Oluştu!', type: "danger", message: "Lütfen sayfayı yenileyip tekrar deneyin." })
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        Store.addNotification({ ...notification, title: 'Bir Hata Oluştu!', type: "danger", message: "Lütfen sayfayı yenileyip tekrar deneyin." })
                        setIsSend(false)
                    }
                    else {
                        Store.addNotification({ ...notification, title: 'Mail İsteğiniz Alındı!', type: "success", message: "En kısa süre içerisinde geri dönüş sağlayacağız." })
                        setIsSend(false)
                        handleTime()
                    }
                }
                else {
                    Store.addNotification({ ...notification, title: 'Bir Hata Oluştu!', type: "danger", message: "Lütfen sayfayı yenileyip tekrar deneyin." })
                    setIsSend(false)
                }
            })
        setFullName("");
        setEmail("");
        setPhone("");
        setSurname("");
    }

    return (
        <div >
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <div style={{ position: "fixed", top: "80px", zIndex: "999999999999999" }}>
                        <ReactNotifications />
                    </div>
                    <section style={{ marginBottom: '-6rem' }} className="section" id="contact">
                        <div className="container">
                            <div className="nk-block nk-block-about">
                                <div className="row justify-content-between align-items-center gutter-vr-50px">
                                    <div className="col-lg-6">
                                        <div className="nk-block-text">
                                            <div className="nk-block-text-head">
                                                <h2 className="title title-lg ttu animated" data-animate="fadeInUp" data-delay="0.7">{t("7/24 BİZE ULAŞIN")}</h2>
                                                <p className="animated" data-animate="fadeInUp" data-delay="0.8">{t("Sizlere sunduğumuz hizmet, ürün veya servisler hakkındaki her türlü soru, görüş, öneri veya şikayetlerinizi aşağıdaki iletişim kanallarını kullanarak bize iletebilirsiniz.")}</p>
                                            </div>
                                            <div className="nk-form-submit">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="field-item animated" data-animate="fadeInUp" data-delay="0.8">
                                                            <label className="field-label ttu">{t("ADINIZ")}</label>
                                                            <div className="field-wrap">
                                                                <input name="contact-name" placeholder={t("Adınızı Giriniz")} type="text" className="input-bordered required" value={fullname} onChange={event => { setFullName(event.target.value) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="field-item animated" data-animate="fadeInUp" data-delay="0.8">
                                                            <label className="field-label ttu">{t("SOYADINIZ")}</label>
                                                            <div className="field-wrap">
                                                                <input name="contact-name" placeholder={t("Soyadınızı Giriniz")} type="text" className="input-bordered required" value={surname} onChange={event => { setSurname(event.target.value) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="field-item animated" data-animate="fadeInUp" data-delay="0.9">
                                                            <label className="field-label ttu">{t("EMAIL ADRESİNİZ")}</label>
                                                            <div className="field-wrap">
                                                                <input name="contact-email" placeholder={t("Email Adresinizi Giriniz")} type="email" className="input-bordered required email" value={email} onChange={event => { EmailChange(event.target.value) }} style={validEmail.test(String(email).toLowerCase()) || email === "" ? {} : { borderColor: "#f00" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="field-item animated" data-animate="fadeInUp" data-delay="0.9">
                                                            <label className="field-label ttu">{t("TELEFON NUMARANIZ")}</label>
                                                            <div className="field-wrap">
                                                                <InputMask mask="(999) 999 99 99" name="contact-phone" placeholder={t("Telefon Numaranızı Giriniz")} type="text" className="input-bordered required" value={phone} onChange={event => { setPhone(event.target.value) }}
                                                                    style={!phone.includes("_") || phone === "" ? {} : { borderColor: "#f00" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="text" className="d-none" name="form-anti-honeypot" defaultValue />
                                                <div className="row">
                                                    <div className="col-sm-5 text-right animated" data-animate="fadeInUp" data-delay="1.1">
                                                        {time > 0 ? <button type="submit" className="btn btn-round btn-disable">{millisToMinutesAndSeconds(time)}</button> :
                                                            isNull ?
                                                                <button type="submit" className="btn btn-round btn-primary" onClick={() => { Submit() }} >{t("GÖNDER")}</button>
                                                                :
                                                                <button type="submit" className="btn btn-round btn-disable">{t("GÖNDER")}</button>
                                                        }

                                                    </div>
                                                    <div className="col-sm-7 order-sm-first">
                                                        <div className="form-results" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 text-center order-lg-first">
                                        <div className="nk-block-contact nk-block-contact-s1  animated" data-animate="fadeInUp" data-delay="0.1">
                                            <ul className="contact-list">
                                                <li className="animated" data-animate="fadeInUp" data-delay="0.2">
                                                    <em className="contact-icon fas fa-phone" />
                                                    <div className="contact-text">
                                                        <span>+44 7781 521 818</span>
                                                    </div>
                                                </li>
                                                <li className="animated" data-animate="fadeInUp" data-delay="0.3">
                                                    <em className="contact-icon fas fa-envelope" />
                                                    <div className="contact-text">
                                                        <span>support@skybitpro.com</span>
                                                    </div>
                                                </li>
                                                <li className="animated" data-animate="fadeInUp" data-delay="0.4">
                                                    <em className="contact-icon fas fa-map-pin" />
                                                    <div className="contact-text text-left">
                                                        <span>New Liverpool House, London<br /> EC2M 7LD, United Kingdom</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="nk-circle-animation nk-df-center white small" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AltComponent />
                </div>
                <div className="nk-ovm nk-ovm-repeat nk-ovm-fixed shape-i">
                    <div className="ovm-line" />
                </div>
            </div>
        </div>
    );
};
export default Contact;