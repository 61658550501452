import React from 'react'
import { useTranslation } from 'react-i18next'

function Iphone() {
    const { t } = useTranslation()

    let language = localStorage.getItem("skybit-language")
    return (
        <>
            <div className='d-flex justify-content-center mt-5'>
                {language === 'en' ? <img style={{minHeight: '14rem'}} className='img-fluid mt-5 px-4' src='images/skybitpro/skybitpro_iphone14eng.png' alt="Iphone 14" /> : 
                <img style={{minHeight: '14rem'}} className='img-fluid mt-5 px-4' src='images/skybitpro/skybitpro_iphone14promax.png' alt="Iphone 14" />}
            </div>
            <ul className='text-center mt-4 text-light px-4'>
                <li className='mt-2'>{t("Kampanyadan sadece kampanya başlangıç tarihi itibariyle hesap açılışı yapan ve kimliğini doğrulayan yatırımcılar faydalanabilir.")}</li>
                <li className='mt-2'>{t("Kampanya, 1 Aralık 2022 saat 09:30 ile 1 Ocak 2024 saat tarihleri arasındaki işlemleri kapsayacak şekilde geçerli olacaktır.")}</li>
                <li className='mt-2'>{t("Kampanya dahilinde açılan hesaplarda gerçekleşen işlem hacimleri, üç aylık dönem sonunda kontrol edilecek olup, son 3 (üç) ay  boyunca 150.000 Dolar dolar olan yatırımcılarımız kazanacaktır.")}</li>
                <li className='mt-2'>{t("Kampanya şartlarını karşılayan yatırımcılar iPhone 14 Pro Max – 256 GB kazanmaya hak kazanacaklardır.")}</li>
                <li className='mt-2'>{t("iPhone 14 Pro Max – 256 GB kazanan yatırımcılara aygıt kampanya bitimini takip eden 30 iş günü içinde teslim edilecektir.")}</li>
                <li className='mt-2'>{t("Yatırımcıların SKYBIT PRO kampanya kapsamındaki hesabından şartlı virman işlemi gerçekleştirilmesi halinde yatırımcılar kampanya haklarını kaybedeceklerdir.")}</li>
                <li className='mt-2'>{t("Her yatırımcı kendi adına açılmış hesap ile kampanyadan sadece bir defa faydalanabilir.")}</li>
                <li className='mt-2'>{t("iPhone 14 Pro Max Kampanyası başka hiçbir kampanya ile birleştirilemez.")}</li>
                <li className='mt-2'>{t("SKYBIT PRO kampanya koşullarını değiştirme ve kampanyayı sonlandırma hakkını kazanılmış haklar saklı kalmak koşuluyla ve önceden bilgi verilmek suretiyle saklı tutar.")}</li>
                <li className='mt-2'>{t("Kampanyaya 18 yaşından küçükler katılamaz.")}</li>
            </ul>
            </>
    )
}

export default Iphone