import React from 'react';
import AltComponent from '../../components/AltComponent/AltComponent';
import PriceList from '../../components/PriceList/PriceList';

const Exchange = (props) => {
    return (
        <div>
            <div className='nk-wrap mt-5'>
                <div className="nk-pages tc-light">
                    <PriceList 
                    url="https://api.coin-stats.com/v4/coins?&limit=50"
                    title="Borsa"
                    backTitle="KRİPTO PARA"
                    />
                    <AltComponent />
                </div>
                <div className="nk-ovm nk-ovm-repeat nk-ovm-fixed shape-i">
                    <div className="ovm-line" />
                </div>
            </div>
        </div>
    );
};
export default Exchange;