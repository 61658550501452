import { useEffect } from 'react';
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Router from './router/router';
import { connect } from "react-redux";
function App(props) {
  useEffect(() => {
    if (localStorage.getItem('skybit-language') === "tr") {
      document.title = "Skybitpro - The Most Reliable Crypto Exchange"
    }
    else {
      document.title = "Skybitpro - The Most Reliable Crypto Exchange"
    }
  }, [props.language])

  return (
    <div>
      <div id="particles-bg" className="particles-container is-fixed particles-bg op-20" />
      {/* <Header /> */}
      <Router />
      <Footer />
      <div className="nk-ovm nk-ovm-repeat nk-ovm-fixed shape-i">
        <div className="ovm-line" />
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    language: state.language.language,
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
